import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';
import {
    productDetailsReq,
    idReq,
    productListSearchReq
} from '../../interfaces/ApiReqRes'


export function useProductApi() {

    const callApi = useApiCall()

    const getProductListSearch = (data: productListSearchReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PRODUCT.PRODUCT_LIST_SEARCH, data, onSuccess, onError);
    }
    const getProductGlobalSearch = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PRODUCT.PRODUCT_GLOBAL_SEARCH, data, onSuccess, onError);
    }

    const getProductListDetails = (data: productDetailsReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PRODUCT.PRODUCT_LIST_DETAILS, data, onSuccess, onError);
    }

    const changeLoved = (data: idReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PRODUCT.CHANGE_LOVE, data, onSuccess, onError);
    }
    const checkAvailaibility = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PRODUCT.CHECK_AVAILABILITY, data, onSuccess, onError);
    }




    return {
        getProductListSearch: getProductListSearch,
        getProductGlobalSearch: getProductGlobalSearch,
        getProductListDetails: getProductListDetails,
        changeLoved: changeLoved,
        checkAvailaibility: checkAvailaibility
    }
}