import { call } from 'redux-saga/effects';
import { API_URL } from '../_config'
import { CallApi } from './api/callApi';


export function* getFevorite(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.LOVE_FEST.GET_FAVORITE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getMyUnique(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.LOVE_FEST.GET_UNIQUE_LISTINGS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getInspoBoards(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.LOVE_FEST.GET_ALL_INSPOBOARD, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* addToInspoBoard(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.LOVE_FEST.ADD_TO_INSPOBOARD, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* addUpdateInspoBoard(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.LOVE_FEST.ADD_UPDATE_INSPOBOARD, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* deleteInspoBoard(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.LOVE_FEST.DELETE_INSPOBOARD, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

