import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { useCartApi } from '../../_common/hooks/api/cartApiHook';
import { FaCheck } from 'react-icons/fa';
import { useProductApi } from '../../_common/hooks/api/productApiHook';
import DeliveryOptions from "../../components/product/deliveryOptions";
import { useToaster } from '../../_common/hooks/common/appTosterHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import Select from 'react-select';
import Moment from 'react-moment';

import '../../style/updateCart.scss';

const UpdateCartItemModal = (props: Record<string, any>) => {
    const { item, update } = props;
    //console.log('-------------->',item)
    const cartApi = useCartApi();
    const productApi = useProductApi();
    const toast = useToaster();
    const [qty, setQty] = useState<any>(12);

    const { showLoader, hideLoader } = useAppLoader()

    const [product, setProduct] = useState<any>(null);

    const [checkEditOption, setCheckEditOption] = useState<any>(null);
    const [btnEl, setBtnEl] = useState<any>(null);
    const [selectedDeliveryType, setSelectedDeliveryType] = useState<any>(null);
    const [address, setAddress] = useState<any>(null);
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const [editDeliveryOption, setEditDeliveryOption] = useState<boolean>(false);

    const [checkEditModal, setCheckEditModal] = useState(false);
    const closeCheckEditModal = () => { setCheckEditModal(false); setCheckEditOption(null) };

    const [variants, setVariants] = useState<any>({});
    const [additionalAnsweres, setAdditionalAnsweres] = useState<any>([]);


    const updateGiftNoteField = () => {
        let chkBox: any = document.getElementById('modal-as-gift-' + item.product?.id);
        let note: any = document.getElementById('modal-gift-note' + item.product?.id);
        if (!chkBox.checked) {
            console.log(note);
            note.value = '';
        }
    }

    const updateGiftNote = () => {
        if (selectedDeliveryType) {
            let chkBox: any = document.getElementById('modal-as-gift-' + item.product?.id);
            let note: any = document.getElementById('modal-gift-note' + item.product?.id);

            let _variants: any = [];
            Object.keys(variants).forEach((variantKey: any) => {
                let selectedOption = variants[variantKey];
                _variants.push({
                    variant_id: selectedOption.product_variant_id,
                    option_id: selectedOption.id
                })
            });


            let params: any = {
                cart_id: item.id,
                quantity: parseInt(qty),
                gift_note: note ? note.value : '',
                selectedVariation: _variants,
                is_gift: chkBox.checked,
                address_id: address ? address.id : null,
                selectedDeliveryType: selectedDeliveryType.toString(),
                start_date: startDate,
                end_date: endDate,
                answers: additionalAnsweres,
            }

            console.log('Update Cart =>', params)
            showLoader()
            cartApi.updateCart(params, (message: any, resp: any) => {
                props.onHide();
                showCart();
                update();
                hideLoader();
                toast.success(message);
            }, (message: any, resp: any) => {
                toast.error(message);
                hideLoader();
            })
        } else {
            toast.error('Please select a delivery option');
        }

    }

    const showCart = () => {
        let elements = document.getElementsByClassName('MuiDrawer-paper');
        console.log(elements)
        elements[0].classList.remove('d-none');
    }

    const checkEditAvailibility = (el: any, option: any) => {
        if (el.getAttribute('data-checked') == 'true') {
            setSelectedDeliveryType(option.delivery_type_id)
        } else {
            setCheckEditOption(option)
        }
    }


    const getProduct = (coords: any) => {
        return new Promise((resolve: any, reject: any) => {
            let params: any = {
                id: item.product.slug
            }
            if (coords) {
                params.latitude = coords.latitude;
                params.longitude = coords.longitude;
            }
            productApi.getProductListDetails(params, (message: any, resp: any) => {
                console.log('resp.product', resp.product)
                setProduct(resp.product);
                resolve(true);
            }, (message: any, resp: any) => {

            })
        })
    }


    const reset = () => {
        setEditDeliveryOption(false);
        setSelectedDeliveryType(item.delivery_type_id);
        setAddress(item.customer_address_id ? { id: item.customer_address_id } : null);
        setStartDate(item.start_date);
        setEndDate(item.end_date);
        setAdditionalAnsweres([]);
        //setVariants({})

    }



    useEffect(() => {
        if (checkEditOption) {
            setCheckEditModal(true);
        }
    }, [checkEditOption]);


    useEffect(() => {
        if (props.show) {
            if (!product) {
                getProduct(null)
            }
            reset();
        }
    }, [props.show]);


    const selectVariant = (varient: any, data: any) => {
        let _new = JSON.parse(JSON.stringify(variants));
        _new[varient] = data;
        setVariants(_new);
        setEditDeliveryOption(true)
    }


    const checkEditAvailibilityResult = (option: any, status: any, address: any, startDate: any, endDate: any) => {
        console.log('pop => status=>', status)
        setAddress(address);
        setStartDate(startDate);
        setEndDate(endDate);

        btnEl.parentNode.parentNode.children[0].setAttribute('data-checked', status);

        console.log('btnEl.parentNode.parentNode.children[0]=>', btnEl.parentNode.parentNode.children[0]);
        if (status) {
            btnEl.innerText = 'Available';
            btnEl.classList.add('available');
            btnEl.classList.remove('not-available');
        } else {
            btnEl.innerText = 'Sorry, it’s not available. Check another day or location?';
            btnEl.classList.remove('available');
            btnEl.classList.add('not-available');
        }

    }

    const MyOption = (props: any) => {
        const { innerProps, innerRef } = props;
        return (
            <div ref={innerRef} {...innerProps} className="cust-varient-option">

                <p>{props.data.label}</p>
                {/* {props.data.price? <p className="sub"> <Price amount={props.data.price} /></p>:<p className="sub"> <Price amount={product.price} /></p>} */}
            </div>
        );
    };




    useEffect(() => {
        if (product) {
            let _variants: any = {};

            product.product_choice_variations.forEach((variant: any, i: any) => {
                let selected_option_id = item.CartDetails[0].carts_variations[i].option_details.id;
                variant.product_options_variations.map((option: any) => {
                    option.value = option.id;
                    if (option.price != 0 && option.price != null && i == 0) {
                        option.label = option.option_name + '   $' + option.price;
                    } else {
                        option.label = option.option_name
                    }

                    //option.price = option.price;

                    if (selected_option_id == option.id) {
                        _variants[variant.label] = option
                    }
                    return option;
                })
            })
            setVariants(_variants);

            product?.product_additional_questions.map((question: any, i: any) => {
                setAdditionalAnswere(question, item.question_answers[i].answer)
            })
            //setAdditionalAnswere({},'')
            console.log('Item => ', item)
        }
    }, [product]);

    const setAdditionalAnswere = (question: any, answer: any) => {
        let answers: any = [];
        product.product_additional_questions.map((_question: any) => {
            if (_question.id == question.id) {
                _question.answer = answer;
            } else {
                if (!_question.answer) {
                    _question.answer = '';
                }
            }
            answers.push(_question);
        })

        setAdditionalAnsweres(answers)
    }

    const getDeliveryDetails = (id: any) => {
        let name: any = ''
        if (product) {
            product.product_delivery.map((option: any) => {
                if (option.delivery_type_id == id) {
                    name = option.delivery_name
                }

            })
        }

        return (
            <React.Fragment>
                <h4>{name}</h4>
                <span><Moment format="YYYY/MM/DD">{item.start_date}</Moment> &mdash; <Moment format="YYYY/MM/DD">{item.end_date}</Moment></span>
            </React.Fragment>
        );

    }

    const setSelectedDelivery = (id: any, address: any, startDate: any, endDate: any) => {
        setAddress(address);
        setStartDate(startDate);
        setEndDate(endDate);
        setSelectedDeliveryType(id)
    }


    return (
        <React.Fragment>

            <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="add-item-update-modal" >
                <Modal.Header /* closeButton */>
                    <Modal.Title id="add-item-update-modal-title">Update Cart Item: {item?.product?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <div className="product-thumb">
                                <img src={item?.product.list_img_link} alt={item?.product.name} />
                                <h2>{item?.product.name}</h2>
                            </div>
                            <hr />
                            <div className="variants">
                                {product?.product_choice_variations.map((variant: any, i: any) => (
                                    <div className="variant" key={i}>

                                        <p className="label">{variant.label}</p>
                                        <Select
                                            components={{ Option: MyOption }}
                                            options={variant.product_options_variations}
                                            onChange={(data: any) => { selectVariant(variant.label, data) }}
                                            value={variants[variant.label]}
                                        />
                                    </div>
                                ))}
                            </div>

                            <div className="field">
                                <label htmlFor="">Quantity</label>
                                <input placeholder="Quantity" defaultValue={item?.qty} onChange={(e: any) => { setQty(e.target.value); setEditDeliveryOption(true) }} type="number" pattern="[0-9]+" name="quantity" className="form-control" />
                            </div>


                        </Col>
                        <Col md={6}>
                            {product?.product_additional_questions.map((question: any, i: any) => (
                                <div className="mb-3" key={i}>
                                    <label className="form-label">{question?.additional_question}</label>
                                    <textarea placeholder="Please give your answer..." className="form-control" onChange={(e: any) => { setAdditionalAnswere(question, e.target.value) }} defaultValue={item.question_answers[i].answer} />
                                </div>
                            ))}

                            <div className="form-check">
                                <input type="checkbox" id={'modal-as-gift-' + item.product?.id} className="form-check-input" defaultChecked={item.as_gift ? true : false} onChange={updateGiftNoteField} />
                                <label title="" htmlFor={'modal-as-gift-' + item.product?.id} className="form-check-label">I am sending this order as a gift</label>
                            </div>

                            <div className="field">
                                <label htmlFor="">Gift Note</label>
                                <textarea placeholder="Gift Note..." defaultValue={item.gift_note} id={'modal-gift-note' + item.product?.id} name="giftNote" className="form-control"></textarea>
                            </div>

                            <br />

                            {(editDeliveryOption && product) ?

                                <DeliveryOptions options={product.product_delivery} quantity={qty} variants={variants} setSelectedDelivery={setSelectedDelivery} hasQuote={product.has_quote} />

                                : null}

                            {!editDeliveryOption ?
                                <React.Fragment>
                                    <p>{getDeliveryDetails(selectedDeliveryType)}</p>
                                    {item?.customer_address_id ? <p>{item.customer_address.addressLine1}</p> : null}
                                    <Button variant="primary" onClick={() => { setEditDeliveryOption(true) }}>change delivery options</Button>
                                </React.Fragment>

                                : null}


                        </Col>
                    </Row>
                    <div className="form">
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { showCart(); props.onHide(); }} variant="dark">Close</Button>
                    <Button onClick={() => { updateGiftNote(); }}>Update</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>

    )
};

export default UpdateCartItemModal


