import React, { useEffect, useState } from "react";
import CheckAvailibilityModal from "../../components/modals/checkAvailibility";
import { FaCheck } from 'react-icons/fa';
import Moment from 'react-moment';
import moment from 'moment';
import { useUserSelector } from '../../_common/hooks/selectors/userSelector';

import { Container, Row, Col } from 'react-bootstrap';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import LoginModal from "../../components/modals/login";
import RegistrationModal from "../../components/modals/registration";
import GuestModal from "../../components/modals/guest";


import '../../style/productDelivery.scss';
interface incomingProps {
	options: any;
	variants: any;
	quantity: any;
	setSelectedDelivery: any;
	hasQuote:any;
}
export default function DeliveryOptions({ options, variants, quantity, setSelectedDelivery,hasQuote }: incomingProps) {

	const [deliveryOptions, setDeliveryOptions] = useState<any>([]);
	const [selectedDeliveryOption, setSelectedDeliveryOption] = useState<any>(null);

	const [checkOption, setCheckOption] = useState<any>(null);
	const [loginModal, setLoginModal] = useState(false);
	const [registerModal, setRegisterModal] = useState(false);
	const openRegisterModal = () => { setRegisterModal(true); setLoginModal(false); };
	const closeRegisterModal = () => { setRegisterModal(false); setLoginModal(true); };
	const closeLoginModal = () => { setLoginModal(false); }
	const closeLoginModal1 = () => { setLoginModal(false); guestavailaibilty(soption, sel); }
	const [checkModal, setCheckModal] = useState(false);
	const [soption, setOption] = useState<any>(null);
	const [sel, setEl] = useState<any>(null);
	const closeCheckModal = () => { setCheckModal(false); setCheckOption(null) };
	const user: any = useUserSelector();

	const uniqueID = (new Date()).getTime();


	const setSelected = (e: any, address: any, startDate: any, endDate: any) => {
		setSelectedDeliveryOption(e);
		if (e === null) {
			setSelectedDelivery(null, null, null, null);
		} else {
			setSelectedDelivery(e, address, startDate, endDate);
		};
	}

	const getDate = (date: any) => {
		return moment(date).format('MMM-DD,YYYY')
	}

	const guestavailaibilty = (option: any, el: any = null) => {
		console.log(option);

		// console.log('hhhddh');
		let fstvAddress = localStorage.getItem('__fstvAddress');
		let festivelytoken = localStorage.getItem('festively');

		if (fstvAddress == null && festivelytoken == null) {
			setLoginModal(true)
		} else {
			if (el != null && el.getAttribute('data-checked') == 'true') {
				setSelected(option.delivery_type_id, option.address, option.startDate, option.endDate);
			} else {
				setCheckOption(option)
			}
		}
	}

	const checkAddressEn = () => {

	}

	const checkAvailibility = (el: any, option: any) => {
		console.log(el, option);
		setOption(option);
		setEl(el);
		console.log(el);

		if (user) {
			if (el.getAttribute('data-checked') == 'true') {
				setSelected(option.delivery_type_id, option.address, option.startDate, option.endDate);
			} else {
				setCheckOption(option)
			}
		} else {
			guestavailaibilty(option, el);
		}
	}

	const checkAvailibilityResult = (option: any, status: any, address: any, startDate: any, endDate: any, message: any) => {
		console.log('option', option);

		let _deliveryOptions: any = [];
		options.map((_option: any, i: any) => {
			if (_option.id === option.id) {
				_option.status = status;
				_option.label = status ? 'Available' : 'Sorry, it’s not available. Check another day or location?';
				_option.msg = status ? 'available dates:' + getDate(startDate) + ' to ' + getDate(endDate) : message;
				_option.startDate = startDate;
				_option.endDate = endDate;
				_option.address = address;
			}
			console.log('_option =>', _option)
			_deliveryOptions.push(_option)
		})
		setDeliveryOptions(_deliveryOptions);
		setSelected(null, null, null, null);
	}


	useEffect(() => {
		setDeliveryOptions(options)
	}, [options]);

	useEffect(() => {
		if (checkOption) {
			setCheckModal(true);
		}
	}, [checkOption]);


	useEffect(() => {
		setSelected(null, null, null, null);
		setDeliveryOptions(options);

		let _deliveryOptions: any = [];
		options.map((_option: any) => {
			_option.status = null;
			_option.label = null;
			_option.msg = ''
			_option.startDate = null;
			_option.endDate = null;
			_option.address = null;
			_deliveryOptions.push(_option)
		})
		setDeliveryOptions(_deliveryOptions);

	}, [options, variants, quantity]);

	return (
		<React.Fragment>
			<GuestModal
				show={loginModal}
				onHide={closeLoginModal}
				onHide2={closeLoginModal1}
				guestavailaibilty={guestavailaibilty}
				hasQuote={hasQuote}
			/>
			<CheckAvailibilityModal
				show={checkModal}
				onHide={closeCheckModal}
				data={checkOption}
				variants={variants}
				quantity={parseInt(quantity)}
				checkavailibilityresult={checkAvailibilityResult}
			/>
			<div className="buy-type">
				<h2>Choose One</h2>
				{deliveryOptions.map((option: any, i: any) => (
					<div className="card" key={i}>
						<input type="radio" data-checked={option.status} name={uniqueID + "buy-type"} id={'option_' + uniqueID + option.delivery_type_id} onChange={(e: any) => { checkAvailibility(e.target, option) }} checked={selectedDeliveryOption === option.delivery_type_id} value={option.delivery_type_id} />
						<label htmlFor={'option_' + uniqueID + option.delivery_type_id}>
							<div className="select">
								<FaCheck />
							</div>
							<h3>{option.delivery_name}</h3>
							<p className="note">{option.delivery_desc}</p>
							<button className={option.status ? 'available' : option.status === false ? 'not-available' : ''} onClick={() => { if (user) { setCheckOption(option) } else { guestavailaibilty(option); } }}>{option.status ? option.label : option.status === false ? option.label : 'Check Availablity'}</button>
							<p className="msg">{option.msg}</p>
						</label>

					</div>
				))}
			</div>
		</React.Fragment>
	);
}
