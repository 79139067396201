import React, { useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import GuestForm from "../guestForm";


const guestModal = (props: Record<string, any>) => {
    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" className="add-review-modal" >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Welcome</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <GuestForm close={props.onHide2} hasQuote={props.hasQuote} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide} variant="dark">Close</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default guestModal


