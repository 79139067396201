import React from 'react';

const LazyComponent = React.lazy(() =>
  import('./login')
);

const Component = (props: Record<string, any>) => (
  <React.Suspense fallback={<h1>...</h1>}>
    <LazyComponent {...props} />
  </React.Suspense>
);

export default Component;