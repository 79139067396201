import { type } from 'os';
import { ActionExtended } from '../../../_common/interfaces/ActionExtended';
import { Cart } from '../../../_common/interfaces/models/cart';
import { ACTIONS } from '../../../_config'

export interface CartReducer {
  cart: any | null;
}

const initialState: CartReducer = {
  cart: null
};

const CartReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.CART.MY_CART:
      return {
        ...state,
        cart: action.payload.cart
        
      };

    default:
      return state;
  }
};

export default CartReducer;
