import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';
import {
    BlankReq,
    myLoveReq,
    addToInspoBoardReq,
    addUpdateInspoBoardReq, delDoardReq
} from '../../interfaces/ApiReqRes'


export function useLoveFestApi() {

    const callApi = useApiCall()

    const getFevorite = (data: myLoveReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.LOVE_FEST.GET_FAVORITE, data, onSuccess, onError);
    }

    const getMyUnique = (data: myLoveReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.LOVE_FEST.GET_UNIQUE_LISTINGS, data, onSuccess, onError);
    }

    const getInspoBoards = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.LOVE_FEST.GET_ALL_INSPOBOARD, data, onSuccess, onError);
    }

    const addToInspoBoard = (data: addToInspoBoardReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.LOVE_FEST.ADD_TO_INSPOBOARD, data, onSuccess, onError);
    }
    const deleteInspoBoard = (data: delDoardReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.LOVE_FEST.DELETE_INSPOBOARD, data, onSuccess, onError);
    }
    const addUpdateInspoBoard = (data: addUpdateInspoBoardReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.LOVE_FEST.ADD_UPDATE_INSPOBOARD, data, onSuccess, onError);
    }


    return {
        getFevorite: getFevorite,
        getMyUnique: getMyUnique,
        getInspoBoards: getInspoBoards,
        addToInspoBoard: addToInspoBoard,
        deleteInspoBoard: deleteInspoBoard,
        addUpdateInspoBoard: addUpdateInspoBoard,
    }
}