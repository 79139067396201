import { call } from 'redux-saga/effects';
import { API_URL } from '../_config'
import { CallApi } from './api/callApi';



export function* getProductReviews(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.REVIEW.GET_PRODUCT_REVIEW, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* addProductReviews(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.REVIEW.REVIEW, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* getMyReviewedProducts(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.REVIEW.GET_USER_OWN_REVIEW, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* uploadImage(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.REVIEW.UPLOAD_IMAGE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* deleteImage(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.REVIEW.DELETE_IMAGE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* deleteReview(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.REVIEW.DELETE_USER_OWN_REVIEW, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}



/* UPLOAD_IMAGE: 'SAGA_ACTIONS/REVIEW/UPLOAD_IMAGE',
    DELETE_IMAGE: 'SAGA_ACTIONS/REVIEW/DELETE_IMAGE',
    REVIEW: 'SAGA_ACTIONS/REVIEW/REVIEW',
    GET_USER_REVIEW: 'SAGA_ACTIONS/REVIEW/GET_USER_REVIEW',
    GET_PRODUCT_REVIEW: 'SAGA_ACTIONS/REVIEW/GET_PRODUCT_REVIEW',
    GET_USER_OWN_REVIEW: 'SAGA_ACTIONS/REVIEW/GET_USER_OWN_REVIEW',
    DELETE_USER_OWN_REVIEW: 'SAGA_ACTIONS/REVIEW/DELETE_USER_OWN_REVIEW' */

