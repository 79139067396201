import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Header from "../../components/common/header";
import Footer from "../../components/common/footer";
import Item from "../../components/common/item";
import ReadMore from "../../components/common/readMore";

import ListingMap from "../../components/listingMap";
import VendorMap from "../../components/VendorMap";

import { Container, Row, Col, Button } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'
import DatePicker from 'react-date-picker';
import { useCommonApi } from '../../_common/hooks/api/commonApiHook';
import { useProductApi } from '../../_common/hooks/api/productApiHook';
import { Link, useLocation } from "react-router-dom";
import { useCmsApi } from '../../_common/hooks/api/cmsApiHook';
import '../../style/listing.scss';
import Filter from "../../components/filter";


export default function GlobalSearch() {
    const { type } = useParams<any>();
    const commonApi = useCommonApi();
    const productApi = useProductApi();
    const location = useLocation();
    const history: any = useHistory();
    const cmsApi = useCmsApi();

    const [typeData, setTypeData] = useState<any>(null);
    const [items, setItems] = useState<any>(null);
    const [newItems, setNewItems] = useState<any>(null);
    const [view, setView] = useState<any>('list');
    // const [paramsData, setParamsdata] = useState<any>(null);


    const query = new URLSearchParams(location.search);
    const search_text = query.get('text');
    const address: any = query.get('addressLine1');
    const date: any = query.get('date');
    const lat: any = query.get('lat');
    const lng: any = query.get('lng');
    const paramsData: any = {
        "theme": search_text,
        "lat": lat,
        "lng": lng,
        "addressLine1": address,
        "startDate": date,
        "endDate": date
    }
    // setParamsdata(dataa);

    const searchProducts = () => {
        console.log(paramsData);

        productApi.getProductGlobalSearch(paramsData, (message: string, resp: any) => {
            setItems([]);
            setTimeout(() => {
                console.log('flength', resp.product_list.length);
                if (resp.product_list.length == 0) {
                    getNewProducts();
                    setItems(resp.product_list)
                } else {
                    setNewItems([]);
                    setItems(resp.product_list);
                }
            }, 500);
            console.log('items1', items);
        }, (message: string) => {
            console.log('mlength', message);
            setItems([]);
            getNewProducts();
            console.log('items3', items);
        });
        console.log('items1', items);


    }

    const getNewProducts = () => {
        productApi.getProductListSearch({ page: 1, per_page_item: 9, order: 'newest' }, (message: string, resp: any) => {
            console.log('nitems2', items);
            setNewItems(resp.product_list.data);
        }, (message: string) => {
            setNewItems([]);
        });
    }

    // useEffect(() => {
    //     commonApi.getThemeCatInfo({ type: type === 'category' ? 'categories' : type, slug: slug }, (message: string, resp: any) => {
    //         setTypeData(resp.data);
    //     }, (message: string) => {

    //     })
    // }, []);

    useEffect(() => {
        searchProducts();
    }, [search_text, address, date]);

    return (
        <React.Fragment>
            <Header />
            <section className="listing">
                <br />
                <Container>
                    <Row>
                        <Col sm={12}>
                            <React.Fragment>
                                <div className="header">
                                    <h2>{typeData ? typeData.name : null}</h2>
                                    <div>
                                        <button className={view === 'list' ? 'active' : ''} onClick={() => { setView('list') }}>List</button>
                                        <button className={view === 'map' ? 'active' : ''} onClick={() => { setView('map') }}>Map</button>
                                    </div>
                                </div>
                            </React.Fragment>

                            {typeData?.description ? <ReadMore className="desc" limit={230} text={typeData.description} /> : null}

                            {view === 'list' ?
                                <Row>
                                    {items?.map((item: any, i: any) => (
                                        <Col sm={4} key={i}>
                                            <Item item={item} type="product" theme="from" />
                                        </Col>
                                    ))}

                                    {items?.length == 0 ?
                                        <React.Fragment>
                                            <h5>There is no product as per your search but you can check other products below.</h5>
                                            {newItems?.map((nitem: any, i: any) => (
                                                <Col sm={4} key={i}>
                                                    <Item item={nitem} type="product" theme="from" />
                                                </Col>
                                            ))}
                                        </React.Fragment>

                                        : null}
                                </Row>
                                :
                                <ListingMap items={items} />
                            }
                        </Col>
                    </Row>
                </Container>

            </section>
            <Footer />
        </React.Fragment>
    );
}