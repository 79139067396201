//import { getSubdomain } from './functions';
export const API_BASE_URL = process.env.REACT_APP_API_URL;

export const STORAGE = 'festively'
export const APP_STAGE = process.env.REACT_APP_STAGE
export const APP_VERSION = process.env.REACT_APP_VERSION
export const APP_URL = window.location.origin;

export const URLS = {
  HOME: '/',
  ABOUT: '/about-us',
  TERMS: '/terms-and-conditions',
  PRIVACY: '/privacy-policy',
  FAQS: '/faqs',
  HELP: '/help',
  CONTACT: '/contact-us',
  LISTING: '/listing/:type/:slug',
  SHOP: '/shop/:slug',
  PRODUCT: '/product/:slug',
  CATEGORIES: '/categories',
  OCCASSIONS: '/occassions',
  VENDORS: '/vendors',
  ORDER_DETAILS: '/order/:id',
  CHECKOUT: '/checkout',
  COLLECTIONS: '/collections',
  NEWEST_PRODUCTS: '/newest-products',
  SEARCH: '/search',
  GALLERY: '/gallery',

  USER: {
    LOGIN: '/login',
    RESET_PASSWORD: '/reset-password',
    REGISTER: '/register',
    GUEST_REGISTER: '/guest-register',
    FORGOT_PASSWORD: '/forgot-password',
    MY_ACCOUNT: '/my-account',
    REMAINING_AMOUNT: '/pay-remaining-amount/:token',
  },
}


export const API_URL = {
  USER: {
    OTP: `${API_BASE_URL}otpcheck`,
    LOGIN: `${API_BASE_URL}login`,
    ME: `${API_BASE_URL}details`,
    SOCIAL_LOGIN: `${API_BASE_URL}customer/social-login`,
    INSTAGRAM_DATA: `${API_BASE_URL}instagram-data`,
    REGISTER: `${API_BASE_URL}register`,
    GUEST_REGISTER: `${API_BASE_URL}guest-register`,
    FORGOT_PASSWORD: `${API_BASE_URL}send-reset-email`,
    RESET_PASSWORD: `${API_BASE_URL}save-password`,
    EDIT_PROFILE: `${API_BASE_URL}profile-update`,
    EDIT_PREFERENCE: `${API_BASE_URL}customer-preferencess-submit`,
    EDIT_USER_SETTINGS: `${API_BASE_URL}profile-settings-submit`,
    UPDATE_PROFILE_PIC: `${API_BASE_URL}profile-pic-update`,
    REMOVE_PROFILE_PIC: `${API_BASE_URL}profile-pic-remove`,
    SENT_OTP_USER: `${API_BASE_URL}send-otp`,
    SENT_AUTH_OTP: `${API_BASE_URL}send-auth-otp`,
    VERIFY_OPT: `${API_BASE_URL}verify-otp`,
    VERIFY_AUTH_OTP: `${API_BASE_URL}verify-auth-otp`,
    MY_ORDERS: `${API_BASE_URL}my-orders`,
    ORDER_DETAILS: `${API_BASE_URL}order-details`,
    CANCEL_ORDER: `${API_BASE_URL}cancel-order`
  },
  CMS: {
    BANNER_DATA: `${API_BASE_URL}banner-data`,
    SEARCH: `${API_BASE_URL}get-cms-results`,
    SETTINGS: `${API_BASE_URL}get-settings-results`,
    BLOG_DATA: `${API_BASE_URL}get-blog-data`,
    TESTIMONIALS: `${API_BASE_URL}get-testimonials`,
    OCCASSION_DATA: `${API_BASE_URL}occassion-data`,
    ALL_OCCASSION_LIST: `${API_BASE_URL}all-occassion-data`,
    ALL_OCCASSION_THEME_LIST: `${API_BASE_URL}all-themes`,
    MAKERS_DATA: `${API_BASE_URL}get-nearby-vendors`,
    OCCASSION_LIST: `${API_BASE_URL}occassion-list-view`,
    CATEGORY_LIST: `${API_BASE_URL}category-list-view`,
    VENDOR_LIST: `${API_BASE_URL}get-vendors/all`,
    CATEGORY_DETAILS_LIST: `${API_BASE_URL}product-category-list`,
    DELIVERY_LIST: `${API_BASE_URL}delivery-type-list`,
    PRICE_RANGE_AND_SORTS: `${API_BASE_URL}product-price-sort-list`,
    FILTER: `${API_BASE_URL}product-filter-list`,
    EMAIL_SUBSCRIBE: `${API_BASE_URL}email-subscribe`,
    TOP_HEADER: `${API_BASE_URL}banner-header-data`,


    /* SEARCH: `${API_BASE_URL}get-cms-results`,
    TESTIMONIALS: `${API_BASE_URL}get-testimonials`,
    MAKERS_DATA: `${API_BASE_URL}get-nearby-vendors`,
    W_BLOG_DATA: `${API_BASE_URL}get-blog-data`,
    SETTINGS: `${API_BASE_URL}get-settings-results`,
    ABOUT_US: `${API_BASE_URL}get-about-us-data`,
    HOW_IT_WORKS_DATA: `${API_BASE_URL}how-it-works-faq`,
    LISTING_SEO: `${API_BASE_URL}meta-keyword-data`,*/

  },
  COMMON: {
    GET_THEME_CAT_INFO: `${API_BASE_URL}theme-cat-data`,
    GET_PRODUCTS: `${API_BASE_URL}get-products`,
  },
  VENDOR: {
    VENDOR_DETAILS: `${API_BASE_URL}vendor-details`,
    FRIENDORS_LIST: `${API_BASE_URL}vendor-friendors`,
    VENDOR_FAQ: `${API_BASE_URL}vendor-faq`,
    CHANGE_LOVE: `${API_BASE_URL}customer/love`,
    CLICK_COUNT: `${API_BASE_URL}increase-count`,
  },
  PRODUCT: {
    PRODUCT_LIST_SEARCH: `${API_BASE_URL}customer/product_list_search`,
    PRODUCT_GLOBAL_SEARCH: `${API_BASE_URL}get-search-product`,
    PRODUCT_LIST_DETAILS: `${API_BASE_URL}customer/product_list_details`,
    GET_FRIENDORS: `${API_BASE_URL}customer/get_friendors`,
    CHANGE_LOVE: `${API_BASE_URL}customer/love`,
    CHANGE_UNIQUE: `${API_BASE_URL}customer/unique`,
    CHECK_AVAILABILITY: `${API_BASE_URL}product-available-dates`,
    CHECK_LOCATION_AVAILABILITY: `${API_BASE_URL}product-available`,
    CHECK_PRICE_CALCULATION: `${API_BASE_URL}customer/price_calculator`,
    BLACKOUT_DATES: `${API_BASE_URL}all-blackout-Data-List`,
  },
  GLOBAL_SEARCH: {
    OPEN_SEARCH_INPUT: `${API_BASE_URL}customer/product_list_search`,
    TOGGLE_SEARCH_INPUT: `${API_BASE_URL}customer/product_list_search`,
    CLOSE_SEARCH_INPUT: `${API_BASE_URL}customer/product_list_search`,
    SAVE_INPUT_TEXT: `${API_BASE_URL}customer/product_list_search`,
    RESET_INPUT_TEXT: `${API_BASE_URL}customer/product_list_search`,
    SAVE_SEARCH_SUGGESTIONS: `${API_BASE_URL}customer/product_list_search`,
    RESET_SEARCH_SUGGESTIONS: `${API_BASE_URL}customer/product_list_search`,
    SAVE_SEARCH_RESULT: `${API_BASE_URL}customer/product_list_search`,
    RESET_SEARCH_RESULT: `${API_BASE_URL}customer/product_list_search`,
    RESET_ALL: `${API_BASE_URL}customer/product_list_search`,
    GET_SUGGESTIONS: `${API_BASE_URL}customer/search_suggestions`,
    SEARCH: `${API_BASE_URL}customer/global_search`
  },
  LOVE_FEST: {
    GET_FAVORITE: `${API_BASE_URL}customer/my_love`,
    GET_ALL_INSPOBOARD: `${API_BASE_URL}customer/get_pinboard`,
    ADD_UPDATE_INSPOBOARD: `${API_BASE_URL}customer/add_update_pinboard`,
    DELETE_INSPOBOARD: `${API_BASE_URL}customer/delete_pinboard`,
    ADD_TO_INSPOBOARD: `${API_BASE_URL}customer/assign_pinboard`,
    GET_UNIQUE_LISTINGS: `${API_BASE_URL}customer/my_unique`,
  },
  ADDRESS: {
    ADD_UPDATE_ADDRESS: `${API_BASE_URL}customer-address-save`,
    GET_ALL_ADDRESSES: `${API_BASE_URL}get-customer-address`,
    SET_DEFAULT_ADDRESS: `${API_BASE_URL}set-default-customer-address`,
    GET_ADDRESS_DETAILS: `${API_BASE_URL}get-address`,
    DELETE_ADDRESS: `${API_BASE_URL}delete-address`,
    ADD_UPDATE_ADDRESS_GUEST: `${API_BASE_URL}guest-address-save`,
    GET_ALL_ADDRESSES_GUEST: `${API_BASE_URL}get-guest-address`,
    SET_DEFAULT_ADDRESS_GUEST: `${API_BASE_URL}set-default-guest-address`,
  },
  REVIEW: {
    UPLOAD_IMAGE: `${API_BASE_URL}review-image-upload`,
    DELETE_IMAGE: `${API_BASE_URL}review-image-delete`,
    REVIEW: `${API_BASE_URL}rating-review-save`,
    GET_USER_REVIEW: `${API_BASE_URL}get-review-rating-user`,
    GET_PRODUCT_REVIEW: `${API_BASE_URL}get-review-rating`,
    GET_USER_OWN_REVIEW: `${API_BASE_URL}get-reviews`,
    DELETE_USER_OWN_REVIEW: `${API_BASE_URL}review-rating-delete`,
  },
  CHAT: {
    USERS: `${API_BASE_URL}customer/list-message-user`,
    MESSAGE_DETAILS: `${API_BASE_URL}customer/user-message-details`,
    SEND_MESSAGE: `${API_BASE_URL}customer/add-message`,
  },
  QUOTE: {
    SEND_QUOTE_REQUEST: `${API_BASE_URL}send-quote-request`,
    GET_QUOTE: `${API_BASE_URL}get-quotes`,
    GET_QUOTE_LIST: `${API_BASE_URL}get-quote-list`,
  },
  CART: {
    ADD_PRODUCT_TO_CART: `${API_BASE_URL}customer/add_to_cart`,
    SAVE_FOR_LATER: `${API_BASE_URL}customer/save-for-later`,
    EDIT_PRODUCT_TO_CART: `${API_BASE_URL}customer/edit_cart`,
    MODIFY_CART: `${API_BASE_URL}customer/modify_cart`,
    MY_CART: `${API_BASE_URL}customer/my_cart`,
    MOVE_TO_CART: `${API_BASE_URL}customer/move-to-cart`,
    DELETE_WISHLIST: `${API_BASE_URL}customer/delete-wishlist`,
    WISHLIST: `${API_BASE_URL}customer/my-wishlist`,
    SET_CART_ADDRESS: `${API_BASE_URL}set-cart-address`,
    BOOK_CART_CHECK: `${API_BASE_URL}book-cart`,
    CHECK_CART_AVAILABLE: `${API_BASE_URL}customer/check-cart-available`,
    APPLY_COUPONS: `${API_BASE_URL}apply-coupon`,
    REMOVE_COUPONS: `${API_BASE_URL}remove-coupon`,
    REMOVE_NOTES: `${API_BASE_URL}remove-note`,
    GIFT_NOTES: `${API_BASE_URL}add-note`,
    UPDATE_CART_ADDRESS: `${API_BASE_URL}customer/assign-address`,
    CHECKOUT: `${API_BASE_URL}booking-checkout`,
    UPDATE_CART: `${API_BASE_URL}customer/modify_cart`,
    GUEST_CHECK_OUT: `${API_BASE_URL}guest_checkout`,
    REMAINING_DETAILS: `${API_BASE_URL}remaining-details`
  },

}

export const ACTIONS = {
  LOADER: {
    SET_FP_STATUS: 'ACTIONS/LOADER/SET_FP_STATUS',
  },
  USER: {
    OTP: 'ACTIONS/USER/OTP',
    LOGIN: 'ACTIONS/USER/LOGIN',
    ME: 'ACTIONS/USER/ME',
    LOGOUT: 'ACTIONS/USER/LOGOUT',
    SOCIAL_LOGIN: 'ACTIONS/USER/SOCIAL_LOGIN',
    INSTAGRAM_DATA: 'ACTIONS/USER/INSTAGRAM_DATA',
    REGISTER: 'ACTIONS/USER/REGISTER',
    GUEST_REGISTER: 'ACTIONS/USER/GUEST_REGISTER',
    FORGOT_PASSWORD: 'ACTIONS/USER/FORGOT_PASSWORD',
    RESET_PASSWORD: 'ACTIONS/USER/RESET_PASSWORD',
    EDIT_PROFILE: 'ACTIONS/USER/EDIT_PROFILE',
    EDIT_PREFERENCE: 'ACTIONS/USER/EDIT_PREFERENCE',
    EDIT_USER_SETTINGS: 'ACTIONS/USER/EDIT_USER_SETTINGS',
    UPDATE_PROFILE_PIC: 'ACTIONS/USER/UPDATE_PROFILE_PIC',
    REMOVE_PROFILE_PIC: 'ACTIONS/USER/REMOVE_PROFILE_PIC',
    SENT_OTP_USER: 'ACTIONS/USER/SENT_OTP_USER',
    SENT_AUTH_OTP: 'ACTIONS/USER/SENT_AUTH_OTP',
    VERIFY_OPT: 'ACTIONS/USER/VERIFY_OPT',
    VERIFY_AUTH_OTP: 'ACTIONS/USER/VERIFY_AUTH_OTP',
    MY_ORDERS: 'ACTIONS/USER/MY_ORDERS',
    ORDER_DETAILS: 'ACTIONS/USER/ORDER_DETAILS',
    CANCEL_ORDER: 'ACTIONS/USER/CANCEL_ORDER',
  },
  CMS: {
    BANNER_DATA: 'ACTION/CMS/BANNER_DATA',
    SEARCH: 'ACTION/CMS/SEARCH',
    SETTINGS: 'ACTION/CMS/SETTINGS',
    BLOG_DATA: 'ACTION/CMS/BLOG_DATA',
    TESTIMONIALS: 'ACTION/CMS/TESTIMONIALS',
    OCCASSION_DATA: 'ACTION/CMS/OCCASSION_DATA',
    ALL_OCCASSION_LIST: 'ACTION/CMS/ALL_OCCASSION_LIST',
    ALL_OCCASSION_THEME_LIST: 'ACTION/CMS/ALL_OCCASSION_THEME_LIST',
    MAKERS_DATA: 'ACTION/CMS/MAKERS_DATA',
    OCCASSION_LIST: 'ACTION/CMS/OCCASSION_LIST',
    CATEGORY_LIST: 'ACTION/CMS/CATEGORY_LIST',
    VENDOR_LIST: 'ACTION/CMS/VENDOR_LIST',
    CATEGORY_DETAILS_LIST: 'ACTION/CMS/CATEGORY_DETAILS_LIST',
    DELIVERY_LIST: 'ACTION/CMS/DELIVERY_LIST',
    PRICE_RANGE_AND_SORTS: 'ACTION/CMS/PRICE_RANGE_AND_SORTS',
    FILTER: 'ACTION/CMS/FILTER',
    EMAIL_SUBSCRIBE: 'ACTION/CMS/EMAIL_SUBSCRIBE',
    TOP_HEADER: 'ACTION/CMS/TOP_HEADER',
  },
  COMMON: {
    GET_THEME_CAT_INFO: 'ACTIONS/COMMON/GET_THEME_CAT_INFO',
    GET_PRODUCTS: 'ACTIONS/COMMON/GET_PRODUCTS',
  },
  VENDOR: {
    VENDOR_DETAILS: 'ACTIONS/VENDOR/VENDOR_DETAILS',
    FRIENDORS_LIST: 'ACTIONS/VENDOR/FRIENDORS_LIST',
    VENDOR_FAQ: 'ACTIONS/VENDOR/VENDOR_FAQ',
    CHANGE_LOVE: 'ACTIONS/VENDOR/CHANGE_LOVE',
    CLICK_COUNT: 'ACTIONS/VENDOR/CLICK_COUNT',
  },
  PRODUCT: {
    PRODUCT_LIST_SEARCH: 'ACTIONS/PRODUCT/PRODUCT_LIST_SEARCH',
    PRODUCT_GLOBAL_SEARCH: 'ACTIONS/PRODUCT/PRODUCT_GLOBAL_SEARCH',
    PRODUCT_LIST_DETAILS: 'ACTIONS/PRODUCT/PRODUCT_LIST_DETAILS',
    GET_FRIENDORS: 'ACTIONS/PRODUCT/GET_FRIENDORS',
    CHANGE_LOVE: 'ACTIONS/PRODUCT/CHANGE_LOVE',
    CHANGE_UNIQUE: 'ACTIONS/PRODUCT/CHANGE_UNIQUE',
    CHECK_AVAILABILITY: 'ACTIONS/PRODUCT/CHECK_AVAILABILITY',
    CHECK_LOCATION_AVAILABILITY: 'ACTIONS/PRODUCT/CHECK_LOCATION_AVAILABILITY',
    CHECK_PRICE_CALCULATION: 'ACTIONS/PRODUCT/CHECK_PRICE_CALCULATION',
    BLACKOUT_DATES: 'ACTIONS/PRODUCT/BLACKOUT_DATES',
  },
  GLOBAL_SEARCH: {
    OPEN_SEARCH_INPUT: 'ACTION/GLOBAL_SEARCH/OPEN_SEARCH_INPUT',
    TOGGLE_SEARCH_INPUT: 'ACTION/GLOBAL_SEARCH/TOGGLE_SEARCH_INPUT',
    CLOSE_SEARCH_INPUT: 'ACTION/GLOBAL_SEARCH/CLOSE_SEARCH_INPUT',
    SAVE_INPUT_TEXT: 'ACTION/GLOBAL_SEARCH/SAVE_INPUT_TEXT',
    RESET_INPUT_TEXT: 'ACTION/GLOBAL_SEARCH/RESET_INPUT_TEXT',
    SAVE_SEARCH_SUGGESTIONS: 'ACTION/GLOBAL_SEARCH/SAVE_SEARCH_SUGGESTIONS',
    RESET_SEARCH_SUGGESTIONS: 'ACTION/GLOBAL_SEARCH/RESET_SEARCH_SUGGESTIONS',
    SAVE_SEARCH_RESULT: 'ACTION/GLOBAL_SEARCH/SAVE_SEARCH_RESULT',
    RESET_SEARCH_RESULT: 'ACTION/GLOBAL_SEARCH/RESET_SEARCH_RESULT',
    RESET_ALL: 'ACTION/GLOBAL_SEARCH/RESET_ALL',
    GET_SUGGESTIONS: 'ACTION/GLOBAL_SEARCH/GET_SUGGESTIONS',
    SEARCH: 'ACTION/GLOBAL_SEARCH/SEARCH',
  },
  LOVE_FEST: {
    GET_FAVORITE: "ACTION/LOVE_FEST/GET_FAVORITE",
    GET_ALL_INSPOBOARD: "ACTION/LOVE_FEST/GET_ALL_INSPOBOARD",
    ADD_UPDATE_INSPOBOARD: "ACTION/LOVE_FEST/ADD_UPDATE_INSPOBOARD",
    DELETE_INSPOBOARD: "ACTION/LOVE_FEST/DELETE_INSPOBOARD",
    ADD_TO_INSPOBOARD: "ACTION/LOVE_FEST/ADD_TO_INSPOBOARD",
    GET_UNIQUE_LISTINGS: "ACTION/LOVE_FEST/GET_UNIQUE_LISTINGS"
  },
  ADDRESS: {
    ADD_UPDATE_ADDRESS: "ACTION/ADDRESS/ADD_UPDATE_ADDRESS",
    GET_ALL_ADDRESSES: "ACTION/ADDRESS/GET_ALL_ADDRESSES",
    SET_DEFAULT_ADDRESS: "ACTION/ADDRESS/SET_DEFAULT_ADDRESS",
    GET_ADDRESS_DETAILS: "ACTION/ADDRESS/GET_ADDRESS_DETAILS",
    DELETE_ADDRESS: "ACTION/ADDRESS/DELETE_ADDRESS",
    ADD_UPDATE_ADDRESS_GUEST: "ACTION/ADDRESS/ADD_UPDATE_ADDRESS_GUEST",
    GET_ALL_ADDRESSES_GUEST: "ACTION/ADDRESS/GET_ALL_ADDRESSES_GUEST",
    SET_DEFAULT_ADDRESS_GUEST: "ACTION/ADDRESS/SET_DEFAULT_ADDRESS_GUEST",
  },
  REVIEW: {
    UPLOAD_IMAGE: 'ACTION/REVIEW/UPLOAD_IMAGE',
    DELETE_IMAGE: 'ACTION/REVIEW/DELETE_IMAGE',
    REVIEW: 'ACTION/REVIEW/REVIEW',
    GET_USER_REVIEW: 'ACTION/REVIEW/GET_USER_REVIEW',
    GET_PRODUCT_REVIEW: 'ACTION/REVIEW/GET_PRODUCT_REVIEW',
    GET_USER_OWN_REVIEW: 'ACTION/REVIEW/GET_USER_OWN_REVIEW',
    DELETE_USER_OWN_REVIEW: 'ACTION/REVIEW/DELETE_USER_OWN_REVIEW'
  },
  CHAT: {
    USERS: 'ACTION/CHAT/USERS',
    MESSAGE_DETAILS: 'ACTION/CHAT/MESSAGE_DETAILS',
    SEND_MESSAGE: 'ACTION/CHAT/SEND_MESSAGE',
  },
  QUOTE: {
    SEND_QUOTE_REQUEST: 'ACTIONS/QUOTE/SEND_QUOTE_REQUEST',
    GET_QUOTE: 'ACTIONS/QUOTE/GET_QUOTE',
    GET_QUOTE_LIST: 'ACTIONS/QUOTE/GET_QUOTE_LIST',
  },
  CART: {
    ADD_PRODUCT_TO_CART: 'ACTION/CART/ADD_PRODUCT_TO_CART',
    SAVE_FOR_LATER: 'ACTION/CART/SAVE_FOR_LATER',
    EDIT_PRODUCT_TO_CART: 'ACTION/CART/EDIT_PRODUCT_TO_CART',
    MODIFY_CART: 'ACTION/CART/MODIFY_CART',
    MY_CART: 'ACTION/CART/MY_CART',
    MOVE_TO_CART: 'ACTION/CART/MOVE_TO_CART',
    DELETE_WISHLIST: 'ACTION/CART/DELETE_WISHLIST',
    WISHLIST: 'ACTION/CART/WISHLIST',
    SET_CART_ADDRESS: 'ACTION/CART/SET_CART_ADDRESS',
    BOOK_CART_CHECK: 'ACTION/CART/BOOK_CART_CHECK',
    CHECK_CART_AVAILABLE: 'ACTION/CART/CHECK_CART_AVAILABLE',
    APPLY_COUPONS: 'ACTION/CART/APPLY_COUPONS',
    REMOVE_COUPONS: 'ACTION/CART/REMOVE_COUPONS',
    REMOVE_NOTES: 'ACTION/CART/REMOVE_NOTES',
    GIFT_NOTES: 'ACTION/CART/GIFT_NOTES',
    UPDATE_CART_ADDRESS: 'ACTION/CART/UPDATE_CART_ADDRESS',
    CHECKOUT: 'ACTION/CART/CHECKOUT',
    UPDATE_CART: 'ACTION/CART/UPDATE_CART',
    GUEST_CHECK_OUT: 'ACTION/CART/GUEST_CHECK_OUT',
    REMAINING_DETAILS: 'ACTION/CART/REMAINING_DETAILS'
  },
}

export const SAGA_ACTIONS = {
  USER: {
    OTP: 'SAGA_ACTIONS/USER/OTP',
    LOGIN: 'SAGA_ACTIONS/USER/LOGIN',
    LOGOUT: 'SAGA_ACTIONS/USER/LOGOUT',
    ME: 'SAGA_ACTIONS/USER/ME',
    SOCIAL_LOGIN: 'SAGA_ACTIONS/USER/SOCIAL_LOGIN',
    INSTAGRAM_DATA: 'SAGA_ACTIONS/USER/INSTAGRAM_DATA',
    REGISTER: 'SAGA_ACTIONS/USER/REGISTER',
    GUEST_REGISTER: 'SAGA_ACTIONS/USER/GUEST_REGISTER',
    FORGOT_PASSWORD: 'SAGA_ACTIONS/USER/FORGOT_PASSWORD',
    RESET_PASSWORD: 'SAGA_ACTIONS/USER/RESET_PASSWORD',
    EDIT_PROFILE: 'SAGA_ACTIONS/USER/EDIT_PROFILE',
    EDIT_PREFERENCE: 'SAGA_ACTIONS/USER/EDIT_PREFERENCE',
    EDIT_USER_SETTINGS: 'SAGA_ACTIONS/USER/EDIT_USER_SETTINGS',
    UPDATE_PROFILE_PIC: 'SAGA_ACTIONS/USER/UPDATE_PROFILE_PIC',
    REMOVE_PROFILE_PIC: 'SAGA_ACTIONS/USER/REMOVE_PROFILE_PIC',
    SENT_OTP_USER: 'SAGA_ACTIONS/USER/SENT_OTP_USER',
    SENT_AUTH_OTP: 'SAGA_ACTIONS/USER/SENT_AUTH_OTP',
    VERIFY_OPT: 'SAGA_ACTIONS/USER/VERIFY_OPT',
    VERIFY_AUTH_OTP: 'SAGA_ACTIONS/USER/VERIFY_AUTH_OTP',
    MY_ORDERS: 'SAGA_ACTIONS/USER/MY_ORDERS',
    ORDER_DETAILS: 'SAGA_ACTIONS/USER/ORDER_DETAILS',
    CANCEL_ORDER: 'SAGA_ACTIONS/USER/CANCEL_ORDER',
  },
  CMS: {
    BANNER_DATA: 'SAGA_ACTION/CMS/BANNER_DATA',
    SEARCH: 'SAGA_ACTION/CMS/SEARCH',
    SETTINGS: 'SAGA_ACTION/CMS/SETTINGS',
    BLOG_DATA: 'SAGA_ACTION/CMS/BLOG_DATA',
    TESTIMONIALS: 'SAGA_ACTION/CMS/TESTIMONIALS',
    OCCASSION_DATA: 'SAGA_ACTION/CMS/OCCASSION_DATA',
    ALL_OCCASSION_LIST: 'SAGA_ACTION/CMS/ALL_OCCASSION_LIST',
    ALL_OCCASSION_THEME_LIST: 'SAGA_ACTION/CMS/ALL_OCCASSION_THEME_LIST',
    MAKERS_DATA: 'SAGA_ACTION/CMS/MAKERS_DATA',
    OCCASSION_LIST: 'SAGA_ACTION/CMS/OCCASSION_LIST',
    CATEGORY_LIST: 'SAGA_ACTION/CMS/CATEGORY_LIST',
    VENDOR_LIST: 'SAGA_ACTION/CMS/VENDOR_LIST',
    CATEGORY_DETAILS_LIST: 'SAGA_ACTION/CMS/CATEGORY_DETAILS_LIST',
    DELIVERY_LIST: 'SAGA_ACTION/CMS/DELIVERY_LIST',
    PRICE_RANGE_AND_SORTS: 'SAGA_ACTION/CMS/PRICE_RANGE_AND_SORTS',
    FILTER: 'SAGA_ACTION/CMS/FILTER',
    EMAIL_SUBSCRIBE: 'SAGA_ACTION/CMS/EMAIL_SUBSCRIBE',
    TOP_HEADER: 'SAGA_ACTION/CMS/TOP_HEADER',
  },
  COMMON: {
    GET_THEME_CAT_INFO: 'SAGA_ACTION/COMMON/GET_THEME_CAT_INFO',
    GET_PRODUCTS: 'SAGA_ACTION/COMMON/GET_PRODUCTS',
  },
  VENDOR: {
    VENDOR_DETAILS: 'SAGA_ACTIONS/VENDOR/VENDOR_DETAILS',
    FRIENDORS_LIST: 'SAGA_ACTIONS/VENDOR/FRIENDORS_LIST',
    VENDOR_FAQ: 'SAGA_ACTIONS/VENDOR/VENDOR_FAQ',
    CHANGE_LOVE: 'SAGA_ACTIONS/VENDOR/CHANGE_LOVE',
    CLICK_COUNT: 'SAGA_ACTIONS/VENDOR/CLICK_COUNT',
  },
  PRODUCT: {
    PRODUCT_LIST_SEARCH: 'SAGA_ACTIONS/PRODUCT/PRODUCT_LIST_SEARCH',
    PRODUCT_GLOBAL_SEARCH: 'SAGA_ACTIONS/PRODUCT/PRODUCT_GLOBAL_SEARCH',
    PRODUCT_LIST_DETAILS: 'SAGA_ACTIONS/PRODUCT/PRODUCT_LIST_DETAILS',
    GET_FRIENDORS: 'SAGA_ACTIONS/PRODUCT/GET_FRIENDORS',
    CHANGE_LOVE: 'SAGA_ACTIONS/PRODUCT/CHANGE_LOVE',
    CHANGE_UNIQUE: 'SAGA_ACTIONS/PRODUCT/CHANGE_UNIQUE',
    CHECK_AVAILABILITY: 'SAGA_ACTIONS/PRODUCT/CHECK_AVAILABILITY',
    CHECK_LOCATION_AVAILABILITY: 'SAGA_ACTIONS/PRODUCT/CHECK_LOCATION_AVAILABILITY',
    CHECK_PRICE_CALCULATION: 'SAGA_ACTIONS/PRODUCT/CHECK_PRICE_CALCULATION',
    BLACKOUT_DATES: 'SAGA_ACTIONS/PRODUCT/BLACKOUT_DATES',
  },
  GLOBAL_SEARCH: {
    OPEN_SEARCH_INPUT: 'SAGA_ACTIONS/GLOBAL_SEARCH/OPEN_SEARCH_INPUT',
    TOGGLE_SEARCH_INPUT: 'SAGA_ACTIONS/GLOBAL_SEARCH/TOGGLE_SEARCH_INPUT',
    CLOSE_SEARCH_INPUT: 'SAGA_ACTIONS/GLOBAL_SEARCH/CLOSE_SEARCH_INPUT',
    SAVE_INPUT_TEXT: 'SAGA_ACTIONS/GLOBAL_SEARCH/SAVE_INPUT_TEXT',
    RESET_INPUT_TEXT: 'SAGA_ACTIONS/GLOBAL_SEARCH/RESET_INPUT_TEXT',
    SAVE_SEARCH_SUGGESTIONS: 'SAGA_ACTIONS/GLOBAL_SEARCH/SAVE_SEARCH_SUGGESTIONS',
    RESET_SEARCH_SUGGESTIONS: 'SAGA_ACTIONS/GLOBAL_SEARCH/RESET_SEARCH_SUGGESTIONS',
    SAVE_SEARCH_RESULT: 'SAGA_ACTIONS/GLOBAL_SEARCH/SAVE_SEARCH_RESULT',
    RESET_SEARCH_RESULT: 'SAGA_ACTIONS/GLOBAL_SEARCH/RESET_SEARCH_RESULT',
    RESET_ALL: 'SAGA_ACTIONS/GLOBAL_SEARCH/RESET_ALL',
    GET_SUGGESTIONS: 'SAGA_ACTIONS/GLOBAL_SEARCH/GET_SUGGESTIONS',
    SEARCH: 'SAGA_ACTIONS/GLOBAL_SEARCH/SEARCH',
  },
  LOVE_FEST: {
    GET_FAVORITE: "SAGA_ACTIONS/LOVE_FEST/GET_FAVORITE",
    GET_ALL_INSPOBOARD: "SAGA_ACTIONS/LOVE_FEST/GET_ALL_INSPOBOARD",
    ADD_UPDATE_INSPOBOARD: "SAGA_ACTIONS/LOVE_FEST/ADD_UPDATE_INSPOBOARD",
    DELETE_INSPOBOARD: "SAGA_ACTIONS/LOVE_FEST/DELETE_INSPOBOARD",
    ADD_TO_INSPOBOARD: "SAGA_ACTIONS/LOVE_FEST/ADD_TO_INSPOBOARD",
    GET_UNIQUE_LISTINGS: "SAGA_ACTIONS/LOVE_FEST/GET_UNIQUE_LISTINGS"
  },
  ADDRESS: {
    ADD_UPDATE_ADDRESS: "SAGA_ACTIONS/ADDRESS/ADD_UPDATE_ADDRESS",
    GET_ALL_ADDRESSES: "SAGA_ACTIONS/ADDRESS/GET_ALL_ADDRESSES",
    SET_DEFAULT_ADDRESS: "SAGA_ACTIONS/ADDRESS/SET_DEFAULT_ADDRESS",
    GET_ADDRESS_DETAILS: "SAGA_ACTIONS/ADDRESS/GET_ADDRESS_DETAILS",
    DELETE_ADDRESS: "SAGA_ACTIONS/ADDRESS/DELETE_ADDRESS",
    ADD_UPDATE_ADDRESS_GUEST: "SAGA_ACTIONS/ADDRESS/ADD_UPDATE_ADDRESS_GUEST",
    GET_ALL_ADDRESSES_GUEST: "SAGA_ACTIONS/ADDRESS/GET_ALL_ADDRESSES_GUEST",
    SET_DEFAULT_ADDRESS_GUEST: "SAGA_ACTIONS/ADDRESS/SET_DEFAULT_ADDRESS_GUEST",
  },
  REVIEW: {
    UPLOAD_IMAGE: 'SAGA_ACTIONS/REVIEW/UPLOAD_IMAGE',
    DELETE_IMAGE: 'SAGA_ACTIONS/REVIEW/DELETE_IMAGE',
    REVIEW: 'SAGA_ACTIONS/REVIEW/REVIEW',
    GET_USER_REVIEW: 'SAGA_ACTIONS/REVIEW/GET_USER_REVIEW',
    GET_PRODUCT_REVIEW: 'SAGA_ACTIONS/REVIEW/GET_PRODUCT_REVIEW',
    GET_USER_OWN_REVIEW: 'SAGA_ACTIONS/REVIEW/GET_USER_OWN_REVIEW',
    DELETE_USER_OWN_REVIEW: 'SAGA_ACTIONS/REVIEW/DELETE_USER_OWN_REVIEW'
  },
  CHAT: {
    USERS: 'SAGA_ACTIONS/CHAT/USERS',
    MESSAGE_DETAILS: 'SAGA_ACTIONS/CHAT/MESSAGE_DETAILS',
    SEND_MESSAGE: 'SAGA_ACTIONS/CHAT/SEND_MESSAGE',
  },
  QUOTE: {
    SEND_QUOTE_REQUEST: 'SAGA_ACTIONS/QUOTE/SEND_QUOTE_REQUEST',
    GET_QUOTE: 'SAGA_ACTIONS/QUOTE/GET_QUOTE',
    GET_QUOTE_LIST: 'SAGA_ACTIONS/QUOTE/GET_QUOTE_LIST',
  },
  CART: {
    ADD_PRODUCT_TO_CART: 'SAGA_ACTIONS/CART/ADD_PRODUCT_TO_CART',
    SAVE_FOR_LATER: 'SAGA_ACTIONS/CART/SAVE_FOR_LATER',
    EDIT_PRODUCT_TO_CART: 'SAGA_ACTIONS/CART/EDIT_PRODUCT_TO_CART',
    MODIFY_CART: 'SAGA_ACTIONS/CART/MODIFY_CART',
    MY_CART: 'SAGA_ACTIONS/CART/MY_CART',
    MOVE_TO_CART: 'SAGA_ACTIONS/CART/MOVE_TO_CART',
    DELETE_WISHLIST: 'SAGA_ACTIONS/CART/DELETE_WISHLIST',
    WISHLIST: 'SAGA_ACTIONS/CART/WISHLIST',
    SET_CART_ADDRESS: 'SAGA_ACTIONS/CART/SET_CART_ADDRESS',
    BOOK_CART_CHECK: 'SAGA_ACTIONS/CART/BOOK_CART_CHECK',
    CHECK_CART_AVAILABLE: 'SAGA_ACTIONS/CART/CHECK_CART_AVAILABLE',
    APPLY_COUPONS: 'SAGA_ACTIONS/CART/APPLY_COUPONS',
    REMOVE_COUPONS: 'SAGA_ACTIONS/CART/REMOVE_COUPONS',
    REMOVE_NOTES: 'SAGA_ACTIONS/CART/REMOVE_NOTES',
    GIFT_NOTES: 'SAGA_ACTIONS/CART/GIFT_NOTES',
    UPDATE_CART_ADDRESS: 'SAGA_ACTIONS/CART/UPDATE_CART_ADDRESS',
    CHECKOUT: 'SAGA_ACTIONS/CART/CHECKOUT',
    UPDATE_CART: 'SAGA_ACTIONS/CART/UPDATE_CART',
    GUEST_CHECK_OUT: 'SAGA_ACTIONS/CART/GUEST_CHECK_OUT',
    REMAINING_DETAILS: 'SAGA_ACTIONS/CART/REMAINING_DETAILS'

  },

}