import { combineReducers } from 'redux';

import loaderReducer from './common/loaderReducer'
import userReducer from './user/userReducer'
import cartReducer from './cart/cartReducer'

const rootReducer = combineReducers({
  loader: loaderReducer,
  user: userReducer,
  cart: cartReducer,
})

export default rootReducer