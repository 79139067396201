import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; //useHistory, useParams
import { URLS } from '../_config';
import { useUserSelector } from '../_common/hooks/selectors/userSelector';

import { useUserApi } from '../_common/hooks/api/UserApiHook';
import { useCmsApi } from '../_common/hooks/api/cmsApiHook';
import { useCartApi } from '../_common/hooks/api/cartApiHook';
import { FaTimes } from 'react-icons/fa';
import { AiOutlineMenu } from 'react-icons/ai';
import { BiLogOutCircle, BiSortDown } from 'react-icons/bi';
import { GoSettings } from 'react-icons/go';
import { HiOutlineUser } from 'react-icons/hi';
import Drawer from '@material-ui/core/Drawer';
import { Container, Row, Button, Accordion, Dropdown } from 'react-bootstrap';
import { useCartSelector } from '../_common/hooks/selectors/cartSelector';
import CartItems from "./cart/cartItems";
import NavSearch from "./common/navSearch";


import '../style/filter.scss';
import PlacesAutocomplete, { geocodeByAddress, geocodeByPlaceId, getLatLng } from "react-places-autocomplete";
import { useToaster } from "../_common/hooks/common/appTosterHook";
import ReactDatePicker from "react-datepicker";
import "../../node_modules/react-datepicker/src/stylesheets/datepicker.scss";


interface incomingProps {
  priceRanges: any;
  categoryList: any;
  occassionList: any;
  deliveryList: any;
  sorts?: any;
  setFilter: any;
  filterData: any;
}

export default function Filter({ setFilter, filterData, priceRanges, categoryList, occassionList, deliveryList, sorts }: incomingProps) {

  const user: any = useUserSelector();
  const cart: any = useCartSelector();
  const userApi = useUserApi();
  const cmsApi = useCmsApi();
  const cartApi = useCartApi();

  const [drawer, setDrawer] = useState<boolean>(false);
  const [sortDrawer, setSortDrawer] = useState<boolean>(false);
  const [mobileView, setMobileView] = useState<boolean>(false);
  const [value, setValue] = useState<any>('');
  const [lat, setLat] = useState<any>(0);
  const [lng, setLng] = useState<any>(0);
  const [gmapScript, setGmapScript] = useState<boolean>(false);
  const [address, setAddress] = useState<any>(null);
  const toast = useToaster();
  const [addressError, setAddressError] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<any>(null);


  const setViewMode = () => {
    if ((window as any).innerWidth > 1332) {
      setMobileView(false);
    } else {
      setMobileView(true);
    }
  }
  const addScript = (path: any, id: any) => {
    return new Promise((resolve, reject) => {
      if (document.getElementById(id)) {
        resolve(true);
      } else {
        let script = document.createElement('script');
        script.onload = function () {
          resolve(true);
        };
        script.src = path;
        script.id = id;
        document.head.appendChild(script);
      }

    });
  }

  const setLatLng = (lat: any, lng: any) => {
    setLat(lat);
    setLng(lng);
    console.log(lat, lng)
  }

  const checkGoogle = () => {
    return new Promise((resolve, reject) => {
      if ((window as any).google !== undefined) {
        setGmapScript(true)
        resolve(true);
      } else {
        let timer = setInterval(() => {
          if ((window as any).google !== undefined) {
            clearInterval(timer);
            setGmapScript(true)
            resolve(true);
          }
        }, 100)
      }
    })
  }

  const load_Google_Map = () => {
    return new Promise((resolve, reject) => {
      addScript('https://maps.googleapis.com/maps/api/js?key=' + process.env.REACT_APP_GOOGLE_KEY + '&libraries=places,geometry,drawing&v=weekly', 'g-map').then(() => {
        checkGoogle().then(() => {
          addScript('/assets/js/oms.min.js', 'oms').then(() => { resolve(true) })
        })
      })

    });
  }

  const handleChange = (address: any) => {
    setValue(address)
    console.log('Handle Change ===================>', address)
    setLatLng(0, 0);
    setAddress(address);
  };

  const onDateRangeChange = (date: any) => {
    console.log('dateRange =>', date);
    setStartDate(date);
    let formattedDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
    setFilter('date', formattedDate);
    //
  }

  const handleSelect = async (address: any, placeId: any) => {
    console.log('Handle Select =>', address, placeId);
    setValue(address);
    setFilter('zip_code', address)
    geocodeByAddress(address).then((results: any) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setLatLng(lat, lng)
      });
    geocodeByPlaceId(placeId).then((results: any) => {
      const place = results[0];
      console.log(place);

      const { long_name: postalCode = '' } =
        place.address_components.find((c: any) => c.types.includes('postal_code')) || {};
      console.log("postalCode", postalCode);

    })

  };

  useEffect(() => {
    setViewMode();
    load_Google_Map();
    (window as any).onresize = () => {
      setViewMode()
    }
  }, [])



  return (
    <React.Fragment>
      {mobileView ?
        <div className="mobile-filter">
          {/* <GoSettings className="menu-icon" onClick={() => { setDrawer(true) }} /> */}
          <div className="btns">
            <Button variant="primary" onClick={() => { setDrawer(true) }}><GoSettings className="sort-icon" /> Filter</Button>

            <Button variant="secondary" onClick={() => { setSortDrawer(true) }}><BiSortDown className="sort-icon" /> Sort By</Button>
          </div>

          {/* <Dropdown>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
              <BiSortDown className="sort-icon" /> Sort By
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {sorts?.map((label: any, i: any) => (
                <Dropdown.Item key={i} htmlFor={"sort-filter-" + i}>
                  <div className="form-check" >
                    <input className="form-check-input" type="checkbox" name="sort_by" value="" id={"sort-filter-" + i} onChange={(e: any) => { e.target.checked ? setFilter('sort_by', (i + 1)) : setFilter('sort_by', null) }} checked={filterData?.sort_by == (i + 1)} />
                    <label className="form-check-label" htmlFor={"sort-filter-" + i}>
                      {label}
                    </label>
                  </div>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
        :
        <div className="filter">
          <h3>Filter Options</h3>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Price Range</Accordion.Header>
              <Accordion.Body>
                <ul>
                  {priceRanges?.map((label: any, i: any) => (
                    <li key={i}>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id={"price-filter-" + i} name="price-range" onChange={(e: any) => { e.target.checked ? setFilter('price_range', (i + 1)) : setFilter('price_range', null) }} checked={filterData?.price_range == (i + 1)} />
                        <label className="form-check-label" htmlFor={"price-filter-" + i}>
                          {label}
                        </label>
                      </div>
                    </li>
                  ))}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Categories</Accordion.Header>
              <Accordion.Body>
                <ul>

                  {categoryList?.map((data: any, i: any) => (
                    <li key={i}>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="cat_id" value="" id={"cat_id-" + i} onChange={(e: any) => { e.target.checked ? setFilter('cat_id', data.id) : setFilter('cat_id', null) }} checked={filterData?.cat_id == data.id} />
                        <label className="form-check-label" htmlFor={"cat_id-" + i}>
                          {data.name}
                        </label>
                      </div>
                    </li>
                  ))}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Occassions</Accordion.Header>
              <Accordion.Body>
                <ul>
                  {occassionList?.map((data: any, i: any) => (
                    <li key={i}>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="occassion_id" value="" id={"occassion_id-" + i} onChange={(e: any) => { e.target.checked ? setFilter('occassion_id', data.id) : setFilter('occassion_id', null) }} checked={filterData?.occassion_id == data.id} />
                        <label className="form-check-label" htmlFor={"occassion_id-" + i}>
                          {data.text}
                        </label>
                      </div>
                    </li>
                  ))}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Location</Accordion.Header>
              <Accordion.Body>
                <div className="location-filter">
                  {gmapScript ?
                    <PlacesAutocomplete
                      value={value}
                      onChange={handleChange}
                      onSelect={handleSelect}
                      onError={() => toast.error('No results found')}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div className="add_input">
                          <div className="form-group">
                            <input onFocus={() => { setAddressError(false) }}
                              {...getInputProps({
                                name: 'address',
                                placeholder: 'Add the address',
                                className: 'form-control' + (addressError ? ' is-invalid' : ''),
                              })}
                            />
                            {loading && <div className="text-white spinner-border ml-2" />}
                            {addressError ? <div className="invalid-feedback">required</div> : null}
                          </div>
                          {/* {touched &&
                                (error && (
                                    <FormFeedback>
                                    {error}
                                </FormFeedback>
                                ))} */}
                          <div className="autocomplete-dropdown-container">
                            {suggestions.map((suggestion, i) => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? { backgroundColor: 'rgb(38,132,255)', color: '#fff', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                  key={i}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    : null}
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Date</Accordion.Header>
              <Accordion.Body>
                <div className="location-filter">
                  <ReactDatePicker className="form-control" minDate={new Date()} selected={startDate} onChange={(date) => onDateRangeChange(date)} />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>Shipping/Delivery</Accordion.Header>
              <Accordion.Body>
                <ul>
                  {deliveryList?.map((data: any, i: any) => (
                    <li key={i}>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="delivery_type_id" value="" id={"delivery_type_id-" + i} onChange={(e: any) => { e.target.checked ? setFilter('delivery_type_id', data.id) : setFilter('delivery_type_id', null) }} checked={filterData?.delivery_type_id == data.id} />
                        <label className="form-check-label" htmlFor={"delivery_type_id-" + i}>
                          {data.type}
                        </label>
                      </div>
                    </li>
                  ))}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            {sorts?.length > 0 ?
              <Accordion.Item eventKey="6">
                <Accordion.Header>Sort By</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    {sorts?.map((label: any, i: any) => (
                      <li key={i}>
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" name="sort_by" value="" id={"sort-filter-" + i} onChange={(e: any) => { e.target.checked ? setFilter('sort_by', (i + 1)) : setFilter('sort_by', null) }} checked={filterData?.sort_by == (i + 1)} />
                          <label className="form-check-label" htmlFor={"sort-filter-" + i}>
                            {label}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              : ''}
          </Accordion>
        </div>
      }









      <Drawer anchor="right" open={drawer} onClose={() => { setDrawer(false) }}>
        <div className="drawer-body filter-drawer">
          <FaTimes className="close-btn" onClick={() => { setDrawer(false) }} />
          <div className="drawer-bar">
            Filter
          </div>
          <div className="filter">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Price Range</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    {priceRanges?.map((label: any, i: any) => (
                      <li key={i}>
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" id={"price-filter-" + i} name="price-range" onChange={(e: any) => { e.target.checked ? setFilter('price_range', (i + 1)) : setFilter('price_range', null) }} checked={filterData?.price_range == (i + 1)} />
                          <label className="form-check-label" htmlFor={"price-filter-" + i}>
                            {label}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Categories</Accordion.Header>
                <Accordion.Body>
                  <ul>

                    {categoryList?.map((data: any, i: any) => (
                      <li key={i}>
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" name="cat_id" value="" id={"cat_id-" + i} onChange={(e: any) => { e.target.checked ? setFilter('cat_id', data.id) : setFilter('cat_id', null) }} checked={filterData?.cat_id == data.id} />
                          <label className="form-check-label" htmlFor={"cat_id-" + i}>
                            {data.name}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Occassions</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    {occassionList?.map((data: any, i: any) => (
                      <li key={i}>
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" name="occassion_id" value="" id={"occassion_id-" + i} onChange={(e: any) => { e.target.checked ? setFilter('occassion_id', data.id) : setFilter('occassion_id', null) }} checked={filterData?.occassion_id == data.id} />
                          <label className="form-check-label" htmlFor={"occassion_id-" + i}>
                            {data.text}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Location</Accordion.Header>
                <Accordion.Body>
                  <div className="location-filter">
                    {gmapScript ?
                      <PlacesAutocomplete
                        value={value}
                        onChange={handleChange}
                        onSelect={handleSelect}
                        onError={() => toast.error('No results found')}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div className="add_input">
                            <div className="form-group">
                              <input onFocus={() => { setAddressError(false) }}
                                {...getInputProps({
                                  name: 'address',
                                  placeholder: 'Add the address',
                                  className: 'form-control' + (addressError ? ' is-invalid' : ''),
                                })}
                              />
                              {loading && <div className="text-white spinner-border ml-2" />}
                              {addressError ? <div className="invalid-feedback">required</div> : null}
                            </div>
                            {/* {touched &&
                                (error && (
                                    <FormFeedback>
                                    {error}
                                </FormFeedback>
                                ))} */}
                            <div className="autocomplete-dropdown-container">
                              {suggestions.map((suggestion, i) => {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? { backgroundColor: 'rgb(38,132,255)', color: '#fff', cursor: 'pointer' }
                                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                    key={i}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                      : null}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Shipping/Delivery</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    {deliveryList?.map((data: any, i: any) => (
                      <li key={i}>
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" name="delivery_type_id" value="" id={"delivery_type_id-" + i} onChange={(e: any) => { e.target.checked ? setFilter('delivery_type_id', data.id) : setFilter('delivery_type_id', null) }} checked={filterData?.delivery_type_id == data.id} />
                          <label className="form-check-label" htmlFor={"delivery_type_id-" + i}>
                            {data.type}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Drawer>



      <Drawer anchor="right" open={sortDrawer} onClose={() => { setSortDrawer(false) }}>
        <div className="drawer-body filter-drawer">
          <FaTimes className="close-btn" onClick={() => { setSortDrawer(false) }} />
          <div className="drawer-bar">
            Sort
          </div>
          <div className="filter sort">
            <ul>
              {sorts?.map((label: any, i: any) => (
                <li key={i} >
                  <div className="form-check" >
                    <input className="form-check-input" type="checkbox" name="sort_by" value="" id={"sort-filter-" + i} onChange={(e: any) => { e.target.checked ? setFilter('sort_by', (i + 1)) : setFilter('sort_by', null) }} checked={filterData?.sort_by == (i + 1)} />
                    <label className="form-check-label" htmlFor={"sort-filter-" + i}>
                      {label}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Drawer>

    </React.Fragment>
  );
}