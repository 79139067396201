import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';
import {
  BlankReq,
  globalSearchReq,
} from '../../interfaces/ApiReqRes'


export function useGlobalApi() {

  const callApi = useApiCall()

  const getSuggestions = (data: globalSearchReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.GLOBAL_SEARCH.GET_SUGGESTIONS, data, onSuccess, onError);
  }

  const search = (data: BlankReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.GLOBAL_SEARCH.SEARCH, data, onSuccess, onError);
  }


  return {
    getSuggestions: getSuggestions,
    search: search
    /* 
    callRegistration: registration,
    callEmailVerify: emailVerify,
    callGetUser: getUser,
    callLogout: logout,
    submitContact: submitContact,
    forgotPassword: forgotPassword,
    validToken: validToken,
    changePassword: changePassword,
    profileUpdate: profileUpdate,
    updateAvatar: updateAvatar */
  }
}