import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Header from "../../components/common/header";
import Footer from "../../components/common/footer";
import { Link } from "react-router-dom";
import ReadMore from "../../components/common/readMore";
import ListingMap from "../../components/listingMap";
import Filter from "../../components/filter";

import { Container, Row, Col } from 'react-bootstrap'
import { useCommonApi } from '../../_common/hooks/api/commonApiHook';
import { useCmsApi } from '../../_common/hooks/api/cmsApiHook';
import '../../style/listing.scss';
import '../../style/widget.scss';


export default function OccassionList() {
	const { type, slug } = useParams<any>();
	const commonApi = useCommonApi();
	const cmsApi = useCmsApi();

	const [typeData, setTypeData] = useState<any>(null);
	const [items, setItems] = useState<any>(null);
	const [view, setView] = useState<any>('list');

	const [occassionList, setOccassionList] = useState<any>(null);
	const [categoryList, setCategoryList] = useState<any>(null);
	const [deliveryList, setDeliveryList] = useState<any>(null);
	const [priceRanges, setPriceRanges] = useState<any>(null);
	const [sorts, setSorts] = useState<any>(null);
	const [filterData, setFilterData] = useState<any>(null);
	const [timer, setTimer] = useState<any>(false);
	const [noData, setNoData] = useState<any>(null);
	const history = useHistory();


	const getAllOccassionList = () => {
		cmsApi.getAllOccassionThemeList({}, (message: any, resp: any) => {
			setOccassionList(resp.data);
			console.log(resp.data)
		}, (message: any, resp: any) => { })
	}

	const getCategoryList = () => {
		cmsApi.getCategoryDetailsList({}, (message: any, resp: any) => {
			setCategoryList(resp.data)
		}, (message: any, resp: any) => { })
	}

	const getDeliveryList = () => {
		cmsApi.getDeliveryTypes({}, (message: any, resp: any) => {
			setDeliveryList(resp.data)
		}, (message: any, resp: any) => { })
	}

	const getPriceRangeAndSorts = () => {
		cmsApi.getPriceRangeAndSorts({}, (message: any, resp: any) => {
			setPriceRanges(resp.data.price)
			setSorts(resp.data.sort)
		}, (message: any, resp: any) => { })
	}


	const getItems = () => {
		let param: any = {};
		if (type == 'category') {
			param.category_slug = slug;
		} else if (type == 'themes') {
			param.theme_slug = slug;
		}

		commonApi.getProducts(param, (message: string, resp: any) => {
			setItems(resp.data.data);
		}, (message: string) => {

		});
	}

	const getResult = () => {
		setItems(null)
		let QS: any = new URLSearchParams((window as any).location.search);
		let filter = QS.get('filter') ? atob(QS.get('filter')) : null;
		if (filter) {
			setFilterData(JSON.parse(filter))
			cmsApi.filterProduct(JSON.parse(filter), (message: string, resp: any) => {
				setItems(resp.data);
			}, (message: string, resp: any) => {
				setItems([]);
			})
		} else {
			getItems()
		}
	}

	const setFilter = (key: any, value: any) => {
		let filters: any = {};
		if (filterData) {
			filters = JSON.parse(JSON.stringify(filterData))
		}
		/* let filters: any = {
			cat_id: 20,
			occassion_id: 20,
			delivery_type_id: 6,
			zip_code: 70003,
			price_range: 0,
			sort_by: 0
		} */
		if (value == 0 || value == null || value == '') {
			delete filters[key]
		} else {
			filters[key] = value;
		}


		setFilterData(filters);
		let _url = slug + '?filter=' + btoa(JSON.stringify(filters))
		if (Object.keys(filters).length == 0) {
			_url = slug
		}
		history.push(_url);
		setTimeout(() => { setTimer(true) }, 100)

	}

	useEffect(() => {
		getAllOccassionList();
		/* commonApi.getThemeCatInfo({ type: type === 'category' ? 'categories' : type, slug: slug }, (message: string, resp: any) => {
			setTypeData(resp.data);
		}, (message: string) => {
			console.error('no data error', message);
			setNoData(true)
		}) */
		/* getResult();
		getOccassionList(); */
		//getCategoryList();
		/* getDeliveryList();
		getPriceRangeAndSorts(); */
	}, []);

	/* useEffect(() => {
		if (filterData) {
			//getResult()
		}
	}, [filterData])
	useEffect(() => {
		if (timer) {
			getResult();
			setTimer(false)
		}
	}, [timer]) */







	return (
		<React.Fragment>
			<Header />
			<section className="listing">
				{typeData ?
					<div className="banner">
						<img src={typeData.img2_link} alt={typeData.slug} />
					</div>
					: null}

				<Container>
					<Row>
						{/* <Col sm={3}>
							<Filter setFilter={setFilter} filterData={filterData} priceRanges={priceRanges} categoryList={categoryList} occassionList={occassionList} deliveryList={deliveryList} sorts={sorts} />
						</Col> */}
						<Col sm={12}>
							{/* <div className="header mt-1">
								<h2>{typeData ? typeData.name : noData !== null && noData ?
									slug
									: null}</h2>


								{typeData ?
									<div>
										<button className={view === 'list' ? 'active' : ''} onClick={() => { setView('list') }}>List</button>
										<button className={view === 'map' ? 'active' : ''} onClick={() => { setView('map') }}>Map</button>
									</div>
									: null}
								


							</div>
							{typeData?.description ? <ReadMore className="desc" limit={230} text={typeData.description} /> : null} */}



							{occassionList ?
								<Row className="widget shop-by-category">
									{occassionList?.map((occassion: any, i: any) => (
										<Col sm={3} key={i}>
											<Link to={'listing/themes/' + occassion.slug} className="thumb-block">
												<div className="zoom-img">
												<img src={occassion.img_link} alt={occassion.name?occassion.name: ''} />
												</div>
												{occassion.name ? <p className="title-1">{occassion.name}</p> : null}
												{occassion.meta_text ? <p className="title-2">{occassion.meta_text}</p> : null}
											</Link>
										</Col>
									))}
									{occassionList?.length == 0 ? <p className="no-product" >We are currently adding new listings. Come back again soon!</p> : null}
								</Row>
								:
								null
							}

							{noData !== null && noData?
							<h2 className="text-center">No Data Found!</h2>
							:null }


						</Col>
					</Row>
				</Container>

			</section>
			<Footer />
		</React.Fragment>
	);
}