import React, { useEffect, useState } from "react";

import { useCmsApi } from '../../_common/hooks/api/cmsApiHook';
import { useProductApi } from '../../_common/hooks/api/productApiHook';
import { useToaster } from '../../_common/hooks/common/appTosterHook';
import Price from "../../components/common/price";
import Rating from "../../components/common/rating";
import { IoMdHeart, IoMdHeartEmpty } from 'react-icons/io';
import { BiPlus } from 'react-icons/bi';
import { Link } from "react-router-dom";
import PinInspoModal from "../modals/pinInspo";
import { Container, Row, Button, Col } from 'react-bootstrap';
import { useUserSelector } from '../../_common/hooks/selectors/userSelector';
import { useCartSelector } from '../../_common/hooks/selectors/cartSelector';
import { useCartApi } from '../../_common/hooks/api/cartApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';

import '../../style/item.scss';
interface incomingProps {
  item?: any;
  type?: any;
  theme?: any;
  inspo?: any;
  boards?: any;
  onUpdate?: any;
  closeOfferDrawer?: any;
  offer?: boolean;
}

export default function Item({ item, type, theme, inspo, boards, onUpdate, offer, closeOfferDrawer }: incomingProps) {
  const cmsApi = useCmsApi();
  const user: any = useUserSelector();
  const cart: any = useCartSelector();
  const cartApi = useCartApi();
  const productApi = useProductApi();
  const [_item, setItem] = useState<any>(item);
  const [inspoModal, setInspoModal] = useState<boolean>(false);
  const closeInspoModal = () => { setInspoModal(false); onUpdate('pins') };

  const { showLoader, hideLoader } = useAppLoader();

  const toast = useToaster();


  const getProductDetails = () => {
    productApi.getProductListDetails({ id: _item.id }, (message: string, resp: any) => {
      setItem(resp.product);
    }, (message: string) => {

    })
  }



  const changeLovedStatus = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    let params: any = { id: _item.id };
    switch (type) {
      case 'product':
        params = { 'product_id': _item.id };
        break;
      default:
    }
    productApi.changeLoved(params, (message: string, resp: any) => {
      let msg: any = 'Status changed ' + (_item.loved ? `${type} marked as not loved` : `${type} marked as loved`)
      toast.success(msg);
      getProductDetails();
    }, (message: string) => {

    })
  }

  const addToInspo = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setInspoModal(true);
  }

  const isInCart = (item: any) => {
    let res = false;
    cart?.cart?.items?.map((_item: any) => {
      if (_item.product.id === item.id) { res = true }
    })
    return res;
  }


  const addToCart = (e: any) => {
    if (user) {
      showLoader()
      let deliveryOption: any = (document.querySelector('[name="buy-type"]:checked') as any)?.value;



      let params = {
        product_id: item.id,
        address_id: item.address_id,
        selectedVariation: [],
        answers: [],
        selectedDeliveryType: item.delivery_type_id,
        quantity: 1
      };



      cartApi.addToCart(params, (message: any, resp: any) => {
        hideLoader();
        sessionStorage.setItem('deliveryOption', deliveryOption);
        toast.success(message);
        closeOfferDrawer();
        cartApi.myCart({}, (message: any, resp: any) => {
        }, (message: any, resp: any) => {
          console.error(message);
        })
      }, (message: any, resp: any) => {
        hideLoader();
        console.error(message)
      })


    } else {
      //setLoginModal(true);
    }

  }

  const getProductPrice = () => {



    if (_item.service_pricing_details) {
      switch (_item.type) {
        case 4:
          if (_item.service_pricing_details.per_day_fee != null) {
            return _item.service_pricing_details.per_day_fee;
          }

          if (_item.service_pricing_details.per_week_fee != null) {
            return _item.service_pricing_details.per_week_fee;
          }

          break;
        default:
          return _item.price;
      }
    } else {
      return _item.price;
    }



  }



  useEffect(() => {
    if (item) {
      (window as any).$clamp(document.getElementById("item-title" + _item.slug), { clamp: 2 });
    }
    getProductDetails();
    setItem(item);
    console.log('item=', item);

  }, [item])




  return (
    <React.Fragment>
      {inspo ?
        <PinInspoModal
          show={inspoModal}
          onHide={closeInspoModal}
          boards={boards}
          pined={_item.pin_boards}
          id={_item.id}
          update={getProductDetails}
          boardUpdate={onUpdate}
        />
        : null}

      <div className="item">
        <Link to={"/product/" + _item.slug} className="thumb">
          <img src={_item.main_img_link} alt="" style={{ backgroundImage: `url(${_item.main_img_link})` }} />

          <div className="loved" onClick={changeLovedStatus}>
            {_item.loved ? <IoMdHeart /> : <IoMdHeartEmpty />}
          </div>
          {inspo ?
            <div className="inspo" onClick={addToInspo}><BiPlus /></div>
            : null}
        </Link>

        {/* <h3 className="single-line" title={_item.name}>{_item.name}</h3> */}
        <h3 title={_item.name} id={"item-title" + _item.slug}>{_item.name}</h3>
        {theme != 'from' ?
          <React.Fragment>
            {_item.avg_rating ? <div className="rating">
              <Rating rating={_item.avg_rating} readOnly={true} />
            </div> : null}

          </React.Fragment>
          : null}
        {theme == 'from' ? null : null} {/* <Link to={'/shop/' + _item.vendor_details.slug} className="from">By {_item.vendor_details.name}</Link> */}


        <p className="price">{_item.type != 1 ? 'Starting at ' : ''}

          <Price amount={getProductPrice()} price_range={_item?.price_range} />
          {

            (_item.type == 4 && _item.service_pricing_details) ?
              (_item.service_pricing_details.per_day_fee != null) ? '/per day' :
                (_item.service_pricing_details.per_week_fee != null) ? '/per week' : ''
              : null}

          {/* <Price amount={_item.price} /> */}

        </p>

        {offer ?
          <div className="addtocart"><Button variant="primary" size="sm" onClick={addToCart}>Add to Cart</Button><Button variant="info" size="sm" onClick={closeOfferDrawer}>No Thanks</Button></div>
          : null}
      </div>
    </React.Fragment>
  );
}
