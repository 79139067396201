import { useSelector } from 'react-redux'
import { User } from '../../interfaces/models/user'
import { Cart } from '../../interfaces/models/cart'
import { StateExtended } from '../../interfaces/StateExtended'


export function useCartSelector() {
    const cart: any | null = useSelector((state: StateExtended) => state.cart)
    return cart
}

export function useUserTokenSelector() {
    const token: string | null = useSelector((state: StateExtended) => state.user.token)
    return token
}

export function useUserAuthSelector() {
    const isAuth: boolean = useSelector((state: StateExtended) => state.user.isAuthenticated)
    return isAuth
}