import React, { useEffect, useState } from "react";
import { URLS } from '../../_config';
import { Link } from "react-router-dom"; //useHistory, useParams
import { useCmsApi } from '../../_common/hooks/api/cmsApiHook';
import { FaFacebookF, FaPinterestP, FaTwitter } from 'react-icons/fa';
import { ImInstagram, ImYoutube2 } from 'react-icons/im';
import { SiTiktok } from 'react-icons/si';
import { Container, Row, Col } from 'react-bootstrap'
import { useUserSelector } from '../../_common/hooks/selectors/userSelector';
import EmailSubscribe from './emailSubscribe';
import '../../style/footer.scss';




export default function Footer() {

  const user: any = useUserSelector();
  const cmsApi = useCmsApi();
  const [socialLinks, setSocialLinks] = useState<any>(null);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    cmsApi.settingsSearch({ type: 'social_links' }, (message: string, resp: any) => {
      setSocialLinks(resp.data)
    }, (message: string) => { // login error..

    });
  }, [])


  return (
    <footer>
      <section className="footer-links">
        <Container>
          <Row>
            <Col md={4} className="f-links">
              <h2>Support</h2>
              <div className="links">
                <Link to={URLS.FAQS}>FAQs</Link>
                <a href="https://help.festively.com/" target="_blank" rel="noreferrer">Help</a>
                {user ? <Link to={URLS.USER.MY_ACCOUNT}>My Account</Link> : <Link to={URLS.USER.LOGIN}>My Account</Link>}
                <Link to={URLS.CONTACT}>Contact Us</Link>
              </div>
            </Col>
            <Col md={4} className="f-links">
              <h2>Company</h2>
              <div className="links">
                <Link to={URLS.ABOUT}>About Us</Link>
                <a href="https://blog.festively.com/" target="_blank" rel="noreferrer">Blog</a>
                <Link to={URLS.TERMS}>Terms</Link>
                <Link to={URLS.PRIVACY}>Privacy</Link>
              </div>
            </Col>
            <Col md={4} className="f-links">
              <h2>Festively for Business</h2>
              <div className="links">
                <a href={process.env.REACT_APP_API_DOMAIN + '/'} target="_blank" rel="noreferrer">Feature your business</a>
                {user?.vendor_id ?
                  <a href={process.env.REACT_APP_API_DOMAIN + '/home'} target="_blank" rel="noreferrer">Switch to business account</a>
                  :
                  <a href={process.env.REACT_APP_API_DOMAIN + '/login'} target="_blank" rel="noreferrer">Switch to business account</a>
                }

                <a href="https://www.community.festively.com" target="_blank" rel="noreferrer">Join the Community</a>
                {/* <Link to="/">Sign up to recieve offers</Link> */}
              </div>
              {/* <EmailSubscribe theme="dark" /> */}
            </Col>
          </Row>
        </Container>
      </section>
      <section className="footer-company">
        <h3>@sofestively</h3>
        <div className="social-links">
          <a href={socialLinks?.insta_link} target="_blank" rel="noreferrer"><ImInstagram /></a>
          <a href={socialLinks?.ticktock_link} target="_blank" rel="noreferrer"><SiTiktok /></a>
          <a href={socialLinks?.facebook_link} target="_blank" rel="noreferrer"><FaFacebookF /></a>
          <a href={socialLinks?.pinterest_link} target="_blank" rel="noreferrer"><FaPinterestP /></a>
          <a href={socialLinks?.youtube_link} target="_blank" rel="noreferrer"><ImYoutube2 /></a>
          <a href={socialLinks?.twitter_link} target="_blank" rel="noreferrer"><FaTwitter /></a>
        </div>
        <hr />
        <p className="copyright">© {currentYear}, FESTIVELY. All rights reserved. Based in Los Angeles, CA & Beyond</p>
      </section>



    </footer>
  );
}
