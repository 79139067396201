import { call } from 'redux-saga/effects';
import { API_URL } from '../_config'
import { CallApi } from './api/callApi';



export function* sendQuoteRequest(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.QUOTE.SEND_QUOTE_REQUEST, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* getQuote(action: any): any {
    try {
        const data = action.payload;
        
        const resp = yield call(CallApi.GET, API_URL.QUOTE.GET_QUOTE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getQuoteList(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.QUOTE.GET_QUOTE_LIST, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}