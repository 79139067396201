import React from "react";
import Rating from '@material-ui/lab/Rating';
import { withStyles } from '@material-ui/core/styles';
interface incomingProps {
  rating?: any;
  readOnly: any;
  size?: any;
  onChange?: any;
}
export default function MyRating({ rating, readOnly, size, onChange }: incomingProps) {

  const StyledRating = withStyles({
    iconFilled: {
      color: '#e7373f',
    },
    iconHover: {
      color: '#e7373f',
    }
  })(Rating);

  return (
    <StyledRating
      name="customized-color"
      defaultValue={rating}
      getLabelText={(value: any) => `${value} Heart${value !== 1 ? 's' : ''}`}
      precision={0.5}
      readOnly={readOnly}
      onChange={onChange ? onChange : (event: any, newValue: any) => { }}
      size={size ? size : 'small'}
    />
  );
}
