import React, { useEffect, useState } from "react";

interface incomingProps {
	items: any
}

export default function ListingMap({ items }: incomingProps) {

	const [position, setPosition] = useState<any>(null);

	const addScript = (path: any, id: any) => {
		return new Promise((resolve, reject) => {
			if (document.getElementById(id)) {
				resolve(true);
			} else {
				let script = document.createElement('script');
				script.onload = function () {
					resolve(true);
				};
				script.src = path;
				script.id = id;
				document.head.appendChild(script);
			}

		});
	}

	const checkGoogle = () => {
		return new Promise((resolve, reject) => {
			if ((window as any).google !== undefined) {
				resolve(true);
			} else {
				let timer = setInterval(() => {
					if ((window as any).google !== undefined) {
						clearInterval(timer);
						resolve(true);
					}
				}, 100)
			}
		})
	}

	const load_Google_Map = () => {
		
		return new Promise((resolve, reject) => {
			addScript('https://maps.googleapis.com/maps/api/js?key='+process.env.REACT_APP_GOOGLE_KEY+'&callback=initMap&&libraries=places,geometry,drawing&v=weekly', 'g-map').then(() => {
				checkGoogle().then(() => {
					addScript('/assets/js/oms.min.js', 'oms').then(() => { resolve(true) })
				})
			})
		});
	}

	const addMarkers = () => {
		load_Google_Map().then(() => {
			let google: any = (window as any).google;
			let mapEl = document.getElementById("list-map");
			var mypos = { lat: 34.0522, lng: -118.2437 }
			let map = new google.maps.Map(mapEl, {
				center: mypos,
				zoom: 7,
			});
			let bounds:any = new google.maps.LatLngBounds();

			

			setTimeout(() => {
				let iw = new google.maps.InfoWindow();
				let oms = new (window as any).OverlappingMarkerSpiderfier(map, {
					markersWontMove: true,
					markersWontHide: true,
					basicFormatEvents: true
				});

				let markers: any = [];
				let markersPositions: any = [];
				let addFilteredMarkers = (M: any, latlng: any) => {
					if (latlng.lat && latlng.lng) {
						let found = false;
						markersPositions.forEach((_latlng: any) => {
							if (_latlng == latlng) {
								found = true;
							}
						});
						if (!found) {
							markersPositions.push(latlng)
							markers.push(M);
						} else {
						}
					} else {
					}
				}


				items?.forEach((item: any, i: any) => {
					if (item.lat != null && item.lng != null) {
						let _lat = parseFloat(item.lat);
						let _lng = parseFloat(item.lng);

						//map.setCenter({ lat: _lat, lng: _lng });
						var markerData = { lat: _lat, lng: _lng, text: item.name }
						var marker = new google.maps.Marker({ position: markerData });  // markerData works here as a LatLngLiteral
						addFilteredMarkers(marker, { lat: _lat, lng: _lng });
						console.log('------------------->', item)

						if (item.vendor_details) {
							item.vendor_slug = item.vendor_details.slug
							item.vendor_name = item.vendor_details.name
							item.main_img_link=item.main_img_link
							item.slug_link=window.location.origin+'/product/'+item.slug
						}else{
							item.main_img_link=item.user.profile_pic_link
							item.vendor_slug = item.slug
							item.vendor_name = item.name
							item.slug_link=window.location.origin+'/shop/'+item.slug
						}
						
						google.maps.event.addListener(marker, 'spider_click', function (e: any) {  // 'spider_click', not plain 'click'
							iw.setContent(`
                              <div style="display:flex;">
                                  <div style="width:40%">
                                      <img style="width:100%" src="${item.main_img_link}" />
                                  </div>
                                  <div style="padding-left:12px" />
                                      <h1 style="font-size:22px; color:#232323;"><a style="color:inherit;" href="${item.slug_link}">${markerData.text}</a></h1>
                                      <p>Rating: ${item.avg_rating}/5</p>
                                      <hr style="height:1px; border:0 margin:10px 0; background-color:#dadada"/>
                                      <h4 style="font-size:18px; color:#232323;">Vendor: <a style="color:inherit;" href="${window.location.origin}/shop/${item.vendor_slug}">${item.vendor_name}</a></h4>
                                  </div>
                              </div>
                          `);
							iw.open(map, marker);
						});
						oms.addMarker(marker);
					} else {
						console.log('Lat lng null', item)
					}

				});


				markers.map((M:any) => {
					bounds.extend(M.getPosition());
					return M;
				});
				// var me = new google.maps.Marker({ position: mypos });
				// bounds.extend(me.getPosition());


				// if(markers.length > 0){
					map.fitBounds(bounds);
				// }

				/* if ((window as any).MarkerClusterer) {
					new (window as any).MarkerClusterer(map, markers, {
						imagePath:
							"https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
					});
				} */


				/* oms.addListener('format', function (marker:any, status:any) {
					var iconURL = status == (window as any).OverlappingMarkerSpiderfier.markerStatus.SPIDERFIED ? 'marker-highlight.svg' :
						status == (window as any).OverlappingMarkerSpiderfier.markerStatus.SPIDERFIABLE ? 'marker-plus.svg' :
							status == (window as any).OverlappingMarkerSpiderfier.markerStatus.UNSPIDERFIABLE ? 'marker.svg' :
								null;
					marker.setIcon({
						url: iconURL,
						scaledSize: new google.maps.Size(23, 32)  // makes SVG icons work in IE
					});
				}); */

			}, 1000)



		})
	}
	const getCurrentPosition = () => {
		return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition((position: any) => {
				if (position.coords) {
					setPosition(position.coords);
				}
				resolve(true);
			}, (err: any) => {
				resolve(true);
			}, {
				enableHighAccuracy: true,
				timeout: 5000,
				maximumAge: 0
			})
		})
		
	}

	useEffect(() => {
		getCurrentPosition()
	}, []);
	useEffect(() => {
		addMarkers();
	}, [items]);



	useEffect(() => {
		if(position){
			addMarkers();
		}
	}, [position]);
	return (
		<React.Fragment>
			<div id="list-map"></div>
		</React.Fragment>

	);
}
