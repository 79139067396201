export function usNumber(e:any) {
    let n = e.target.value.replace(/\D/g, '');
    var x = n.match(/(\d{3})(\d*)/);
    var y = n.match(/(\d{3})(\d{3})(\d*)/);
    var z = n.match(/(\d{3})(\d{3})(\d{4})/);
    if(z){
        e.target.value = '(' + z[1] + ') ' + z[2] + '-' + z[3];
    } else if(y){
        e.target.value = '(' + y[1] + ') ' + y[2] + ((y[3] != '')?('-' + y[3]):'');
    } else if (x && x[2] != ''){
        e.target.value = '(' + x[1] + ') ' + x[2] ;
    }
    
}

export function creditCard(e:any) {
    let n = e.target.value.replace(/\D/g, '');
    var a = n.match(/(\d{4})(\d*)/);
    var b = n.match(/(\d{4})(\d{4})(\d*)/);
    var c = n.match(/(\d{4})(\d{4})(\d{4})(\d*)/);
    var d = n.match(/(\d{4})(\d{4})(\d{4})(\d{4})(\d*)/);
    if(d){
        e.target.value =  d[1] + ' ' + d[2] + ' ' + d[3]+ ' ' + d[4];
    } else if(c){
        e.target.value = c[1] + ' ' + c[2]+ ' ' + c[3] + ((c[4] != '')?(' ' + c[4]):'');
    } else if(b){
        e.target.value = b[1] + ' ' + b[2] + ((b[3] != '')?(' ' + b[3]):'');
    } else if (a && a[2] != ''){
        e.target.value =  a[1] + ' ' + a[2] ;
    }
    
}
export function creditCardExp(e:any) {
    let n = e.target.value.replace(/\D/g, '');
    var x = n.match(/(\d{2})(\d*)/);
    var y = n.match(/(\d{2})(\d{2})(\d*)/);
    if(y){
        e.target.value = y[1]>12?12:y[1] + ' / ' + y[2] 
    } else if (x && x[2] != ''){
        e.target.value =  x[1]>12?12:x[1] + ' / ' + x[2] ;
    }
}

export function CheckNumber(e:any) {
    let n = e.target.value.replace(/[^\d.]/g, '');
    let min = e.target.getAttribute('min');
    if(min && n < parseFloat(min)) {
        n = parseFloat(min)
    }
    let max = e.target.getAttribute('max');
    if(max && n > parseFloat(max)) {
        n = parseFloat(max)
    }
    e.target.value = n;
}


export function NumberMinMax(e:any, min:any, max:any) {
    let n = e.target.value.replace(/\D/g, '');
    n = parseFloat(n);
    if(n < min) {n = min}
    if(n > max) {n = max}
    e.target.value = n;
}
