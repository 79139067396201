import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE } from '../_config'
import { CallApi } from './api/callApi';


export function* login(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.LOGIN, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            localStorage.setItem(STORAGE, resp.data.data.token);
            yield put({
                type: ACTIONS.USER.LOGIN,
                payload: {
                    user: resp.data.data.user_details,
                    token: resp.data.data.token,
                }
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* register(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.REGISTER, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            /* localStorage.setItem(STORAGE, resp.data.data.token);
            yield put({
                type: ACTIONS.USER.LOGIN,
                payload: {
                    user: resp.data.data.user_details,
                    token: resp.data.data.token,
                }
            }) */
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}






export function* logout(action: any): any {
    try {
        localStorage.removeItem(STORAGE)
        yield put({
            type: ACTIONS.USER.LOGOUT,
        });
        action && action.callbackSuccess && action.callbackSuccess({});
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* editProfile(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.EDIT_PROFILE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* cancelOrder(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.CANCEL_ORDER, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* aboutMe(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.ME, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            console.log(resp.data.user_details)
            /*  yield put({
                 type: ACTIONS.USER.ME,
                 payload: {
                     user: resp.data.user_details
                 }
             }) */
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* myOrders(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.MY_ORDERS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* orderDetails(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.USER.ORDER_DETAILS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}





export function* resetPassword(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.RESET_PASSWORD, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* forgotPassword(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.FORGOT_PASSWORD, data, false);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* getOtp(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.OTP, data, false);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

/*
export function* registration(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.REGISTRATION, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            yield put({
                type: ACTIONS.USER.LOGIN,
                payload: {
                    user: resp.data.data.details,
                    token: resp.data.data.token,
                }
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}



export function* getUserByToken(action: any): any {
    console.log('getUserByToken => ---------------------')
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.USER.GET_USER, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
            console.log('===========>', resp.data.data);
            yield put({
                type: ACTIONS.USER.ME,
                payload: resp.data.data
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
} */
