import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/common/header";
import Footer from "../../components/common/footer";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import '../../style/gallery.scss';
import { images } from "./images";
import { Button, Modal } from "react-bootstrap";

export default function GalleryVendor() {
    const [index, setIndex] = useState(-1);

    const currentImage = images[index];
    const nextIndex = (index + 1) % images.length;
    const nextImage = images[nextIndex] || currentImage;
    const prevIndex = (index + images.length - 1) % images.length;
    const prevImage = images[prevIndex] || currentImage;
    const [loginModal, setLoginModal] = useState(false);

    const handleClick = (index: number) => {
        setIndex(index);
        setLoginModal(true);
    };
    const handleClose = () => {
        setIndex(-1);
        setLoginModal(false);
    };
    const hideModal = () => {
        setLoginModal(false);
    };
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    return (
        <React.Fragment>
            <Header />
            <Gallery
                images={images}
                onClick={handleClick}
                enableImageSelection={false}
            />
            <Modal show={loginModal} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" className="search-modal" >
                <Modal.Body>
                    {currentImage?.type == 'video' ?
                        <div className="imaged">
                            <iframe src={currentImage?.original} width="100%" height="500"></iframe>
                        </div>
                        :
                        <div className="imaged">
                            <img src={currentImage?.original} alt="" />
                        </div>
                    }
                </Modal.Body>
            </Modal>
            {/* {!!currentImage && (
                /* @ts-ignore *
                <Lightbox
                    mainSrc={currentImage.original}
                    imageTitle={currentImage.caption}
                    mainSrcThumbnail={currentImage.src}
                    nextSrc={nextImage.original}
                    nextSrcThumbnail={nextImage.src}
                    prevSrc={prevImage.original}
                    prevSrcThumbnail={prevImage.src}
                    onCloseRequest={handleClose}
                    onMovePrevRequest={handleMovePrev}
                    onMoveNextRequest={handleMoveNext}
                />
            )} */}
            <Footer />
        </React.Fragment >
    );
}