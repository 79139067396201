import React, { useEffect, useState } from "react";
import Header from "../../components/common/header";
import Footer from "../../components/common/footer";
import Price from "../../components/common/price";
import CheckoutThanksModal from "../../components/modals/checkoutThanks";
import CartItems from "../../components/cart/cartItems";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useUserSelector } from "../../_common/hooks/selectors/userSelector";
import { useCartSelector } from "../../_common/hooks/selectors/cartSelector";
import { useCartApi } from "../../_common/hooks/api/cartApiHook";
import { useHistory, useParams } from "react-router";
import { useAppLoader } from "../../_common/hooks/common/appLoaderHook";
import { useToaster } from "../../_common/hooks/common/appTosterHook";
import { useForm } from "react-hook-form";
import { CheckNumber, creditCardExp, creditCard } from "../../functions";
import "../../style/checkout.scss";
import '../../style/cart.scss';
import moment from "moment";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";

export default function RemainingAmount() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const user: any = useUserSelector();
  let cart: any = useCartSelector();
  const toast = useToaster();
  const userApi = useUserApi();

  const cartApi = useCartApi();
  const { showLoader, hideLoader } = useAppLoader();
  const [booking, setBooking] = useState<any>(null);
  const [booking_cart, setBookingCart] = useState<any>(null);
  const [thanksModal, setThanksModal] = useState(false);

  const history = useHistory();
  const { token } = useParams<any>();

  const closeThanksModal = () => {
    setThanksModal(false);
    if (user) {
      history.push('/my-account');
    } else {
      history.push('/');
    }
  }

  const onSubmit = (data: any) => {
    showLoader();
    let id = atob(token);
    cartApi.checkout(
      {
        payment_type: 'remaining',
        booking_id: id,
        card_no: data.cardNumber.split(" ").join(""),
        exp_month: data.exp.split("/")[0],
        exp_year: data.exp.split("/")[1],
        cvv: data.scode,
      },
      (message: string, resp: any) => {
        setThanksModal(true);
        console.log("checkout=>", resp);
        hideLoader();
      },
      (message: string) => {
        // login error..
        //console.error(message);
        toast.error(message);
        hideLoader();
      }
    );

    return false;
  };

  useEffect(() => {
    console.log(token);

    cartApi.getRemainingDetails({ token: token }, (message: string, resp: any) => {
      console.log("checkout=>", resp.booking_cart[0].product);
      setBooking(resp);
      setBookingCart(resp.booking_cart);
    },
      (message: string) => {
        // login error..
        //console.error(message);
        toast.error(message);
        hideLoader();
      })
  }, [token]);

  return (
    <React.Fragment>
      <Header />
      <CheckoutThanksModal
        show={thanksModal}
        onHide={closeThanksModal}
      />
      <section className="checkout">
        <form onSubmit={handleSubmit(onSubmit)} id="pay-form">
          <Container>
            <Row>
              <Col lg={8} md={7} className="payment">
                {/* <ProductImages images={product.product_images.map((image: any) => { return image.link })} /> */}

                <div className="form">
                  <div className="form-group position-relative mb-4">
                    <input
                      type="text"
                      defaultValue={user?.name}
                      className={
                        "form-control f_line" +
                        (errors?.name ? " is-invalid" : "")
                      }
                      placeholder="Cardholder's name"
                      {...register("name", { required: true })}
                    />
                    {errors?.name ? (
                      <div className="invalid-feedback">
                        {errors.name.type === "required" ? "required" : null}
                      </div>
                    ) : null}
                  </div>
                  {!user ? (
                    <>
                      <div className="form-group position-relative mb-4">
                        <input
                          type="text"
                          defaultValue={user?.name}
                          className={
                            "form-control f_line" +
                            (errors?.name ? " is-invalid" : "")
                          }
                          placeholder="Email Address"
                          {...register("email", { required: true })}
                        />
                        {errors?.email ? (
                          <div className="invalid-feedback">
                            {errors.email.type === "required"
                              ? "required"
                              : null}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group position-relative mb-4">
                        <input
                          type="text"
                          defaultValue={user?.name}
                          className={
                            "form-control f_line" +
                            (errors?.name ? " is-invalid" : "")
                          }
                          placeholder="Phone Number"
                          {...register("phoneNumber", { required: true })}
                        />
                        {errors?.phoneNumber ? (
                          <div className="invalid-feedback">
                            {errors.phoneNumber.type === "required"
                              ? "required"
                              : null}
                          </div>
                        ) : null}
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <Row>
                    <Col md={12}>
                      <div className="form-group position-relative mb-4">
                        <input
                          type="tel"
                          maxLength={19}
                          className={
                            "form-control f_line" +
                            (errors?.cardNumber ? " is-invalid" : "")
                          }
                          placeholder="Card Number"
                          {...register("cardNumber", { required: true })}
                          onChange={creditCard}
                        />
                        {errors?.cardNumber ? (
                          <div className="invalid-feedback">
                            {errors.cardNumber.type === "required"
                              ? "required"
                              : null}
                            {errors.cardNumber.type === "pattern"
                              ? "invalid"
                              : null}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="form-group position-relative mb-4">
                        <input
                          type="tel"
                          maxLength={7}
                          className={
                            "form-control f_line" +
                            (errors?.expMonth ? " is-invalid" : "")
                          }
                          placeholder="MM/YY"
                          {...register("exp", { required: true })}
                          onChange={(e: any) => {
                            console.log(e.target.value);
                            creditCardExp(e);
                          }}
                        />
                        {errors?.expMonth ? (
                          <div className="invalid-feedback">
                            {errors.expMonth.type === "required"
                              ? "required"
                              : null}
                            {errors.expMonth.type === "pattern"
                              ? "invalid"
                              : null}
                          </div>
                        ) : null}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="form-group position-relative mb-4">
                        <input
                          type="text"
                          pattern="\d*"
                          maxLength={4}
                          className={
                            "form-control f_line" +
                            (errors?.scode ? " is-invalid" : "")
                          }
                          placeholder="Security Code"
                          {...register("scode", { required: true })}
                          autoComplete="off"
                          onChange={CheckNumber}
                        />
                        {errors?.scode ? (
                          <div className="invalid-feedback">
                            {errors.scode.type === "required"
                              ? "required"
                              : null}
                            {errors.scode.type === "pattern" ? "invalid" : null}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={4} md={5} className="cart">
                <div className="cart-items">
                  <h3>Order Id:- {booking?.id} </h3>
                  {booking_cart?.map((item: any, i: any) => (
                    <Row className="cart-item small">
                      <Col md={4} className="pic">
                        <img src={item?.product?.list_img_link} alt="" />
                      </Col>
                      <Col md={8} className="cart-info">
                        {/* <Price amount={booking?.remaining_amount} /> */}

                        <h2>{item?.product?.name}</h2>
                        <p>Made by: {item?.product?.vendor_details.name}</p>
                        <p>Remaining Price:
                          <Price amount={booking?.remaining_amount} />
                        </p>
                      </Col>
                    </Row>
                  ))}
                </div>
                <React.Fragment>
                  <div className="bill">
                    <div className="details-row">
                      <label>Total Remaining Amount</label>
                      <Price amount={booking?.remaining_amount} />
                    </div>

                    <div className="form-check">
                      <input type="checkbox" id="accept-policy" className={"form-check-input" + (errors?.tc ? ' is-invalid' : '')} {...register("tc", { required: true })} />
                      <label title="" htmlFor="accept-policy" className="form-check-label">By clicking Pay Remaining Amount, you agree to Festively and the Creator’s Terms of Service and Privacy Policy</label>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center">
                    <Button variant="info" size="lg" type="submit">Pay Remaining Amount</Button>
                  </div>
                </React.Fragment>
              </Col>
            </Row>
          </Container>
        </form>
      </section>
      <Footer />
    </React.Fragment>
  );
}
