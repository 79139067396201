import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';
import {
    BlankReq,
    addressReq,
    setDefaultReq,
    idReq
} from '../../interfaces/ApiReqRes'


export function useAddressApi() {

    const callApi = useApiCall()

    const addUpdateAddress = (data: addressReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.ADDRESS.ADD_UPDATE_ADDRESS, data, onSuccess, onError);
    }

    const getAddresses = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.ADDRESS.GET_ALL_ADDRESSES, data, onSuccess, onError);
    }

    const setAsDefault = (data: setDefaultReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.ADDRESS.SET_DEFAULT_ADDRESS, data, onSuccess, onError);
    }

    const addUpdateAddressGuest = (data: addressReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.ADDRESS.ADD_UPDATE_ADDRESS_GUEST, data, onSuccess, onError);
    }

    const getAddressesGuest = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.ADDRESS.GET_ALL_ADDRESSES_GUEST, data, onSuccess, onError);
    }

    const setAsDefaultGuest = (data: setDefaultReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.ADDRESS.SET_DEFAULT_ADDRESS_GUEST, data, onSuccess, onError);
    }
    
    const getAddressDetails = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.ADDRESS.GET_ADDRESS_DETAILS, data, onSuccess, onError);
    }
    const deleteAddress = (data: idReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.ADDRESS.DELETE_ADDRESS, data, onSuccess, onError);
    }



    return {
        addUpdateAddress: addUpdateAddress,
        getAddresses: getAddresses,
        setAsDefault: setAsDefault,
        getAddressDetails: getAddressDetails,
        deleteAddress: deleteAddress,
        addUpdateAddressGuest:addUpdateAddressGuest,
        getAddressesGuest:getAddressesGuest,
        setAsDefaultGuest:setAsDefaultGuest
    }
}