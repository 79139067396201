import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';
import {
    ContentReq,
    DetailsReq,
    BlankReq,
    locationReq,
    SearchReq,
    TestimonialsReq
} from '../../interfaces/ApiReqRes'


export function useCmsApi() {

    const callApi = useApiCall()

    const getBannerData = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CMS.BANNER_DATA, data, onSuccess, onError);
    }
    const cmsSearch = (data: SearchReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CMS.SEARCH, data, onSuccess, onError);
    }
    const settingsSearch = (data: SearchReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CMS.SETTINGS, data, onSuccess, onError);
    }
    const getBlogData = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CMS.BLOG_DATA, data, onSuccess, onError);
    }
    const getTestimonials = (data: TestimonialsReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CMS.TESTIMONIALS, data, onSuccess, onError);
    }

    const getOccassionData = (data: SearchReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CMS.OCCASSION_DATA, data, onSuccess, onError);
    }
    const getMakersData = (data: locationReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CMS.MAKERS_DATA, data, onSuccess, onError);
    }

    const getOccassionList = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CMS.OCCASSION_LIST, data, onSuccess, onError);
    }
    const getAllOccassionList = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CMS.ALL_OCCASSION_LIST, data, onSuccess, onError);
    }
    const getAllOccassionThemeList = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CMS.ALL_OCCASSION_THEME_LIST, data, onSuccess, onError);
    }
    const getTopHeaderText = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CMS.TOP_HEADER, data, onSuccess, onError);
    }



    const getCategoryList = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CMS.CATEGORY_LIST, data, onSuccess, onError);
    }
    const getCategoryDetailsList = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CMS.CATEGORY_DETAILS_LIST, data, onSuccess, onError);
    }


    const getDeliveryTypes = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CMS.DELIVERY_LIST, data, onSuccess, onError);
    }

    const getPriceRangeAndSorts = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CMS.PRICE_RANGE_AND_SORTS, data, onSuccess, onError);
    }
    const filterProduct = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CMS.FILTER, data, onSuccess, onError);
    }
    
    const emailSubscribe = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CMS.EMAIL_SUBSCRIBE, data, onSuccess, onError);
    }

    const getVendorList = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CMS.VENDOR_LIST, data, onSuccess, onError);
    }


    return {
        getBannerData: getBannerData,
        cmsSearch: cmsSearch,
        getBlogData: getBlogData,
        settingsSearch: settingsSearch,
        getTestimonials: getTestimonials,
        getOccassionData: getOccassionData,
        getMakersData: getMakersData,
        getOccassionList: getOccassionList,
        getAllOccassionList: getAllOccassionList,
        getAllOccassionThemeList: getAllOccassionThemeList,
        getCategoryList: getCategoryList,
        getCategoryDetailsList: getCategoryDetailsList,
        getDeliveryTypes: getDeliveryTypes,
        getPriceRangeAndSorts: getPriceRangeAndSorts,
        filterProduct: filterProduct,
        emailSubscribe: emailSubscribe,
        getTopHeaderText:getTopHeaderText,
        getVendorList:getVendorList
    }
}