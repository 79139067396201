import { call } from 'redux-saga/effects';
import { API_URL } from '../_config'
import { CallApi } from './api/callApi';

export function* addUpdateAddress(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.ADDRESS.ADD_UPDATE_ADDRESS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* getAddresses(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.ADDRESS.GET_ALL_ADDRESSES, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* setAsDefault(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.ADDRESS.SET_DEFAULT_ADDRESS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* getAddressDetails(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.ADDRESS.GET_ADDRESS_DETAILS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* deleteAddress(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.ADDRESS.DELETE_ADDRESS, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* addUpdateAddressGuest(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.ADDRESS.ADD_UPDATE_ADDRESS_GUEST, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* getAddressesGuest(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.ADDRESS.GET_ALL_ADDRESSES_GUEST, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* setAsDefaultGuest(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.ADDRESS.SET_DEFAULT_ADDRESS_GUEST, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}