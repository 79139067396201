import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import { useAddressApi } from '../../_common/hooks/api/addressApiHook';
import { useForm } from "react-hook-form";
import { useToaster } from '../../_common/hooks/common/appTosterHook';
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import { useUserSelector } from '../../_common/hooks/selectors/userSelector';


const AddressModal = (props: Record<string, any>) => {

    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const addressApi = useAddressApi();

    const [address, setAddress] = useState<any>(null);
    const [addressError, setAddressError] = useState<boolean>(false);
    const [value, setValue] = useState<any>('');
    const [lat, setLat] = useState<any>(0);
    const [lng, setLng] = useState<any>(0);
    const [gmapScript, setGmapScript] = useState<boolean>(false);
    const toast = useToaster();
    const user: any = useUserSelector();


    const addScript = (path: any, id: any) => {
		return new Promise((resolve, reject) => {
			if (document.getElementById(id)) {
				resolve(true);
			} else {
				let script = document.createElement('script');
				script.onload = function () {
					resolve(true);
				};
				script.src = path;
				script.id = id;
				document.head.appendChild(script);
			}

		});
	}

    const setLatLng = (lat:any,lng:any) => {
        setLat(lat);
        setLng(lng);
        console.log(lat,lng)
    }

	const checkGoogle = () => {
		return new Promise((resolve, reject) => {
			if ((window as any).google !== undefined) {
                setGmapScript(true)
				resolve(true);
			} else {
				let timer = setInterval(() => {
					if ((window as any).google !== undefined) {
						clearInterval(timer);
                        setGmapScript(true)
						resolve(true);
					}
				}, 100)
			}
		})
	}

    const load_Google_Map = () => {
		return new Promise((resolve, reject) => {
			addScript('https://maps.googleapis.com/maps/api/js?key='+process.env.REACT_APP_GOOGLE_KEY+'&libraries=places,geometry,drawing&v=weekly', 'g-map').then(() => {
				checkGoogle().then(() => {
					addScript('/assets/js/oms.min.js', 'oms').then(() => { resolve(true) })
				})
			})

		});
	}

    const onAddressSubmit = (data: any) => {
        data.lat = lat;//34.2804232;
        data.lng = lng;//-118.5039246;
        if(lat == 0 || lng == 0){
            setAddressError(true)
        } else {
            if (props.address) { data.id = props.address.id }
            data.addressLine1 = value;
            if(user){
                addressApi.addUpdateAddress(data, (message: any, resp: any) => {
                    props.update();
                    props.onHide();
                    toast.success(message);
                }, (message: any, resp: any) => {
                    toast.error(message);
                })
            }else{
                addressApi.addUpdateAddressGuest(data, (message: any, resp: any) => {
                    props.update();
                    props.onHide();
                    toast.success(message);
                }, (message: any, resp: any) => {
                    toast.error(message);
                })
            }
        }
    }


    const handleChange = (address:any) => {
        setValue(address)
        console.log('Handle Change ===================>',address)
        setLatLng(0, 0);
        setAddress(address);
    };
    
    const handleSelect = (address:any) => {
        console.log('Handle Select =>',address);
        setValue(address);
        geocodeByAddress(address).then((results:any) => getLatLng(results[0]))
          .then(({ lat, lng }) =>{
            setLatLng(lat, lng)
        });          
    };



    useEffect(() => {
        if (props.show) {
            load_Google_Map();
            reset({
                name: props.address ? props.address.name : '',
                mobile_no: props.address ? props.address.mobile_no : '',
                addressLine1: props.address ? props.address.addressLine1 : '',
                other_address: props.address ? props.address.other_address : '',
                type: props.address ? props.address.type : ''
            })
        }

    }, [props.show])




    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered className="add-review-modal" >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">{props.address ? 'Edit Address' : 'Create New Address'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="add-inspo">
                <form onSubmit={handleSubmit(onAddressSubmit)}>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input type="text" className={"form-control" + (errors?.name ? ' is-invalid' : '')} placeholder="Name" {...register("name", { required: true })} />
                        {errors?.name ? <div className="invalid-feedback">required</div> : null}
                    </div>

                    <div className="form-group">
                        <label htmlFor="name">Phone Number</label>
                        <input type="text" className={"form-control" + (errors?.mobile_no ? ' is-invalid' : '')} placeholder="Phone Number" {...register("mobile_no", { required: true })} />
                        {errors?.mobile_no ? <div className="invalid-feedback">required</div> : null}
                    </div>

                    {gmapScript?
                        <PlacesAutocomplete
                        value={value}
                        onChange={handleChange}
                        onSelect={handleSelect}
                        onError={() => toast.error('No results found')}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div className="add_input">
                            <div className="form-group">
                                <label htmlFor="name">Address</label>
                                <textarea onFocus={()=>{setAddressError(false)}}
                                    {...getInputProps({
                                    placeholder: 'Search Places',
                                    className: 'form-control' + (addressError? ' is-invalid' : ''),
                                    })}
                                />
                                {loading && <div className="text-white spinner-border ml-2" />}
                                {addressError ? <div className="invalid-feedback">required</div> : null}
                            </div>
                            {/* {touched &&
                                (error && (
                                    <FormFeedback>
                                    {error}
                                </FormFeedback>
                                ))} */}
                            <div className="autocomplete-dropdown-container">
                                {suggestions.map((suggestion, i) => {
                                    const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                    ? { backgroundColor: 'rgb(38,132,255)', color: '#fff', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                    <div 
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                        key={i}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                    );
                                })}
                            </div>
                        </div>
                        )}
                    </PlacesAutocomplete>
                    :null}

                    
                    {/* <div className="form-group">
                        <label htmlFor="name">Address</label>
                        <input type="text" className={"form-control" + (errors?.addressLine1 ? ' is-invalid' : '')} placeholder="Address" {...register("addressLine1", { required: true })} />
                        {errors?.addressLine1 ? <div className="invalid-feedback">required</div> : null}
                    </div> */}

                    <div className="form-group">
                        <label htmlFor="name">Do you have any specific delivery instructions?</label>
                        <input type="text" className={"form-control" + (errors?.other_address ? ' is-invalid' : '')} placeholder="" {...register("other_address", { required: true })} />
                        {errors?.other_address ? <div className="invalid-feedback">required</div> : null}
                    </div>
                    <br />
                    <div className="form-group">
                        <label htmlFor="name">Address Type</label>
                        <div className="d-flex align-items-center">
                            <div className="form-check form-check-inline">
                                <input type="radio" id="home" value="1" className="form-check-input" {...register("type", { required: true })} />
                                <label title="" htmlFor="home" className="form-check-label">Home</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input type="radio" id="work" value="2" className="form-check-input" {...register("type", { required: true })} />
                                <label title="" htmlFor="work" className="form-check-label">Work</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input type="radio" id="office" value="3" className="form-check-input" {...register("type", { required: true })} />
                                <label title="" htmlFor="office" className="form-check-label">Other</label>
                            </div>
                        </div>
                        {errors?.pinboard_description ? <div className="invalid-feedback">required</div> : null}
                    </div>
                    <br />
                    <div className="d-flex justify-content-center">
                        <Button variant="info" type="submit">{props.address ? 'Update Address' : 'Create New Address'}</Button>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide} variant="dark">Close</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default AddressModal;


