import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import AppLoader from './_common/components/elements/full-page-loader/appLoader';
import { store, persistor } from './store';
import axios from 'axios';
import { ACTIONS, API_URL, STORAGE } from './_config';
import { ToastContainer } from 'react-toastify';

function render() {
	ReactDOM.render(
		<React.Fragment>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<React.Fragment>
						<AppLoader />
						<ToastContainer
							position="top-center"
							autoClose={3000}
							hideProgressBar={false}
							newestOnTop={true}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>
						<App />
					</React.Fragment>
				</PersistGate>
			</Provider>
		</React.Fragment>,
		document.getElementById('root')
	);
}
//render();

const token = localStorage.getItem(STORAGE);

function tryLogin(url: string): Promise<any> {
	return new Promise((resolve, reject) => {
		try {
			axios({
				method: 'POST',
				url: url,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}).then(success => {
				if (success && success.data && success.data.user_details) {
					return resolve(success.data.user_details)
				} else {
					return reject(null)
				}
			}).catch(err => {
				return reject(null)
			})
		} catch (e) {
			return reject(null)
		}
	})
}

function getCart(): Promise<any> {
	return new Promise((resolve, reject) => {
		try {
			axios({
				method: 'GET',
				url: API_URL.CART.MY_CART,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}).then(success => {

				if (success && success.data) {
					store.dispatch({
						type: ACTIONS.CART.MY_CART,
						payload: {
							cart: success.data.cart_details,
						}
					});
					return resolve(success.data.cart_details)
				} else {
					return reject(null)
				}
			}).catch(err => {
				return reject(null)
			})
		} catch (e) {
			return reject(null)
		}
	})
}


if (token) {
	try {
		tryLogin(API_URL.USER.ME).then(userData => {
			store.dispatch({
				type: ACTIONS.USER.LOGIN,
				payload: {
					user: userData,
					token: token
				}
			});
			getCart()
			render();
		}).catch(e => {
			localStorage.removeItem(STORAGE);
			render();
		})
	} catch (e) {
		localStorage.removeItem(STORAGE);
		render();
	}
} else {
	render();
}
//serviceWorker.unregister();
