import React, { useEffect, useState } from "react";

interface incomingProps {
  limit: Number;
  text: any;
  className?: any;
}
export default function ReadMore({ limit, text, className }: incomingProps) {
  const [data, setData] = useState<any>(text);
  const [more, setMore] = useState<boolean>(true);
  console.log('====================================');
  console.log('text.length',text.length);
  console.log('====================================');
  const readmore = () => {
    if (!more) {
      setData(text)
    } else {
      setData(text.substr(0, limit) + '...')
    }


    setMore(!more);
  }

  useEffect(() => {
    if (text.length > limit) {
      setData(text.substr(0, limit) + '...')
    }
  }, [])


  return (
    // <div dangerouslySetInnerHTML={{ __html: data }} />onClick={readmore}
    <div>
  {more ?
      <p dangerouslySetInnerHTML={{ __html: text.slice(0, limit) }} className="my-4"></p>
:
      <p dangerouslySetInnerHTML={{ __html: text}} className="my-4"></p>
  }
  {text.length > limit && <span className="read-more-btn" onClick={readmore}>{more ? 'Read More':'Read Less'}....</span>}
</div>
  );
}
