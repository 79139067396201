import React, { useEffect, useState } from "react";
import { useUserApi } from '../_common/hooks/api/UserApiHook';
import { useCartApi } from '../_common/hooks/api/cartApiHook';
import { useForm } from "react-hook-form";
import { Container, Row, Col } from 'react-bootstrap'
import '../style/login.scss';
import { Link, useHistory } from "react-router-dom";
import { FaGoogle } from 'react-icons/fa';
import SocialButton from "./loginWithGoogle";
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useToaster } from '../_common/hooks/common/appTosterHook';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useAddressApi } from '../_common/hooks/api/addressApiHook';




export default function GuestForm(props: Record<string, any>) {
  const userApi = useUserApi();
  const cartApi = useCartApi();
  const { showLoader, hideLoader } = useAppLoader();
  const [loginError, setLoginError] = useState<string | null>(null);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const {
    register: login,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm();
  const {
    register: guest,
    formState: { errors: errors3 },
    handleSubmit: handleSubmit3,
  } = useForm();
  const toast = useToaster();
  const [passwoardError, setPasswoardError] = useState<string | null>(null);
  const [regError, setRegError] = useState<string | null>(null);
  const history = useHistory();
  const addressApi = useAddressApi();
  const [address, setAddress] = useState<any>(null);
  const [addressError, setAddressError] = useState<boolean>(false);
  const [value, setValue] = useState<any>('');
  const [lat, setLat] = useState<any>(0);
  const [lng, setLng] = useState<any>(0);
  const [gmapScript, setGmapScript] = useState<boolean>(false);
  const [otpcheck, setOtpcheck] = useState(false);
  const [otpEmail, setOtpEmail] = useState<any>("");
  const [otpPass, setOtpPass] = useState<any>("");
  const [enteredOtp, setEnteredOtp] = useState('');
  const {
    register: registerotp,
    formState: { errors: errors4 },
    handleSubmit: handleSubmit4,
  } = useForm();

  const onSubmitLogin = (data: any) => {
    //showLoader();
    console.log('Submit login =>', data);
    userApi.login(data, (message: string, resp: any) => {
      props.close();
      cartApi.myCart({}, (message: string, resp: any) => {
        console.log('My Cart', resp)
      }, (message: string) => { // login error..

      });
      //hideLoader();
    }, (message: string) => { // login error..
      setLoginError(message);
      //hideLoader();
    });
  }

  const onSubmitRegister = (data: any) => {
    setRegError(null)
    console.log('Submit login =>', data);

    if (data.password !== data.password_confirmation) {
      setPasswoardError('Password and Confirm Passwoard did not match.')
    } else {
      showLoader();
      setOtpEmail(data.email);
      setOtpPass(data.password);
      userApi.register({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone_no: data.mobile_no,
        password: data.password,
        password_confirmation: data.password_confirmation,
      }, (message: string, resp: any) => {
        toast.success(message)

        hideLoader();
      }, (message: string) => { // login error..
        console.log('error=>', message)
        toast.error(message)
        setRegError(message);
        setOtpcheck(true);
        hideLoader();
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
      });
    }

  }
  const changeOtpVal = (e: any) => {
    console.log(e.target.value);

    setEnteredOtp(e.target.value)
  }
  const onSubmit4 = (data: any) => {
    setRegError(null)
    console.log('Submit login =>', data);
    let logindata = {
      email: otpEmail,
      password: otpPass
    }
    // showLoader();
    userApi.otpcheck({
      otp: enteredOtp,
      email: otpEmail
    }, (message: string, resp: any) => {
      toast.success(message)
      props.close();
      hideLoader();
      userApi.login(logindata, (message: string, resp: any) => {
        props.close();
        cartApi.myCart({}, (message: string, resp: any) => {
          console.log('My Cart', resp)
        }, (message: string) => { // login error..

        });
        //hideLoader();
      }, (message: string) => { // login error..
        setLoginError(message);
        //hideLoader();
      });
    }, (message: string) => { // login error..
      console.log('error=>', message)
      toast.error(message)
      setRegError(message);
      hideLoader();
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    });

  }

  const addScript = (path: any, id: any) => {
    return new Promise((resolve, reject) => {
      if (document.getElementById(id)) {
        resolve(true);
      } else {
        let script = document.createElement('script');
        script.onload = function () {
          resolve(true);
        };
        script.src = path;
        script.id = id;
        document.head.appendChild(script);
      }

    });
  }

  const setLatLng = (lat: any, lng: any) => {
    setLat(lat);
    setLng(lng);
    console.log(lat, lng)
  }

  const checkGoogle = () => {
    return new Promise((resolve, reject) => {
      if ((window as any).google !== undefined) {
        setGmapScript(true)
        resolve(true);
      } else {
        let timer = setInterval(() => {
          if ((window as any).google !== undefined) {
            clearInterval(timer);
            setGmapScript(true)
            resolve(true);
          }
        }, 100)
      }
    })
  }

  const load_Google_Map = () => {
    return new Promise((resolve, reject) => {
      addScript('https://maps.googleapis.com/maps/api/js?key=' + process.env.REACT_APP_GOOGLE_KEY + '&libraries=places,geometry,drawing&v=weekly', 'g-map').then(() => {
        checkGoogle().then(() => {
          addScript('/assets/js/oms.min.js', 'oms').then(() => { resolve(true) })
        })
      })

    });
  }

  const onAddressSubmit = (data: any) => {
    console.log(data);

    data.lat = lat;//34.2804232;
    data.lng = lng;//-118.5039246;
    data.addressLine1 = value;
    if (lat == 0 || lng == 0) {
      setAddressError(true)
    } else {
      localStorage.setItem('__fstvAddress', '1');
      // let search = window.location.search;
      // let params = new URLSearchParams(search);
      // let redirectTo = params.get('redirectTo');
      // console.log(redirectTo);

      // window.location.href = window.location.href;
      props.close();
      data.type = "3";
      console.log(data);
      addressApi.addUpdateAddressGuest(data, (message: any, resp: any) => {
        toast.success(message);
      }, (message: any, resp: any) => {
        toast.error(message);
      })
      console.log('data');
    }
  }


  const handleChange = (address: any) => {
    setValue(address)
    console.log('Handle Change ===================>', address)
    setLatLng(0, 0);
    setAddress(address);
  };

  const handleSelect = (address: any) => {
    console.log('Handle Select =>', address);
    setValue(address);
    geocodeByAddress(address).then((results: any) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setLatLng(lat, lng)
      });
  };



  useEffect(() => {
    load_Google_Map();
  }, [])



  return (
    <section className="team-widget">

      <Container>
        <Row>
          <Col sm={12}>
            <Tabs
              defaultActiveKey="login"
              id="fill-tab-example"
              className="mb-3"
            >
              <Tab eventKey="login" title="Sign In">
                <div className="login">
                  <h2><b>Welcome Back</b></h2>
                  <form onSubmit={handleSubmit2(onSubmitLogin)}>
                    <div className="form-group position-relative mb-4">
                      <label htmlFor="email">Email</label>
                      <input type="text" className={"form-control f_line" + (errors?.email ? ' is-invalid' : '')} placeholder="Email" {...login("email", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
                      {errors?.email ? <div className="invalid-feedback">
                        {errors.email.type === "required" ? 'required' : null}
                        {errors.email.type === "pattern" ? 'invalid' : null}
                      </div> : null}
                    </div>

                    <div className="form-group position-relative mb-4">
                      <label htmlFor="password">Password</label>
                      <input type="password" className={"form-control f_line" + (loginError || errors?.password ? ' is-invalid' : '')} onFocus={() => { setLoginError(null) }} placeholder="Password" {...login("password", { required: true, maxLength: 80 })} />
                      {errors?.password ? <div className="invalid-feedback">required</div> : null}
                      {loginError ? <div className="invalid-feedback">{loginError}</div> : null}
                    </div>

                    <div className="btns">
                      <button className="btn btn-info d-block waves-effect waves-light" type="submit">Log in to Festively</button>


                    </div>
                  </form>
                </div>
              </Tab>
              <Tab eventKey="register" title="Sign Up">
                <div className="login">
                  <h2><b>Create a account & get access to perks</b></h2>
                  {regError ? <h3 className="text-danger text-center">{regError}</h3> : null}
                  {!otpcheck ?
                    <form onSubmit={handleSubmit(onSubmitRegister)}>

                      <div className="form-group position-relative mb-4">
                        <label htmlFor="email">What's your first name?</label>
                        <input type="text" className={"form-control f_line" + (errors?.first_name ? ' is-invalid' : '')} placeholder="Enter your first name" {...register("first_name", { required: true })} />
                        {errors?.first_name ? <div className="invalid-feedback">
                          {errors.first_name.type === "required" ? 'required' : null}
                        </div> : null}
                      </div>

                      <div className="form-group position-relative mb-4">
                        <label htmlFor="email">What's your last name?</label>
                        <input type="text" className={"form-control f_line" + (errors?.last_name ? ' is-invalid' : '')} placeholder="Enter your last name" {...register("last_name", { required: true })} />
                        {errors?.last_name ? <div className="invalid-feedback">
                          {errors.last_name.type === "required" ? 'required' : null}
                        </div> : null}
                      </div>


                      <div className="form-group position-relative mb-4">
                        <label htmlFor="email">What's your email address?</label>
                        <input type="text" className={"form-control f_line" + (errors?.email ? ' is-invalid' : '')} placeholder="Enter your email address" {...register("email", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
                        {errors?.email ? <div className="invalid-feedback">
                          {errors.email.type === "required" ? 'required' : null}
                          {errors.email.type === "pattern" ? 'invalid' : null}
                        </div> : null}
                      </div>

                      <div className="form-group position-relative mb-4">
                        <label htmlFor="mobile_no">What's your phone number?</label>
                        <input type="tel" className={"form-control f_line" + (errors?.mobile_no ? ' is-invalid' : '')} placeholder="Enter your phone number" {...register("mobile_no", { required: true })} />
                        {errors?.mobile_no ? <div className="invalid-feedback">
                          {errors.mobile_no.type === "required" ? 'required' : null}
                        </div> : null}
                      </div>

                      <div className="form-group position-relative mb-4">
                        <label htmlFor="password">Lets add some security</label>
                        <input type="password" className={"form-control f_line" + (passwoardError || errors?.password ? ' is-invalid' : '')} placeholder="Enter a password" {...register("password", { required: true, minLength: 8, maxLength: 16, pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/i })} />
                        {errors?.password ? <div className="invalid-feedback">
                          {errors.password.type === "required" ? 'required' : null}
                          {errors.password.type === "minLength" ? 'Minimum 8 characters' : null}
                          {errors.password.type === "maxLength" ? 'Maximum 16 characters' : null}
                          {errors.password.type === "pattern" ? 'Password need uppercase, lowercase, number, special character' : null}

                        </div> : <div className="small">Use 8 or more characters with a mix of Lower Case and Uppercase character, numbers & symbols</div>}

                        {passwoardError ? <div className="invalid-feedback">{passwoardError}</div> : null}
                      </div>

                      <div className="form-group position-relative mb-4">
                        <label htmlFor="password_confirmation">Confirm your password</label>
                        <input type="password" className={"form-control f_line" + (passwoardError || errors?.password ? ' is-invalid' : '')} placeholder="Enter password again" {...register("password_confirmation", { required: true, maxLength: 80 })} />
                        {errors?.password_confirmation ? <div className="invalid-feedback">required</div> : null}
                        {passwoardError ? <div className="invalid-feedback">{passwoardError}</div> : null}
                      </div>

                      {/* <GoogleReCaptchaProvider reCaptchaKey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI">
                  <GoogleReCaptcha onVerify={handleCapchaVerify} />
                </GoogleReCaptchaProvider> */}

                      <div className="btns">
                        <p>By clicking "Create My Account" you agree to our Terms of Use Policy.</p>
                        <button className="btn btn-info d-block waves-effect waves-light" type="submit">Create My Account</button>
                      </div>

                    </form>
                    :
                    <form onSubmit={handleSubmit4(onSubmit4)}>

                      <div className="form-group position-relative mb-4">
                        <label htmlFor="email">Enter your verification code</label>
                        <input type="text" className={"form-control f_line" + (errors?.otp ? ' is-invalid' : '')} placeholder="Enter your verification code" value={enteredOtp} onChange={changeOtpVal} />
                        {errors?.otp ? <div className="invalid-feedback">
                          {errors.otp.type === "required" ? 'required' : null}
                        </div> : null}
                      </div>

                      <div className="btns">
                        <button className="btn btn-info d-block waves-effect waves-light" type="submit">Submit</button>
                      </div>

                    </form>
                  }
                </div>
              </Tab>
              { !props.hasQuote ? 
              <Tab eventKey="guest" title="Continue as Guest">
                <div className="login">
                  <h2><b>Hello there</b></h2>
                  {regError ? <h3 className="text-danger text-center">{regError}</h3> : null}

                  <form onSubmit={handleSubmit3(onAddressSubmit)}>
                    {gmapScript ?
                      <PlacesAutocomplete
                        value={value}
                        onChange={handleChange}
                        onSelect={handleSelect}
                        onError={() => toast.error('No results found')}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div className="add_input">
                            <div className="form-group">
                              <label htmlFor="email">What is the celebration or delivery address?</label>
                              <input onFocus={() => { setAddressError(false) }}
                                {...getInputProps({
                                  name: 'address',
                                  placeholder: 'Add the address',
                                  className: 'form-control' + (addressError ? ' is-invalid' : ''),
                                })}
                              />
                              {loading && <div className="text-white spinner-border ml-2" />}
                              {addressError ? <div className="invalid-feedback">required</div> : null}
                            </div>
                            {/* {touched &&
                                (error && (
                                    <FormFeedback>
                                    {error}
                                </FormFeedback>
                                ))} */}
                            <div className="autocomplete-dropdown-container">
                              {suggestions.map((suggestion, i) => {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? { backgroundColor: 'rgb(38,132,255)', color: '#fff', cursor: 'pointer' }
                                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                    key={i}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                      : null}
                    <div className="form-group position-relative mb-4">
                      <label htmlFor="email">Who is the point of contact?</label>
                      <input type="text" className={"form-control f_line"} placeholder="Add a name(Optional)" {...guest("name")} />
                    </div>
                    <div className="form-group position-relative mb-4">
                      <label htmlFor="email">What is a good contact number?</label>
                      <input type="number" className={"form-control f_line"} placeholder="Enter a phone Number(Optional)" {...guest("mobile_no")} />
                    </div>
                    <div className="form-group mt-4">
                      <label htmlFor="email">Do you have any specific delivery instructions?</label>
                      <input type="text" className={"form-control" + (errors?.other_address ? ' is-invalid' : '')} placeholder="Specific delivery instructions" {...guest("other_address", { required: true })} />
                      {errors?.other_address ? <div className="invalid-feedback">required</div> : null}
                    </div>
                    <div className="btns">
                      <button className="btn btn-info d-block waves-effect waves-light mt-3" type="submit">Continue</button>
                    </div>

                  </form>
                </div>
              </Tab>
              :''
              }
            </Tabs>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
