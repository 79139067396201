import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; //useHistory, useParams
import { URLS } from '../../_config';
import { useUserSelector } from '../../_common/hooks/selectors/userSelector';

import { useUserApi } from '../../_common/hooks/api/UserApiHook';
import { useCmsApi } from '../../_common/hooks/api/cmsApiHook';
import { useCartApi } from '../../_common/hooks/api/cartApiHook';
import { FaTimes } from 'react-icons/fa';
import { AiOutlineMenu } from 'react-icons/ai';
import { BiLogOutCircle, BiLogInCircle } from 'react-icons/bi';
import { HiOutlineUser } from 'react-icons/hi';
import Drawer from '@material-ui/core/Drawer';
import { Container, Row, Button, Col } from 'react-bootstrap';
import { useCartSelector } from '../../_common/hooks/selectors/cartSelector';
import CartItems from "../../components/cart/cartItems";
import Item from "./item";

import NavSearch from "./navSearch";


import '../../style/header.scss';
import { Helmet } from "react-helmet";

export default function Header() {

  const user: any = useUserSelector();
  const cart: any = useCartSelector();
  const userApi = useUserApi();
  const cmsApi = useCmsApi();
  const cartApi = useCartApi();

  const [drawer, setDrawer] = useState<boolean>(false);
  const [cartDrawer, setCartDrawer] = useState<boolean>(false);
  const [mobileView, setMobileView] = useState<boolean>(false);
  const [headerMenu, setHeaderMenu] = useState<any>(null);
  const [fstvCart, setFstvCart] = useState<any>(null);

  const [offerDrawer, setOfferDrawer] = useState<boolean>(false);
  const [offers, setOffers] = useState<any>(null);

  const [prevCart, setPrevCart] = useState<any>(null);
  const [headerText, setHeaderText] = useState<any>('');
  const [cartCount, setCartCount] = useState<any>(0);


  const closeOfferDrawer = () => {
    setOfferDrawer(false);
    setOffers(null);
  }



  useEffect(() => {
    if (cart?.cart?.items.length > 0) {
      if (prevCart) {
        if (cart?.cart?.items) {

          if (cart.cart.items.length > prevCart.cart?.items.length || prevCart.cart == undefined) {
            setOffers(cart.cart.items[cart.cart.items.length - 1].offers)
          } else {
            //console.log('Just cart')
          }
        }
      }
    }
    setPrevCart(cart);
    setCartCount(cart?.cart?.items.length)
  }, [cart])

  useEffect(()=>{
    cartApi.myCart({}, (message: any, resp: any) => {
    }, (message: any, resp: any) => {
      console.error(message)
    })
  },[]);

  // useEffect(() => {

  //   const __fstvCartString = localStorage.getItem("__fstvCart");
  //   if (__fstvCartString) {
  //     let __fstvCart = JSON.parse(__fstvCartString);
  //     setFstvCart(__fstvCart);

  //     setCartCount(__fstvCart.length)
  //   }
  //   function storageEventHandler(event: any) {
  //     if (event.key === "__fstvCart") {
  //       const fstvCartString = localStorage.getItem("__fstvCart");
  //       let __fstvCart = JSON.parse(fstvCartString || '{}');
  //       setFstvCart(__fstvCart);
  //       setCartCount(__fstvCart.length)
  //       console.log('fstvVart', __fstvCart);
  //     }

  //   }
  //   window.addEventListener("storage", storageEventHandler);
  //   return () => {
  //     window.removeEventListener("storage", storageEventHandler);
  //   };
  // }, []);




  const logout = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    userApi.logout({}, (message: string, resp: any) => {
      setTimeout(() => {
        cartApi.myCart({}, (message: string, resp: any) => {
          console.log('My Cart', resp)
        }, (message: string) => { // login error..

        });
      }, 150)
    }, (message: string) => {

    })
  }

  const getMyLink = (menu: any) => {
    let url = '/';
    switch (menu.value_type) {
      case "themes": url = '/listing/themes/' + menu.slug; break;
      case "category": url = '/listing/category/' + menu.slug; break;
      default:
    }
    return url;
  }
  //1389
  const setViewMode = () => {
    if ((window as any).innerWidth > 1332) {
      setMobileView(false);
    } else {
      setMobileView(true);
    }
  }
  const closeCart = () => {
    setCartDrawer(false)
  }




  useEffect(() => {
    //console.log('offers ====>', offers)
    if (offers) {
      setCartDrawer(false);
      if (offers.length > 0) {
        setOfferDrawer(true);
      } else {
        console.log("NO OFFER FOUND!")
      }

    }
  }, [offers])


  useEffect(() => {
    setViewMode();
    (window as any).onresize = () => {
      setViewMode()
    }
    cmsApi.getOccassionData({ type: '4' }, (message: string, resp: any) => {
      setHeaderMenu(resp.data)
    }, (message: string) => { // login error..

    });

    cmsApi.getTopHeaderText({}, (message: string, resp: any) => {
      setHeaderText(resp.data.type_value)
    }, (message: string) => { // login error..

    });
  }, [])



  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href={(window as any).location.href} />
        <title>Festively</title>

        <meta property="og:title" content="Festively" />
        <meta property="og:url" content={(window as any).location.href} />
        <meta property="og:image" content="https://webkon.biz/MTCEddyStoneContentPath/Company/Fallback//original/no_preview.png" />
        <meta property="og:site_name" content="Festively" />
        <meta property="og:type" content="website" />


      </Helmet>
      <div className="header-container top-bar bg-primary">
        <Container className="position-relative">
          <Row>
            <h4>{headerText}</h4>
            {!mobileView ?
              <React.Fragment>
                {user ?
                  <div className="user">
                    <div className="profile">
                      <img src={user.profile_pic} alt={user.name} />
                      <Link to={URLS.USER.MY_ACCOUNT}>My Account</Link>
                    </div>

                  </div>
                  :
                  <div className="user">
                    <div className="profile">
                      <img src="/assets/img/user-icon-white.png" alt="" className="icon" />
                      <Link to={URLS.USER.LOGIN}>Sign In</Link>
                    </div>
                    <span>|</span>
                    <Link to={URLS.USER.REGISTER}>Register</Link>

                  </div>
                }

              </React.Fragment>
              : null}
          </Row>
        </Container>
      </div>

      <section className="header-container header">
        <Container className="position-relative">
          <div className="nav">
            {mobileView ? <AiOutlineMenu className="menu-icon" onClick={() => { setDrawer(true) }} /> : null}
            <Link to="/"><img src="/assets/img/logo.webp" alt="logo" className="logo" /></Link>
            <div className="search-field">
              <NavSearch />
            </div>
            {!mobileView ?
              <div className="links">
                {headerMenu?.map((menu: any, i: any) => (
                  <Link to={getMyLink(menu)} key={i}>{menu.text}</Link>
                ))}
              </div>
              : null}

            <div className="cart-icon" onClick={() => { setCartDrawer(true) }}>
              <img src="/assets/img/shopping-icon.png" alt="" />
              <div className="count">{cartCount ? cartCount : 0}</div>
            </div>

          </div>
        </Container>
      </section>


      {mobileView ?
        <div className="mob-search-field"></div>
        : null}


      <Drawer anchor="right" open={drawer} onClose={() => { setDrawer(false) }}>
        <div className="drawer-body">
          <FaTimes className="close-btn" onClick={() => { setDrawer(false) }} />
          <div className="drawer-bar">
            {user ? <img src={user.profile_pic} alt={user.name} /> : <HiOutlineUser className="icon" />}
            <label>{user ? user.name : 'Hello Guest!'}</label>
            {user ? <BiLogOutCircle className="icon" onClick={logout} /> : <Link to="/login"><BiLogInCircle className="icon" /></Link>}
          </div>
          <ul className="drawer-nav">
            <li><Link to={URLS.HOME}>Home</Link></li>
            {/* <li><Link to={URLS.HOME}>Sympathy</Link></li>
            <li><Link to={URLS.HOME}>Congratulations</Link></li>
            <li><Link to={URLS.HOME}>Thank You</Link></li>
            <li><Link to={URLS.HOME}>Get Well</Link></li>
            <li><Link to={URLS.HOME}>Birthdays</Link></li>
            <li><Link to={URLS.ABOUT}>About Us</Link></li>
            <li><a href="https://blog.festively.com/" target="_blank" rel="noreferrer">Blog</a></li> */}
            {headerMenu?.map((menu: any, i: any) => (
              <li key={i}><Link to={getMyLink(menu)} key={i}>{menu.text}</Link></li>
            ))}

            {user ? <React.Fragment>
              <li><Link to={URLS.USER.MY_ACCOUNT}>My Account</Link></li>
              <li><a href="#" onClick={logout}>Logout</a></li>

            </React.Fragment>
              : <li><Link to={URLS.USER.LOGIN}>Sign In | Register</Link></li>}

          </ul>
        </div>
      </Drawer>

      <Drawer anchor="right" open={cartDrawer} onClose={() => { setCartDrawer(false) }}>
        <div className="cart-drawer-body">


          <div className="drawer-bar">
            <FaTimes className="close-btn" onClick={() => { setCartDrawer(false) }} />
            <label>Your Cart</label>
          </div>
          <div className="drawer-footer">
            <Link to="/checkout" className="btn btn-info btn-lg">Checkout</Link>
            <Button variant="primary" size="lg" onClick={() => { setCartDrawer(false) }} >Keep Shopping</Button>
          </div>
          <CartItems closeCart={closeCart} showOffers={setOffers} />
        </div>
      </Drawer>


      <Drawer anchor="right" open={offerDrawer} onClose={closeOfferDrawer}>
        <div className="cart-drawer-body">
          <div className="drawer-bar">
            <FaTimes className="close-btn" onClick={closeOfferDrawer} />
            <label>Suggested Items</label>
          </div>
          <Row className="suggest">
            {offers?.map((product: any, i: any) => (
              <Col md={6} sm={12} key={i}>
                <Item item={product} type="product" offer={true} closeOfferDrawer={closeOfferDrawer} theme="from" />
              </Col>
            ))}
          </Row>
          {/* <CartItems closeCart={closeCart} /> */}
        </div>
      </Drawer>






    </React.Fragment>
  );
}