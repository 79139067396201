import { takeLatest, all, takeEvery } from 'redux-saga/effects'; // fork, take,  takeEvery, takeLeading
import { SAGA_ACTIONS } from '../_config';


import { login, register, logout, editProfile, aboutMe, myOrders, orderDetails, resetPassword, forgotPassword, cancelOrder, getOtp } from './userSaga';
import { getThemeCatInfo, getProducts } from './commonSaga';
import { getVendorDetails, getVendorFAQ, getVendorFriendors } from './vendorSaga';
import { getProductListSearch, changeLoved, getProductListDetails, checkAvailaibility, getProductGlobalSearch } from './productSaga';
import { getSuggestions, search } from './globalSaga';
import { getChatUsers, getChatDetails, sendMessage } from './chatSaga';
import { sendQuoteRequest, getQuote, getQuoteList } from './quoteSaga';
import { addToCart, saveForLater, editProductToCart, modifyCart, myCart, setCartAddress, bookCartCheck, checkCartAvailable, applyCoupons, removeCoupons, removeNotes, giftNotes, updateCartAddress, checkout, updateCart, guestCheckOut, myWishlist, deleteWishlist, wishlistMoveToCart, getRemainingDetails } from './cartSaga';
import { getFevorite, getMyUnique, getInspoBoards, addToInspoBoard, addUpdateInspoBoard, deleteInspoBoard } from './loveFestSaga';
import { getProductReviews, addProductReviews, getMyReviewedProducts, deleteReview, uploadImage, deleteImage } from './reviewSaga';
import { addUpdateAddress, getAddresses, setAsDefault, getAddressDetails, deleteAddress, addUpdateAddressGuest, getAddressesGuest, setAsDefaultGuest } from './addressSaga';
import { getBannerData, cmsSearch, getBlogData, settingsSearch, getTestimonials, getOccassionData, getMakersData, getOccassionList, getCategoryList, getDeliveryTypes, getPriceRangeAndSorts, filterProduct, emailSubscribe, getTopHeaderText, getCategoryDetailsList, getAllOccassionList, getVendorList, getAllOccassionThemeList } from './cmsSaga';
export function* sagas() {
  yield all([
    takeLatest(SAGA_ACTIONS.USER.LOGIN, login),
    takeLatest(SAGA_ACTIONS.USER.OTP, getOtp),
    takeLatest(SAGA_ACTIONS.USER.REGISTER, register),
    takeLatest(SAGA_ACTIONS.USER.LOGOUT, logout),
    takeEvery(SAGA_ACTIONS.USER.EDIT_PROFILE, editProfile),
    takeEvery(SAGA_ACTIONS.USER.ME, aboutMe),
    takeEvery(SAGA_ACTIONS.USER.MY_ORDERS, myOrders),
    takeEvery(SAGA_ACTIONS.USER.ORDER_DETAILS, orderDetails),
    takeEvery(SAGA_ACTIONS.USER.RESET_PASSWORD, resetPassword),
    takeEvery(SAGA_ACTIONS.USER.FORGOT_PASSWORD, forgotPassword),
    takeEvery(SAGA_ACTIONS.USER.CANCEL_ORDER, cancelOrder),

    takeLatest(SAGA_ACTIONS.CMS.BANNER_DATA, getBannerData),
    takeEvery(SAGA_ACTIONS.CMS.SEARCH, cmsSearch),
    takeEvery(SAGA_ACTIONS.CMS.SETTINGS, settingsSearch),
    takeEvery(SAGA_ACTIONS.CMS.BLOG_DATA, getBlogData),
    takeEvery(SAGA_ACTIONS.CMS.TESTIMONIALS, getTestimonials),
    takeEvery(SAGA_ACTIONS.CMS.OCCASSION_DATA, getOccassionData),
    takeEvery(SAGA_ACTIONS.CMS.MAKERS_DATA, getMakersData),
    takeEvery(SAGA_ACTIONS.CMS.OCCASSION_LIST, getOccassionList),
    takeEvery(SAGA_ACTIONS.CMS.ALL_OCCASSION_LIST, getAllOccassionList),
    takeEvery(SAGA_ACTIONS.CMS.ALL_OCCASSION_THEME_LIST, getAllOccassionThemeList),
    takeEvery(SAGA_ACTIONS.CMS.VENDOR_LIST, getVendorList),
    takeEvery(SAGA_ACTIONS.CMS.CATEGORY_LIST, getCategoryList),
    takeEvery(SAGA_ACTIONS.CMS.CATEGORY_DETAILS_LIST, getCategoryDetailsList),
    takeEvery(SAGA_ACTIONS.CMS.DELIVERY_LIST, getDeliveryTypes),
    takeEvery(SAGA_ACTIONS.CMS.PRICE_RANGE_AND_SORTS, getPriceRangeAndSorts),
    takeEvery(SAGA_ACTIONS.CMS.FILTER, filterProduct),
    takeEvery(SAGA_ACTIONS.CMS.EMAIL_SUBSCRIBE, emailSubscribe),
    takeEvery(SAGA_ACTIONS.CMS.TOP_HEADER, getTopHeaderText),

    takeEvery(SAGA_ACTIONS.COMMON.GET_THEME_CAT_INFO, getThemeCatInfo),
    takeEvery(SAGA_ACTIONS.COMMON.GET_PRODUCTS, getProducts),

    takeEvery(SAGA_ACTIONS.VENDOR.VENDOR_DETAILS, getVendorDetails),
    takeEvery(SAGA_ACTIONS.VENDOR.FRIENDORS_LIST, getVendorFriendors),
    takeEvery(SAGA_ACTIONS.VENDOR.VENDOR_FAQ, getVendorFAQ),

    takeEvery(SAGA_ACTIONS.PRODUCT.PRODUCT_LIST_SEARCH, getProductListSearch),
    takeEvery(SAGA_ACTIONS.PRODUCT.PRODUCT_GLOBAL_SEARCH, getProductGlobalSearch),
    takeEvery(SAGA_ACTIONS.PRODUCT.CHANGE_LOVE, changeLoved),
    takeEvery(SAGA_ACTIONS.PRODUCT.PRODUCT_LIST_DETAILS, getProductListDetails),
    takeEvery(SAGA_ACTIONS.PRODUCT.CHECK_AVAILABILITY, checkAvailaibility),

    takeLatest(SAGA_ACTIONS.GLOBAL_SEARCH.GET_SUGGESTIONS, getSuggestions),
    takeLatest(SAGA_ACTIONS.GLOBAL_SEARCH.SEARCH, search),
    takeEvery(SAGA_ACTIONS.LOVE_FEST.GET_FAVORITE, getFevorite),
    takeEvery(SAGA_ACTIONS.LOVE_FEST.GET_UNIQUE_LISTINGS, getMyUnique),
    takeEvery(SAGA_ACTIONS.LOVE_FEST.GET_ALL_INSPOBOARD, getInspoBoards),
    takeEvery(SAGA_ACTIONS.LOVE_FEST.ADD_TO_INSPOBOARD, addToInspoBoard),
    takeEvery(SAGA_ACTIONS.LOVE_FEST.ADD_UPDATE_INSPOBOARD, addUpdateInspoBoard),
    takeEvery(SAGA_ACTIONS.LOVE_FEST.DELETE_INSPOBOARD, deleteInspoBoard),


    takeEvery(SAGA_ACTIONS.ADDRESS.ADD_UPDATE_ADDRESS, addUpdateAddress),
    takeEvery(SAGA_ACTIONS.ADDRESS.GET_ALL_ADDRESSES, getAddresses),
    takeEvery(SAGA_ACTIONS.ADDRESS.SET_DEFAULT_ADDRESS, setAsDefault),
    takeEvery(SAGA_ACTIONS.ADDRESS.GET_ADDRESS_DETAILS, getAddressDetails),
    takeEvery(SAGA_ACTIONS.ADDRESS.DELETE_ADDRESS, deleteAddress),
    takeEvery(SAGA_ACTIONS.ADDRESS.ADD_UPDATE_ADDRESS_GUEST, addUpdateAddressGuest),
    takeEvery(SAGA_ACTIONS.ADDRESS.GET_ALL_ADDRESSES_GUEST, getAddressesGuest),
    takeEvery(SAGA_ACTIONS.ADDRESS.SET_DEFAULT_ADDRESS_GUEST, setAsDefaultGuest),

    takeEvery(SAGA_ACTIONS.REVIEW.GET_PRODUCT_REVIEW, getProductReviews),
    takeEvery(SAGA_ACTIONS.REVIEW.REVIEW, addProductReviews),
    takeEvery(SAGA_ACTIONS.REVIEW.GET_USER_OWN_REVIEW, getMyReviewedProducts),
    takeEvery(SAGA_ACTIONS.REVIEW.DELETE_USER_OWN_REVIEW, deleteReview),
    takeEvery(SAGA_ACTIONS.REVIEW.UPLOAD_IMAGE, uploadImage),
    takeEvery(SAGA_ACTIONS.REVIEW.DELETE_IMAGE, deleteImage),

    takeEvery(SAGA_ACTIONS.CHAT.USERS, getChatUsers),
    takeEvery(SAGA_ACTIONS.CHAT.MESSAGE_DETAILS, getChatDetails),
    takeEvery(SAGA_ACTIONS.CHAT.SEND_MESSAGE, sendMessage),

    takeEvery(SAGA_ACTIONS.QUOTE.SEND_QUOTE_REQUEST, sendQuoteRequest),
    takeEvery(SAGA_ACTIONS.QUOTE.GET_QUOTE, getQuote),
    takeEvery(SAGA_ACTIONS.QUOTE.GET_QUOTE_LIST, getQuoteList),


    takeEvery(SAGA_ACTIONS.CART.ADD_PRODUCT_TO_CART, addToCart),
    takeEvery(SAGA_ACTIONS.CART.SAVE_FOR_LATER, saveForLater),
    takeEvery(SAGA_ACTIONS.CART.EDIT_PRODUCT_TO_CART, editProductToCart),
    takeEvery(SAGA_ACTIONS.CART.MODIFY_CART, modifyCart),
    takeEvery(SAGA_ACTIONS.CART.MY_CART, myCart),
    takeEvery(SAGA_ACTIONS.CART.MOVE_TO_CART, wishlistMoveToCart),
    takeEvery(SAGA_ACTIONS.CART.DELETE_WISHLIST, deleteWishlist),
    takeEvery(SAGA_ACTIONS.CART.WISHLIST, myWishlist),
    takeEvery(SAGA_ACTIONS.CART.SET_CART_ADDRESS, setCartAddress),
    takeEvery(SAGA_ACTIONS.CART.BOOK_CART_CHECK, bookCartCheck),
    takeEvery(SAGA_ACTIONS.CART.CHECK_CART_AVAILABLE, checkCartAvailable),
    takeEvery(SAGA_ACTIONS.CART.APPLY_COUPONS, applyCoupons),
    takeEvery(SAGA_ACTIONS.CART.REMOVE_COUPONS, removeCoupons),
    takeEvery(SAGA_ACTIONS.CART.REMAINING_DETAILS, getRemainingDetails),
    takeEvery(SAGA_ACTIONS.CART.REMOVE_NOTES, removeNotes),
    takeEvery(SAGA_ACTIONS.CART.GIFT_NOTES, giftNotes),
    takeEvery(SAGA_ACTIONS.CART.UPDATE_CART_ADDRESS, updateCartAddress),
    takeEvery(SAGA_ACTIONS.CART.CHECKOUT, checkout),
    takeEvery(SAGA_ACTIONS.CART.UPDATE_CART, updateCart),
    takeEvery(SAGA_ACTIONS.CART.GUEST_CHECK_OUT, guestCheckOut),

  ]);
}