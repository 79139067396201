import React, { useEffect, useState } from "react";
import { useProductApi } from "../../_common/hooks/api/productApiHook";
import Header from "../../components/common/header";
import { Col, Container, Row } from "react-bootstrap";
import Item from "../../components/common/item";
import Filter from "../../components/filter";
import { useHistory } from "react-router";
import { useCmsApi } from "../../_common/hooks/api/cmsApiHook";

export default function NewestProducts() {
  const productApi = useProductApi();
  const [items, setItems] = useState<any>(null);
  const [newItems, setNewItems] = useState<any>(null);
  const [priceRanges, setPriceRanges] = useState<any>(null);
  const [sorts, setSorts] = useState<any>(null);
  const [filterData, setFilterData] = useState<any>(null);
  const [categoryList, setCategoryList] = useState<any>(null);
  const [deliveryList, setDeliveryList] = useState<any>(null);
  const [occassionList, setOccassionList] = useState<any>(null);
  const cmsApi = useCmsApi();
  const history = useHistory();

  const searchProducts = () => {
    let QS: any = new URLSearchParams((window as any).location.search);
    var filter = QS.get('filter') ? atob(QS.get('filter')) : null;
    console.log('filter', filter);
    if (filter) {
      let filterData = JSON.parse(filter);
      filterData.page = 1;
      filterData.per_page_item = 20;
      filterData.order = 'newest';
      productApi.getProductListSearch(
        filterData,
        (message: string, resp: any) => {
          setItems([]);
          setTimeout(() => {
            console.log("ffff", resp.product_list);
            setNewItems([]);
            setItems(resp.product_list.data);
          }, 500);
        },
        (message: string) => {
          setItems([]);
          // getNewProducts();
        }
      );
      console.log("items2", items);
    } else {
      productApi.getProductListSearch({ page: 1, per_page_item: 20, order: 'newest' }, (message: string, resp: any) => {
        setItems([]);
        setTimeout(() => {
          console.log('ffff', resp.product_list);
          setNewItems([]);
          setItems(resp.product_list.data)
        }, 500);
      }, (message: string) => {
        setItems([]);
      });
      console.log('items2', items);
    }
  };

  const setFilter = (key: any, value: any) => {

    let filters: any = {};
    if (filterData) {
      filters = JSON.parse(JSON.stringify(filterData))
    }
    if (value == 0 || value == null || value == '') {
      delete filters[key]
    } else {
      filters[key] = value;
    }


    setFilterData(filters);
    let _url = '?filter=' + btoa(JSON.stringify(filters))
    console.log(_url);

    history.push(_url);
    setTimeout(() => { searchProducts(); }, 100)

  }
  const getOccassionList = () => {
    cmsApi.getOccassionList({}, (message: any, resp: any) => {
      setOccassionList(resp.data)
    }, (message: any, resp: any) => { })
  }

  const getCategoryList = () => {
    cmsApi.getCategoryList({}, (message: any, resp: any) => {
      setCategoryList(resp.data)
    }, (message: any, resp: any) => { })
  }

  const getDeliveryList = () => {
    cmsApi.getDeliveryTypes({}, (message: any, resp: any) => {
      setDeliveryList(resp.data)
    }, (message: any, resp: any) => { })
  }



  const getPriceRangeAndSorts = () => {
    cmsApi.getPriceRangeAndSorts({}, (message: any, resp: any) => {
      setPriceRanges(resp.data.price)
      setSorts(resp.data.sort)
    }, (message: any, resp: any) => { })
  }

  useEffect(() => {
    searchProducts();
    getOccassionList();
    getCategoryList();
    getDeliveryList();
    getPriceRangeAndSorts();
  }, []);

  return (
    <React.Fragment>
      <Header />
      <section className="listing">
        <Container>
          <Row>
            <Col sm={3}>
              <Filter setFilter={setFilter} filterData={filterData} priceRanges={priceRanges} categoryList={categoryList} occassionList={occassionList} deliveryList={deliveryList} />
            </Col>
            <Col sm={9}>
              <Row className="mt-3">
                {items?.map((item: any, i: any) => (
                  <Col sm={4} key={i}>
                    <Item item={item} type="product" theme="from" />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}
