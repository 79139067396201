import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Button } from 'react-bootstrap';
import Rating from "../common/rating";
import { useProductApi } from '../../_common/hooks/api/productApiHook';
import { useToaster } from '../../_common/hooks/common/appTosterHook';
import { useAddressApi } from '../../_common/hooks/api/addressApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { useUserSelector } from '../../_common/hooks/selectors/userSelector';
import { TiWarning } from 'react-icons/ti';
import AddressModal from "./addressModal";
import { DateRange } from 'react-date-range';

import moment from 'moment';

import { FaCheck } from 'react-icons/fa';
import { IoMdCall } from 'react-icons/io';
import { MdLocationOn } from 'react-icons/md';
import { ImUser } from 'react-icons/im';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';



import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../../style/checkAvailibility.scss'; // theme css file
import { useForm } from "react-hook-form";

const CheckAvailibilityModal = (props: Record<string, any>) => {

    const toast = useToaster();
    const user: any = useUserSelector();
    const productApi = useProductApi();
    const addressApi = useAddressApi();
    const [addresses, setAddresses] = useState<any>(null);
    const [address, setAddress] = useState<any>(null);
    const [isChange, setIsChange] = useState<boolean>(false);
    const [isChangeAddress, setIsChangeAddress] = useState<boolean>(false);

    const { showLoader, hideLoader } = useAppLoader();

    const [addressModal, setAddressModal] = useState<boolean>(false);
    const [addressError, setAddressError] = useState<boolean>(false);
    const [value, setValue] = useState<any>('');
    const [lat, setLat] = useState<any>(0);
    const [lng, setLng] = useState<any>(0);
    const [gmapScript, setGmapScript] = useState<boolean>(false);
    const [guestAddressshow, setGuestAddressshow] = useState(false);

    const handleShow = () => { setGuestAddressshow(true); }
    const {
        register: guest,
        formState: { errors: errors3 },
        handleSubmit: handleSubmit3,
    } = useForm();

    const [state, setState] = useState<any>([
        {
            startDate: new Date(),
            endDate: undefined,
            key: 'selection'
        }
    ]);


    const onDateRangeChange = (dateRange: any) => {
        console.log('dateRange =>', dateRange)
        setState(dateRange);
        //
    }



    const closeAddressModal = () => { setAddressModal(false); };

    const getAddresses = () => {
        console.log('fffff');

        if (user) {
            addressApi.getAddresses({}, (message: string, resp: any) => {
                setAddresses(resp.data);
                console.log(resp.data);

                let found = false;
                resp.data.map((address: any) => {
                    if (address.isDefault == '1') {
                        found = true;
                        setAddress(address);
                        console.log(address)
                    }
                })

                if (!found) {
                    if (resp.data.length == 0) {
                        setIsChange(false);
                        setIsChangeAddress(true);
                    } else {
                        setAddress(resp.data[0]);
                    }

                }
            }, (message: string, resp: any) => {
                console.log('Addresses ERROR =>', message)
            })
        } else {
            // let ff = JSON.parse(localStorage.getItem('__fstvAddress') || '{}');
            // console.log('ffffff', ff);

            // setAddress(ff)
            addressApi.getAddressesGuest({}, (message: string, resp: any) => {
                setAddresses(resp.data);
                console.log(resp.data);

                let found = false;
                resp.data.map((address: any) => {
                    if (address.isDefault == '1') {
                        found = true;
                        setAddress(address);
                        console.log(address)
                    }
                })

                if (!found) {
                    if (resp.data.length == 0) {
                        setIsChange(false);
                        setIsChangeAddress(true);
                    } else {
                        setAddress(resp.data[0]);
                    }

                }
            }, (message: string, resp: any) => {
                console.log('Addresses ERROR =>', message)
            })
        }
    }

    const checking = () => {


        let _variants: any = [];
        Object.keys(props.variants).forEach((variantKey: any) => {
            let selectedOption = props.variants[variantKey];
            _variants.push({
                variant_id: selectedOption.product_variant_id,
                option_id: selectedOption.id
            })
        });

        let stDate = moment(state[0].startDate).format('YYYY-MM-DD');

        let params = {
            delivery_type_id: props.data.delivery_type_id,
            product_id: props.data.product_id,
            startDate: stDate,
            endDate: state[0].endDate ? moment(state[0].endDate).format('YYYY-MM-DD') : stDate,
            lat: props.data.required_address ? address.lat : null,
            lng: props.data.required_address ? address.lng : null,
            selectedVariation: JSON.stringify(_variants),
            quantity: props.quantity
        }

        showLoader()


        productApi.checkAvailaibility(params, (message: any, resp: any) => {
            //toast.success(message)
            // localStorage.setItem('__fstvAvailable', JSON.stringify(resp.data));
            props.checkavailibilityresult(props.data, !!resp.data.delivery.deliverable, address, state[0].startDate, state[0].endDate ? state[0].endDate : state[0].startDate, message);
            props.onHide();
            hideLoader();
        }, (message: any, resp: any) => {
            //console.error(props);
            props.checkavailibilityresult(props.data, false, address, state[0].startDate, state[0].endDate ? state[0].endDate : state[0].startDate, message);
            props.onHide();
            hideLoader();
        })

    }

    const setDeafultAd = () => {
        console.log(address);
        if(user){
            addressApi.setAsDefault({ id: address?.id, isDefault: 1 }, (message: string, resp: any) => {
                // getAddresses();
            }, (message: string, resp: any) => {
                console.log('Addresses ERROR =>', message)
            })
        }else{
            addressApi.setAsDefaultGuest({ id: address?.id, isDefault: 1 }, (message: string, resp: any) => {
                // getAddresses();
            }, (message: string, resp: any) => {
                console.log('Addresses ERROR =>', message)
            })
        }
    }

    const addScript = (path: any, id: any) => {
        return new Promise((resolve, reject) => {
            if (document.getElementById(id)) {
                resolve(true);
            } else {
                let script = document.createElement('script');
                script.onload = function () {
                    resolve(true);
                };
                script.src = path;
                script.id = id;
                document.head.appendChild(script);
            }

        });
    }

    const setLatLng = (lat: any, lng: any) => {
        setLat(lat);
        setLng(lng);
        console.log(lat, lng)
    }

    const checkGoogle = () => {
        return new Promise((resolve, reject) => {
            if ((window as any).google !== undefined) {
                setGmapScript(true)
                resolve(true);
            } else {
                let timer = setInterval(() => {
                    if ((window as any).google !== undefined) {
                        clearInterval(timer);
                        setGmapScript(true)
                        resolve(true);
                    }
                }, 100)
            }
        })
    }

    const load_Google_Map = () => {
        return new Promise((resolve, reject) => {
            addScript('https://maps.googleapis.com/maps/api/js?key=' + process.env.REACT_APP_GOOGLE_KEY + '&libraries=places,geometry,drawing&v=weekly', 'g-map').then(() => {
                checkGoogle().then(() => {
                    addScript('/assets/js/oms.min.js', 'oms').then(() => { resolve(true) })
                })
            })

        });
    }

    const onAddressSubmit = (data: any) => {
        console.log('dd');

        data.lat = lat;//34.2804232;
        data.lng = lng;//-118.5039246;
        data.addressLine1 = value;
        if (lat == 0 || lng == 0) {
            setAddressError(true)
        } else {
            console.log(data);
            localStorage.setItem('__fstvAddress', JSON.stringify(data));
            setGuestAddressshow(false);
            setAddress(data);

            // window.location.href = window.location.href;
            // close();
            // if (props.address) { data.id = props.address.id }
            // data.addressLine1 = value;
            // addressApi.addUpdateAddress(data, (message: any, resp: any) => {
            //     // props.update();
            //     // props.onHide();
            //     toast.success(message);
            // }, (message: any, resp: any) => {
            //     toast.error(message);
            // })
        }
    }

    const handleChange = (address: any) => {
        setValue(address)
        console.log('Handle Change ===================>', address)
        setLatLng(0, 0);
        setAddress(address);
    };

    const handleSelect = (address: any) => {
        console.log('Handle Select =>', address);
        setValue(address);
        geocodeByAddress(address).then((results: any) => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                setLatLng(lat, lng)
            });
    };



    useEffect(() => {
        load_Google_Map();
    }, [])

    useEffect(() => {

        if (props.show) {
            setIsChangeAddress(false)
            console.log('gf', props.data.required_address);
            if (props.data.required_address) { getAddresses(); } else {
                setAddress(null)
            }
        }
    }, [props.show]);

    // useEffect(()=>{
    //     console.log(address);
    // },[address?.id])


    return (
        <React.Fragment>
            <AddressModal
                show={addressModal}
                onHide={closeAddressModal}
                address={null}
                update={getAddresses}
            />
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered className="add-review-modal" >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">{!isChangeAddress ? 'Check Availablity' : 'Select Your Address'}</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {(address && !isChangeAddress) ?
                        <div className="address-card">
                            <div className="top">
                                <h2>Address</h2>
                                {/* {user ? <> */}
                                    <span onClick={() => { setIsChange(false); setIsChangeAddress(true) }}>Change</span>
                                {/* </> :
                                    <span onClick={handleShow}>Change</span>} */}
                            </div>
                            <hr />
                            <p className="name"><ImUser /> {address.name}</p>
                            <p className="mobile"><IoMdCall />{address.mobile_no}</p>
                            <p className="address"><MdLocationOn /><span>{address.addressLine1}<br />{address.other_address}</span></p>
                            <p className="address"></p>
                        </div>
                        : null}

                    {isChangeAddress ?
                        <React.Fragment>
                            <div className="addresses-container">
                                {addresses?.map((_address: any, i: any) => (
                                    <div className="address-card" key={i} onClick={() => { setIsChange(true); setAddress(_address) }}>
                                        <div className="top">
                                            <h2>Address</h2>
                                            {_address.id === address?.id ? <FaCheck /> : null}
                                        </div>
                                        <hr />
                                        <p className="name" ><ImUser /> {_address.name}</p>
                                        <p className="mobile"><IoMdCall />{_address.mobile_no}</p>
                                        <p className="address"><MdLocationOn /><span>{_address.addressLine1}<br />{_address.other_address}</span></p>
                                        <p className="address"></p>
                                    </div>
                                ))}

                            </div>

                            <div className="d-flex justify-content-center">
                                <Button variant="primary" className="m-1" onClick={() => { setAddressModal(true) }}>Add New Address</Button>
                                <Button variant="info" className="m-1" onClick={() => {
                                    if (isChange) { toast.success('Address selected successfully') }
                                    setIsChangeAddress(false); setDeafultAd()
                                }}>Ok</Button>
                            </div>
                        </React.Fragment>
                        : null}



                    {!isChangeAddress ?
                        <div className="check-calender">
                            <div className="d-flex justify-content-center">
                                <DateRange
                                    editableDateInputs={true}
                                    onChange={(item: any) => onDateRangeChange([item.selection])}
                                    moveRangeOnFirstSelection={false}
                                    ranges={state}
                                    minDate={new Date()}
                                />
                            </div>

                            <div className="d-flex justify-content-center">
                                <Button onClick={checking} variant="info">CHECK</Button>
                            </div>
                        </div>
                        : null}


                </Modal.Body>
            </Modal>

            {/* <Modal show={guestAddressshow} aria-labelledby="contained-modal-title-vcenter" centered className="add-review-modal" >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Change Address</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <form onSubmit={handleSubmit3(onAddressSubmit)}>
                        <div className="form-group position-relative mb-4">
                            {gmapScript ?
                                <PlacesAutocomplete
                                    value={value}
                                    onChange={handleChange}
                                    onSelect={handleSelect}
                                    onError={() => toast.error('No results found')}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div className="add_input">
                                            <div className="form-group">
                                                <label htmlFor="name">Address</label>
                                                <input onFocus={() => { setAddressError(false) }}
                                                    {...getInputProps({
                                                        name: 'address',
                                                        placeholder: 'Search Places',
                                                        className: 'form-control' + (addressError ? ' is-invalid' : ''),
                                                    })}
                                                />
                                                {loading && <div className="text-white spinner-border ml-2" />}
                                                {addressError ? <div className="invalid-feedback">required</div> : null}
                                            </div>
                                            <div className="autocomplete-dropdown-container">
                                                {suggestions.map((suggestion, i) => {
                                                    const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                    // inline style for demonstration purpose
                                                    const style = suggestion.active
                                                        ? { backgroundColor: 'rgb(38,132,255)', color: '#fff', cursor: 'pointer' }
                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                    return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                                style,
                                                            })}
                                                            key={i}
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                                : null}
                        </div>
                        <div className="form-group position-relative mb-4">
                            <input type="text" className={"form-control f_line"} placeholder="Contact Name(Optional)" {...guest("name")} />
                        </div>
                        <div className="form-group position-relative mb-4">
                            <input type="number" className={"form-control f_line"} placeholder="Contact Number(Optional)" {...guest("mobile_no")} />
                        </div>

                        <div className="btns">
                            <button className="btn btn-info d-block waves-effect waves-light" type="submit">Continue</button>
                        </div>

                    </form>
                </Modal.Body>
            </Modal> */}
        </React.Fragment>

    )
};

export default CheckAvailibilityModal


