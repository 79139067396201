import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'; // withRouter, HashRouter, Redirect 
import './App.scss';
import { URLS } from './_config';
import requireNoAuth from './_common/hoc/reqNoAuth'
import requireAuth from './_common/hoc/reqAuth'

import Home from './pages/home';
import AboutUs from './pages/aboutUs';
import Terms from './pages/terms';
import PrivacyPolicy from './pages/privacyPolicy';
import Faqs from './pages/faqs';
import Help from './pages/help';
import ContactUs from './pages/contactUs';
import Login from './pages/login';
import RestePassword from './pages/resetPassword';
import Registration from './pages/registration';
import ForgotPassword from './pages/forgotPassword';
import Listing from './pages/listing';
import Shop from './pages/shop';
import Product from './pages/product';
import OrderDetails from './pages/orderDetails';
import Checkout from './pages/checkout';
import MyAccount from './pages/myAccount';
import Collections from './pages/collections';
import NewestProducts from './pages/newestProducts/newestProducts';
import Categories from './pages/category_list';
import OccassionList from './pages/occassion_list/occassion_list';
import VendorList from './pages/vendor_list';

import 'react-toastify/dist/ReactToastify.css';
import GlobalSearch from './pages/globalSearch/globalSearch';
import GalleryVendor from './pages/gallery/gallery';
import RemainingAmount from './pages/remainingAmount/remainingAmount';


function App() {
  return (
    <Router>
      <Switch>
        {/* <Route exact path={URLS.HOME} component={() => (
          <Redirect to={URLS.USER.LOGIN}/>
        )} /> */}
        <Route exact path={URLS.HOME} component={Home} />
        <Route exact path={URLS.ABOUT} component={AboutUs} />
        <Route exact path={URLS.TERMS} component={Terms} />
        <Route exact path={URLS.PRIVACY} component={PrivacyPolicy} />
        <Route exact path={URLS.FAQS} component={Faqs} />
        <Route exact path={URLS.HELP} component={Help} />
        <Route exact path={URLS.COLLECTIONS} component={Collections} />
        <Route exact path={URLS.NEWEST_PRODUCTS} component={NewestProducts} />
        <Route exact path={URLS.SEARCH} component={GlobalSearch} />
        <Route exact path={URLS.CATEGORIES} component={Categories} />
        <Route exact path={URLS.OCCASSIONS} component={OccassionList} />
        <Route exact path={URLS.VENDORS} component={VendorList} />
        <Route exact path={URLS.USER.MY_ACCOUNT} component={requireAuth(MyAccount)} />
        <Route exact path={URLS.CHECKOUT} component={Checkout} />
        <Route exact path={URLS.USER.REMAINING_AMOUNT} component={RemainingAmount} />
        <Route exact path={URLS.CONTACT} component={ContactUs} />
        <Route exact path={URLS.USER.LOGIN} component={requireNoAuth(Login)} />
        <Route exact path={URLS.USER.RESET_PASSWORD} component={RestePassword} />
        <Route exact path={URLS.USER.REGISTER} component={requireNoAuth(Registration)} />
        <Route exact path={URLS.USER.FORGOT_PASSWORD} component={requireNoAuth(ForgotPassword)} />
        <Route exact path={URLS.GALLERY} component={requireNoAuth(GalleryVendor)} />
        <Route exact path={URLS.USER.MY_ACCOUNT} component={requireNoAuth(MyAccount)} />
        <Route exact path={URLS.LISTING} component={Listing} />
        <Route exact path={URLS.SHOP} component={Shop} />
        <Route exact path={URLS.PRODUCT} component={Product} />
        <Route exact path={URLS.ORDER_DETAILS} component={OrderDetails} />
        <Route exact path="*"  ><Redirect to="/" /></Route>

        {/* 
        <Route exact path={URLS.BLOG} component={requireAuth(Home)} />
        <Route exact path={URLS.ABOUT_US} component={requireAuth(Home)} />
        <Route exact path={URLS.CONTACT} component={requireAuth(Contact)} />
        <Route exact path={URLS.WASTE_TECH_IQ} component={requireAuth(WasteTechIq)} />

        <Route exact path={URLS.KNOWLEDGE} component={requireAuth(Knowledge)} />
        <Route exact path={URLS.KNOWLEDGE_CENTER} component={requireAuth(KnowledgeCenter)} />
        <Route exact path={URLS.KNOWLEDGE_CENTER_LANDING} component={requireAuth(KnowledgeCenterLanding)} />
        <Route exact path={URLS.KNOWLEDGE_CENTER_MAIN} component={requireAuth(KnowledgeCenterMain)} />

        <Route exact path={URLS.USER.LOGIN} component={requireNoAuth(Login)} />
        <Route exact path={URLS.USER.RESET_PASSWORD} component={requireNoAuth(ResetPassword)} />
        <Route exact path={URLS.USER.REGISTRATION} component={requireNoAuth(Registration)} />
        <Route exact path={URLS.USER.VERIFY} component={requireNoAuth(Verify)} />
        <Route exact path={URLS.USER.HOME} component={requireAuth(Home)} />
        <Route exact path={URLS.USER.MY_ACCOUNT} component={requireAuth(MyAccount)} />
        <Route exact path={URLS.USER.PROFILE} component={requireAuth(Profile)} /> */}


      </Switch>
    </Router>
  );
}

export default App;


