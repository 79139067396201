import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';
import {
    BlankReq,
    addToCartReq,
    setDefaultReq,
    pageReq,
    modifyCartReq
} from '../../interfaces/ApiReqRes'


export function useCartApi() {

    const callApi = useApiCall()

    const addToCart = (data: addToCartReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CART.ADD_PRODUCT_TO_CART, data, onSuccess, onError);
    }
    const saveForLater = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CART.SAVE_FOR_LATER, data, onSuccess, onError);
    }
    const modifyCart = (data: modifyCartReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CART.MODIFY_CART, data, onSuccess, onError);
    }
    const myCart = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CART.MY_CART, data, onSuccess, onError);
    }
    const wishlistMoveToCart = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CART.MOVE_TO_CART, data, onSuccess, onError);
    }
    const deleteWishlist = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CART.DELETE_WISHLIST, data, onSuccess, onError);
    }
    const myWishlist = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CART.WISHLIST, data, onSuccess, onError);
    }
    const applyCoupons = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CART.APPLY_COUPONS, data, onSuccess, onError);
    }

    const removeCoupons = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CART.REMOVE_COUPONS, data, onSuccess, onError);
    }

    const checkout = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CART.CHECKOUT, data, onSuccess, onError);
    }

    const getRemainingDetails = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CART.REMAINING_DETAILS, data, onSuccess, onError);
    }

    const updateCart = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CART.UPDATE_CART, data, onSuccess, onError);
    }

    const guestCheckOut = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CART.GUEST_CHECK_OUT, data, onSuccess, onError);
    }

    return {
        addToCart: addToCart,
        myCart: myCart,
        applyCoupons: applyCoupons,
        modifyCart: modifyCart,
        checkout: checkout,
        updateCart: updateCart,
        removeCoupons: removeCoupons,
        guestCheckOut: guestCheckOut,
        saveForLater:saveForLater,
        myWishlist:myWishlist,
        deleteWishlist:deleteWishlist,
        wishlistMoveToCart:wishlistMoveToCart,
        getRemainingDetails:getRemainingDetails
    }
}