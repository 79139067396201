import React, { useState } from "react";
import { Row, Col, Modal, Button } from 'react-bootstrap';
import Rating from "../common/rating";
import { useLoveFestApi } from '../../_common/hooks/api/loveFestApiHook';
import ReactHtmlParser from 'react-html-parser';
import { TiWarning } from 'react-icons/ti';
import { useForm } from "react-hook-form";


const PinInspoModal = (props: Record<string, any>) => {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const loveFestApi = useLoveFestApi();

    const onPinboardSubmit = (data: any) => {
        loveFestApi.addUpdateInspoBoard(data, (message: any, resp: any) => {
            console.log('resp', resp.pinboard.id);
            loveFestApi.addToInspoBoard({
                item_id: props.id,
                item_type: 'product',
                pinboard_id: resp.pinboard.id,
            }, (message: any, resp: any) => {
                props.update();
                props.onHide();
                props.onUpdate();
            }, (message: any, resp: any) => {
                console.error(message)
            })
        }, (message: any, resp: any) => {
            console.error(message)
        })
    }
    const addToBoard = (board: any) => {
        loveFestApi.addToInspoBoard({
            item_id: props.id,
            item_type: 'product',
            pinboard_id: board.id,
        }, (message: any, resp: any) => {
            props.update();
            props.onHide();
        }, (message: any, resp: any) => {
            console.error(message)
        })
    }



    const getBoardBtn = (board: any) => {
        let btn: any = <Button variant="outline-info" type="button" size="sm" onClick={() => { addToBoard(board) }}>Use</Button>
        console.log('------', props.pined)
        props.pined?.forEach((pin: any) => {
            if (pin) {
                if (pin.id === board.id) {
                    btn = <Button variant="outline-danger" type="button" size="sm" onClick={() => { addToBoard(board) }}>Remove</Button>
                }
            }

        })
        return btn;
    }

    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered className="add-review-modal" >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Add/Update Inspo Pins</Modal.Title>
            </Modal.Header>
            <Modal.Body className="add-inspo">

                <p>Choose one of these</p>

                <div className="boards">
                    {props.boards?.map((board: any, i: any) => (
                        <div className="board" key={i}>
                            <p>{board.name}</p>
                            {getBoardBtn(board)}
                        </div>
                    ))}

                </div>
                <p>Or Create One</p>
                <form onSubmit={handleSubmit(onPinboardSubmit)}>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input type="text" className={"form-control" + (errors?.pinboard_name ? ' is-invalid' : '')} placeholder="Pinboard Name" {...register("pinboard_name", { required: true })} />
                        {errors?.pinboard_name ? <div className="invalid-feedback">required</div> : null}
                    </div>

                    <div className="form-group">
                        <label htmlFor="name">Description</label>
                        <textarea className={"form-control" + (errors?.pinboard_description ? ' is-invalid' : '')} placeholder="Pinboard Description" {...register("pinboard_description", { required: true })} />
                        {errors?.pinboard_description ? <div className="invalid-feedback">required</div> : null}
                    </div>
                    <br />
                    <div className="btns">
                        <Button variant="info" type="submit">Add and Use</Button>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide} variant="dark">Close</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default PinInspoModal


