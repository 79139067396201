import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';

import { confirmAlert } from 'react-confirm-alert';
import Price from "../common/price";
import { useCartApi } from '../../_common/hooks/api/cartApiHook';
import { useToaster } from '../../_common/hooks/common/appTosterHook';
import UpdateCartItemModal from '../modals/updateCartItem';
import { useUserSelector } from '../../_common/hooks/selectors/userSelector';

import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../style/cart.scss';
import { Link } from "react-router-dom";



interface incomingProps {
  item: any;
  theme?: any;
  closeCart?: any;
  showSuggested?: any;
}



export default function CartItem({ item, theme, closeCart, showSuggested }: incomingProps) {

  const [isMore, setIsMore] = useState<Boolean>(false);
  const [gift, setGift] = useState<Boolean>(!!item.as_gift);
  const cartApi = useCartApi();
  const user: any = useUserSelector();
  const toast = useToaster();

  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const closeUpdateModal = () => { setUpdateModal(false) };

  const toggleIsMore = () => {
    setIsMore(!isMore);
  }

  const remove = () => {
    confirmAlert({
      title: 'Confirm!',
      message: 'Are you sure to remove this Item',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            cartApi.modifyCart({ cart_id: item.id, delete_product: 1 }, (message: any, resp: any) => {
              updateCartData();
            }, (message: any, resp: any) => {

            })
          }
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  }
  const applyCoupon = (id: any) => {
    console.log('.....', item, item.CartDetails);
    let el: any = document.querySelector('[name="coupon' + item.id + '"]');
    if (el.value != '') {
      cartApi.applyCoupons({
        cart_id: item.CartDetails[0].id,
        product_id: id,
        code: el.value
      }, (message: any, resp: any) => {
        toast.success(message)
        cartApi.myCart({}, (message: any, resp: any) => {
        }, (message: any, resp: any) => {
          console.error(message)
        })
      }, (message: any, resp: any) => {
        toast.error(message)
      })
    } else {
      toast.error('Please enter a coupon code')
    }

  }

  const removeCoupon = (id: any) => {
    console.log('.....', item, item.CartDetails);
    cartApi.removeCoupons({
      cart_id: item.CartDetails[0].id,
      product_id: id,
    }, (message: any, resp: any) => {
      toast.success(message)
      cartApi.myCart({}, (message: any, resp: any) => {
      }, (message: any, resp: any) => {
        console.error(message)
      })
    }, (message: any, resp: any) => {
      toast.error(message)
    });

  }

  const updateCartData = () => {
    cartApi.myCart({}, (message: any, resp: any) => { }, (message: any, resp: any) => { })
  }

  const updateGiftNote = (msg: any) => {
    let chkBox: any = document.getElementById('as-gift-' + item?.id);
    let note: any = document.getElementById('gift-note' + item?.id);
    setGift(chkBox.checked);
    if (!chkBox.checked && note) {
      note.value = '';
    }
    cartApi.updateCart({
      cart_id: item.id,
      quantity: item.qty,
      gift_note: note ? note.value : '',
      is_gift: chkBox.checked,
    }, (message: any, resp: any) => {
      updateCartData();
      if (msg) { toast.success('Gift Note Added') }
    }, (message: any, resp: any) => {
      console.error(message);
    })
  }

  const openUpdateModal = () => {
    let elements = document.getElementsByClassName('MuiDrawer-paper');
    elements[0].classList.add('d-none');

    setUpdateModal(true);
  }

  const saveForLater = () => {
    cartApi.saveForLater({
      cart_id: item.id,
    }, (message: any, resp: any) => {
      updateCartData();
      toast.success(resp.message);
    }, (message: any, resp: any) => {
      console.error(message);
    })
  }


  useEffect(() => {
    console.log('itemcart', item);

    if (item) {
      setGift(!!item.as_gift);
      let note: any = document.getElementById('gift-note' + item?.id);
      if (note) {
        note.value = item.gift_note;
      }

    }
  }, [item])

  useEffect(() => {
    console.log('itemitem', item);

  }, [])

  //console.log('item =>',item)



  return (
    <React.Fragment>
      <UpdateCartItemModal
        show={updateModal}
        onHide={closeUpdateModal}
        item={item}
        update={updateCartData}
      />
      {item.product ?
        <Row className={"cart-item " + (theme ? theme : '')}>
          <Col md={4} className="pic">
            <img src={item.product?.list_img_link} alt="" />
          </Col>
          <Col md={8} className="cart-info">
            {
              (item.product?.type == 4 && item.product?.service_pricing_details) ?
                (item.product?.service_pricing_details?.per_day_fee != null) ?
                  (<><Price amount={item.product?.service_pricing_details?.per_day_fee} discountPercentage={item.product?.discount_percentage} discountedPrice={item.coupon_amount} discountType={item.product?.service_pricing_details?.discount_type} /> /day </>) :
                  (item.product?.service_pricing_details?.per_week_fee != null) ?
                    <><Price amount={item.product?.service_pricing_details?.per_week_fee} discountPercentage={item.product?.discount_percentage} discountedPrice={item.coupon_amount} discountType={item.product?.service_pricing_details?.discount_type} /> / Week</> : <Price amount={item.product?.price} discountPercentage={item.product?.discount_percentage} discountedPrice={item.coupon_amount} discountType={item.product?.service_pricing_details?.discount_type} /> :
                <Price amount={item?.price} discountPercentage={item.product?.discount_percentage} discountedPrice={item.coupon_amount} discountType={item.product?.service_pricing_details?.discount_type} />
            }

            <h2>{item.product?.name}</h2>
            <p>Made by: {item.product?.vendor_details.name}</p>
            <p>Price:
              {
                (item.product?.type == 4 && item.product?.service_pricing_details) ?
                  (item.product?.service_pricing_details?.per_day_fee != null) ?
                    (<><Price amount={item.product?.service_pricing_details?.per_day_fee} discountPercentage={item.product?.discount_percentage} discountedPrice={item.coupon_amount} discountType={item.product?.service_pricing_details?.discount_type} /> /day </>) :
                    (item.product?.service_pricing_details?.per_week_fee != null) ?
                      <><Price amount={item.product?.service_pricing_details?.per_week_fee} discountPercentage={item.product?.discount_percentage} discountedPrice={item.coupon_amount} discountType={item.product?.service_pricing_details?.discount_type} /> / Week</> : <Price amount={item.product?.price} discountPercentage={item.product?.discount_percentage} discountedPrice={item.coupon_amount} discountType={item.product?.service_pricing_details?.discount_type} /> :
                  <Price amount={item?.price} discountPercentage={item.product?.discount_percentage} discountedPrice={item.coupon_amount} discountType={item.product?.service_pricing_details?.discount_type} />
              }
            </p>
            <p>Delivery fee: <Price amount={item.delivery_charge} /></p>
            <div className={"more-details" + (isMore ? ' show' : '')}>
              {item.CartDetails[0]?.carts_variations?.map((variant: any, i: any) => (
                <React.Fragment key={i}>
                  <p key={i}>{variant.label_details?.label}: {variant.option_details?.option_name}</p>
                </React.Fragment>
              ))}
              <p>Quantity: {item.qty}</p>
              {/* <p>Personalization: {item.note}</p> */}

              {item.product.product_additional_questions.length > 0 ?
                <div className="pqa">
                  <p className="title">Question Answer</p>
                  {item.product.product_additional_questions.map((question: any, i: any) => (
                    <React.Fragment key={i}>
                      <p className="pq">{question.additional_question}</p>
                      <p className="pa">{item?.question_answers[i]?.answer}</p>
                    </React.Fragment>
                  ))}
                </div>
                : null}





              <p>Delivery Address: {item.customer_address?.addressLine1}</p>
            </div>

            <p className="more" onClick={toggleIsMore}>{isMore ? '- less details' : '+ more details'}</p>
            <div className="actions">
              <p /* to={'/product/' + item.product?.slug}  */ onClick={openUpdateModal}>Edit</p> | <p onClick={remove}>Remove</p> | <p onClick={saveForLater}>Save for later</p> {item.offers.length > 0 ? <React.Fragment>| <p onClick={() => { showSuggested(item.offers) }}>Suggested Products</p></React.Fragment> : null}
            </div>
            {user ?
              item.coupon_id == null ?
                <div className="coupon">
                  <input type="text" name={'coupon' + item?.id} id={'coupon' + item?.id} spellCheck={false} />
                  <button onClick={() => { applyCoupon(item.id) }}>Apply Coupon</button>
                </div> :
                <div className="couponApply">
                  <p>{item?.coupon?.code} applied</p>
                  <span>-${item?.coupon_amount} ({item?.coupon?.type == 2 ? `${item?.coupon?.value}%` : `$${item?.coupon?.value}`} OFF)</span>
                  <a className="removebtn" onClick={() => { removeCoupon(item?.id) }}>Remove</a>
                </div> : ''
            }


            <div className="form-check">
              <input type="checkbox" id={'as-gift-' + item?.id} className="form-check-input" checked={gift ? true : false} onChange={(e) => { updateGiftNote(false) }} />
              <label title="" htmlFor={'as-gift-' + item?.id} className="form-check-label">I am sending this order as a gift</label>
            </div>

            {gift ?
              <div className="form-group gift-note">
                <label title="" htmlFor={'gift-note' + item?.id} className="form-label">Gift Note</label>
                <div className="note">
                  <input type="text" name="giftNote" defaultValue={item.gift_note} id={'gift-note' + item?.id} spellCheck={false} />
                  <button onClick={() => { updateGiftNote(true) }}>Save</button>
                </div>
              </div>
              : null}
          </Col>
        </Row>
        : null}
    </React.Fragment>

  );
}
