import React, { FC, ReactElement, PropsWithChildren } from 'react'
//import React, { FC, ReactElement } from 'react';

export default function AuthLayout(props: PropsWithChildren<any>) {
  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
}

/* export interface ChildProps {
  children: React.ReactNode
}

const AuthLayout: FC<ChildProps> = ({ children }): any => {
  return (
    <React.Fragment>{children}</React.Fragment>
  )
};

export default AuthLayout; */
