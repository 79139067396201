import React from "react";

interface incomingProps {
  amount: any;
  discountPercentage?: any;
  discountedPrice?: any;
  discountType?: any;
  price_range?: any;
}
export default function Price({
  amount,
  discountPercentage,
  discountedPrice,
  discountType,
  price_range
}: incomingProps) {
  if (amount == null) {
    amount = 0;
  }
  let _amount = 0;
  if (amount > 0 || (price_range==null || price_range===undefined)) {
    if (discountType == 1) {
      _amount = discountPercentage
        ? amount - (amount / 100) * discountPercentage
        : amount
        ? amount
        : 0;
      _amount = discountedPrice ? _amount - discountedPrice : _amount;
    } else {
      _amount = discountPercentage ? amount - discountPercentage : amount;
      _amount = discountedPrice ? _amount - discountedPrice : _amount;
    }
  }else{
    let price_range_split = price_range.split('-');
    _amount=parseFloat(price_range_split[0]);
    console.log('====================================');
    console.log(typeof(price_range_split[0]));
    console.log('====================================');
  }
  const addComma = (x: any) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <span className="price">
      {!isNaN(amount) ? (
        <React.Fragment>
          <span
            className="currency"
            style={{ color: discountPercentage ? "red" : "black" }}
          >
            $
          </span>
          <span
            className="amount"
            style={{ color: discountPercentage ? "red" : "black" }}
          >
            {addComma(_amount.toFixed(2))}
          </span>
          {discountPercentage ? (
            <span className="off">
              <span className="currency">$</span>
              <span className="amount">{amount.toFixed(2)}</span>
            </span>
          ) : null}
          {discountedPrice ? (
            <span className="off">
              <span className="currency">$</span>
              <span className="amount">{amount.toFixed(2)}</span>
            </span>
          ) : null}
        </React.Fragment>
      ) : (
        "N/A"
      )}
    </span>
  );
}
