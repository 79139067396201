import React, { useContext, useEffect, useState } from "react";
import { useCartSelector } from '../../_common/hooks/selectors/cartSelector';
import CartItem from "./cartItem";
import { Link } from "react-router-dom";
import { useUserSelector } from '../../_common/hooks/selectors/userSelector';

import '../../style/cart.scss';
import GuestCartItem from "./guestCartItem";


interface incomingProps {
  theme?: any;
  closeCart?: any;
  showOffers?: any;
}

export default function CartItems({ theme, closeCart, showOffers }: incomingProps) {
  let cart: any = useCartSelector();
  const user: any = useUserSelector();
  const [guestCart, setGuestCart] = useState<any>(null);
  const [countItems, setCountItems] = useState<any>(null);
  const [cartrefe, setcartrefe] = useState<any>(true);
  const guestCartrefresh = () => { setcartrefe(!cartrefe) }

  useEffect(() => {
    setCountItems(cart?.cart?.items.length);
    // if (cart.cart == null) {
    //   let fstvCart = JSON.parse(localStorage.getItem('__fstvCart') || '{}');
    //   console.log('guestCart', fstvCart.length);
    //   if (fstvCart.length > 0) {
    //     let ccart = {
    //       cart: {
    //         items: fstvCart
    //       }
    //     };
    //     setCountItems(ccart?.cart?.items.length);
    //     console.log('guestCart', ccart);

    //     setGuestCart(ccart);
    //   }
    // }
    // console.log('guestCart', guestCart);
  }, [])

  // useEffect(() => {
  //   setCountItems(cart?.cart?.items.length);
  //   if (cart.cart == null) {
  //     let fstvCart = JSON.parse(localStorage.getItem('__fstvCart') || '{}');
  //     console.log('guestCart', fstvCart.length);
  //     if (fstvCart.length > 0) {
  //       let ccart = {
  //         cart: {
  //           items: fstvCart
  //         }
  //       };
  //       setCountItems(ccart?.cart?.items.length);
  //       console.log('guestCart', ccart);

  //       setGuestCart(ccart);
  //     }
  //   }
  //   console.log('guestCart', guestCart);
  // }, [cartrefe])


  return (
    <div className="cart-items">
      {/* {user ?
        <> */}
          {cart?.cart?.items.map((item: any, i: any) => (
            <CartItem item={item} theme={theme} closeCart={closeCart} showSuggested={showOffers} key={i} />
          ))}
        {/* </>
        :
        <>
          {guestCart?.cart?.items.map((item: any, i: any) => (
            <GuestCartItem item={item} theme={theme} closeCart={closeCart} showSuggested={showOffers} key={i} guestCartrefresh={guestCartrefresh} />
          ))}
        </>
      } */}

      {countItems > 0 ? null :
        <div className="empty-cart">
          <h2>Your bag is empty</h2>
          <p>Looks like you haven't added any items to your bag yet.</p>
          <Link to="/collections?order=trendingnow">Add items to your Bag</Link>
        </div>
      }
    </div>
  );
}
