import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';
import {
    themeCatInfoReq,
    productsReq
} from '../../interfaces/ApiReqRes'


export function useCommonApi() {

    const callApi = useApiCall()

    const getThemeCatInfo = (data: themeCatInfoReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_THEME_CAT_INFO, data, onSuccess, onError);
    }
    const getProducts = (data: productsReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_PRODUCTS, data, onSuccess, onError);
    }

    return {
        getThemeCatInfo: getThemeCatInfo,
        getProducts: getProducts,
    }
}