import { useSelector } from 'react-redux'
import { User } from '../../interfaces/models/user'
import { StateExtended } from '../../interfaces/StateExtended'


export function useUserSelector() {
    const user: User | null = useSelector((state: StateExtended) => state.user.user)
    return user
}

export function useUserTokenSelector() {
    const token: string | null = useSelector((state: StateExtended) => state.user.token)
    return token
}

export function useUserAuthSelector() {
    const isAuth: boolean = useSelector((state: StateExtended) => state.user.isAuthenticated)
    return isAuth
}