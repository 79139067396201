import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom"; //useHistory, useParams
import { useGlobalApi } from '../../_common/hooks/api/globalApiHook';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { ImSearch } from 'react-icons/im';



export default function NavSearch() {
    const history: any = useHistory();
    const { search_text } = useParams<any>();
    const [search_key, set_serach_key] = useState<any>('');
    const globalApi = useGlobalApi();



    const getSuggestions = (typeText: any, callback: any) => {
        if (typeText !== '') {
            globalApi.getSuggestions({ search_text: typeText }, (message: any, resp: any) => {
                let options = resp.results.map((x: any) => {
                    return {
                        label: x,
                        value: x,
                    }
                });
                callback(options);
            }, (message: any, resp: any) => {
                console.log('error', message);
                callback([]);
            })
        } else {
            callback([]);
        }

    }
    const loadingMessage = () => {
        return "Please wait while we fetch some suggestions"
    }

    const onSearchTerm = (value: any) => {
        let val = value && value.value ? (value.value).replace(/[#@$!%^&*?]/g, '') : null;
        console.log('onSearchTerm', val);
        if (val) {
            history.push('/collections?search_text=' + val);
        }
    }


    const onSearch = () => {

        let value = search_key.replace(/[#@$!%^&*?]/g, '');
        value = value.replace(/ /g, '+');
        if(value != ''){
            history.push('/collections?search_text=' + value);
        }
        
    }

    const searchTextUpdate = (e:any)=>{
        console.log('-----',e.target.value)
        set_serach_key(e.target.value)
    }

    const formatCreateLabel = (val: any) => {
        return `Search for "${val}"`
    }

    const onSearchNewTerm = (value: any) => {

        let val = value ? (value).replace(/[#@$!%^&*?]/g, '') : '';
        console.log('onSearchNewTerm', val)
        /*  let newOpt = {value: val, label: val};
         this.setState({searchTerm: newOpt})
         this.props.dispatch({
             type: ACTIONS.GLOBAL_SEARCH.SAVE_INPUT_TEXT,
             payload: val
         })
         this.gotoSearchResult(val); */
    }

    const keyDown = (e:any)=>{
        if(e.keyCode == 13){
            onSearch()
        }
    }




    useEffect(() => {
        //--
    }, [])



    return (
        
        <div className="nav-search">
            <input type="text" onKeyDown={keyDown} onChange={searchTextUpdate} placeholder="What can we help you find?" className="form-control" />
            <ImSearch onClick={onSearch} />
        </div>
    );
}
{/* <AsyncCreatableSelect
            placeholder="What can we help you find?"
            createOptionPosition="first"
            allowCreateWhileLoading
            loadOptions={getSuggestions}
            value={search_text}
            defaultOptions
            isClearable
            cacheOptions
            noOptionsMessage={() => null}
            loadingMessage={loadingMessage}
            onChange={onSearchTerm}
            formatCreateLabel={formatCreateLabel}
            onCreateOption={onSearchNewTerm}
        /> */}